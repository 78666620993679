import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import { Container } from 'styled-minimal';
import { resetPasswordStaff } from '../../actions/staff';
import withStyles from '@material-ui/core/styles/withStyles';
import CButton from '../../components/FormElements/CButton';
import CInput from '../../components/FormElements/CInput';
import styles from './styles';

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isValidEmail: null,
    }
  }
  
  componentDidMount() {}

  handleSubmit = () => {
    const { resetPasswordStaff, isRequest } = this.props;
    const { email } = this.state;

    if (isRequest) return;
    
    if (this.validateForm(email)) {
      resetPasswordStaff(email);
    }
  };
 

  validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  validateForm = (email) => {
    let result = true;
    
    if (email !== null) {
      this.setState({isValidEmail: !(!email || !this.validateEmail(email))});
      result = !(!email || !this.validateEmail(email));
    }
  
    return result;
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render(){
    const { step, classes } = this.props;
    const { email, isValidEmail } = this.state;

    return (
      <HomeContainer>
        <div className={classes.textBlock} >
          <Typography component="h1" variant="h1" classes={{h1: classes.h1}}>
            Password recovery
          </Typography>
          {
            (step !== 1) ? (
              <div>
                <Typography component="p" variant="body2" classes={{body2: classes.text}}>
                  Enter your email address to reset password:
                </Typography>
              </div>
            ) : 
              <div>
                <Typography component="p" variant="body2" classes={{body2: classes.text}}>
                  Please check your email for instructions on how to complete password reset process.
                </Typography>
              </div>  
          }
        </div> 
        <form noValidate className={classes.form} onSubmit={this.handleSubmit} method="POST">
          {
            (step !== 1) ? (
              <Grid container spacing={24} justify="center" alignItems="center">
                <Grid container item xs={12} sm={6}>
                  <CInput
                    required
                    fullWidth
                    name="email" 
                    type="email" 
                    label= 'Email'
                    autoComplete="email" 
                    autoFocus 
                    value={email} 
                    onChange={this.handleChange} 
                    placeholder={'Email'}
                    isValid={isValidEmail}
                    validate={(event) => {
                      const {relatedTarget} = event;
                      if (relatedTarget && ('button' === relatedTarget.getAttribute('type'))) {
                        this.handleSubmit()
                      }
                      this.validateForm(event.target.value, null)
                    }}
                  />
                </Grid>
                <Grid container item xs={12} sm={6} justify="center" alignItems="center" style={{ paddingTop: '35px' }}>
                  <CButton
                    styles={{ padding: '100px' }}
                    onClick={this.handleSubmit}
                    className={classes.submit}
                    fullWidth
                  >
                    RESET PASSWORD
                  </CButton>
                </Grid>
                <Grid item xs={12} sm={6}/> 
              </Grid>  
            ) : null
          }   
        </form>
      </HomeContainer>
    )
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  staff: state.staffReducer.staff, 
  step: state.staffReducer.ressetPasswordStep,
  isRequest: state.staffReducer.isRequest,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    resetPasswordStaff,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPassword));
