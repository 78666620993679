/**
 * Created by piter on 4/21/19.
 */
import {
    MAIN_ACCENT_COLOR,
  } from '../../styles/constants';
  
  const styles = theme => ({
    h4: {
      color: 'white',
      opacity: 0.6,
      fontFamily: "Montserrat-Bold",
      fontSize: '10px',
      marginBottom: 5,
      textTransform: 'uppercase'
    },
    count: {
      fontFamily: "Montserrat-Bold",
      color: 'white',
      paddingRight: '6px',
      letterSpacing: '-0.5px',
      opacity: 0.8,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '25px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '30px',
      },
    },
    accentCount: {
      color: MAIN_ACCENT_COLOR,
      opacity: 1
    },
    blockLeft: {
      marginRight: 60,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: 15
      }
    },
    blockRight: {
      [theme.breakpoints.up('sm')]: {
        marginRight: 0
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: 15
      }
    },
    container: {
      position: 'absolute',
      marginRight: 30,
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        top: 40,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: 0,
        top: 20,
      }
    }
  });
  
  export default styles;