import React from 'react';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import withWidth from '@material-ui/core/withWidth';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Header from './components/Header';
import Login from './pages/login';
import Phone_auth from './pages/phone_auth';
import Success from './pages/registration/success';
import Loading from './pages/loading';
import Registration from './pages/registration';
import ResetPassword from './pages/reset_pass';
import NewPassword from './pages/new_pass';
import Home from './pages/home';
import Prizes from './pages/prizes';
import Winner from './pages/winner';
import SearchHome from './pages/search_phone';
import CodeScanner from './pages/code_scanner';
import QR from './pages/qr';
import EducationalPacks from './pages/educational_packs';
import PrizesScanner from './pages/prizes_scanner';
import ErrorDrawer from './components/ErrorDrawer';
import { getStaff } from './actions/staff';
import { getCurrentWinner } from './actions/prizes';
import ScrollToTop from './components/ScrollToTop';
import history from './modules/history';
import config from './config';

import background_1 from './resources/images/logos/background.svg';
import background_2 from './resources/images/logos/background_1.svg';
import './App.css';
 
const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
  background: url(${background_1}) no-repeat 50% 50% #232B37;
  background-size: cover;
`;

const Main = styled.main`
  flex: 1;
  align-items: stretch;
  display: flex;
`;

const theme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#00AA60',
      },
    },
    MuiPickersYear: {
      '&$selected': {
        color: '#00AA60',
      },
    },
    MuiPickersMonth: {
      '&$selected': {
        color: '#00AA60',
      },
    },
    MuiPickersDay: {
      isSelected: {
        color: '#fff',
        backgroundColor: '#00AA60'
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#00AA60',
      },
    },
  },
  // brandStyles: {
  //   input: {
  //     defaultBorderColor: 'yellow'
  //   },
  // },
  typography: {
    useNextVariants: true,
    fontFamily: [
      "OpenSans-Regular",
    ].join(','),
  },
});

const LIST_ALLOW_PAGES = [
  '/home',
  '/phone_auth',
  '/success',
]


export class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname: history.location.pathname,
      user_is_found: false,
    }
  }

  componentDidMount() {
    if (history.location.pathname === '/reset_pass' || history.location.pathname === '/send_email' ) return;
   
    this.props.dispatch(getStaff());
    this.setState({ user_is_found: (history.location.pathname === '/search_phone' && Object.entries(this.props.found_user).length !== 0 && this.props.isRegisteredToEvent && this.props.staff.role === 'security') ? true : false});
    setInterval(() => { this.getDataByInterval()}, 15000)
  }

  componentDidUpdate(prevProps) {
    if (this.state.pathname !== history.location.pathname) {
      this.setState({pathname: history.location.pathname, user_is_found: (history.location.pathname === '/search_phone' && Object.entries(this.props.found_user).length !== 0 && this.props.isRegisteredToEvent && this.props.staff.role === 'security') ? true : false});
    }
    if (prevProps.found_user !== this.props.found_user) {
      this.setState({ user_is_found: (Object.entries(this.props.found_user).length !== 0 && this.props.isRegisteredToEvent && this.props.staff.role === 'security') ? true : false});
    }
  }

  getDataByInterval = () => {
    if (LIST_ALLOW_PAGES.includes(history.location.pathname)) {
      this.props.dispatch(getStaff(true));
    }
    if (history.location.pathname.includes('/winners/')) {
      if (this.props.winner && this.props.winner.messagesLog.find((item) => item.status === 0)) {
        this.props.dispatch(getCurrentWinner(this.props.winner.id))
      }
    }
  }
  

  themes = (found, event) => {
    let styles = {
      minHeight: window.innerHeight,
      overflow: 'hidden',
    }
    if (found) {
      styles = {
        background: `url(${background_2}) no-repeat 50% 50% #232B37`,
        backgroundSize: 'cover',
        minHeight: window.innerHeight,
        overflow: 'hidden',
      }
    }

    if (!!event && event.brand_id && config.themes[event.brand_id] && history.location.pathname === '/register') {
      styles.background = config.themes[event.brand_id].background
    }
    
    if (history.location.pathname.includes('/winners')) {
      styles.background = '#232B37'
    }
    return styles
  }

  render() {
    const { isAuthenticated, dispatch, event, isRequest, chosenEvent } = this.props;
    const { pathname, user_is_found } = this.state;

    return (
      <Router history={history}>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <AppWrapper 
              logged={isAuthenticated} 
              style={this.themes(user_is_found, chosenEvent)}
            >
              {
                (history.location.pathname !== '/qr_scan') && <Header dispatch={dispatch} event={event}/>
              }
              <ErrorDrawer dispatch={dispatch}/> 
              <Main isAuthenticated={isAuthenticated} style={(pathname === '/search_phone' || pathname === '/home') ? {alignItems: 'stretch'}: {}}>
                {
                  (!isRequest) && (
                    <Switch>
                      <Route exact path='/' component={Loading}/>
                      <Route path='/login' component={Login}/>
                      <Route path='/register' component={Registration}/>
                      <Route path='/phone_auth' component={Phone_auth}/>
                      <Route path='/success' component={Success}/>
                      <Route path='/send_email' component={ResetPassword}/>
                      <Route path='/reset_pass' component={NewPassword}/>
                      <Route path='/home' component={Home}/>
                      <Route path='/search_phone' component={SearchHome}/>
                      <Route path='/qr_scan' component={CodeScanner}/>
                      <Route path='/qr' component={QR}/>
                      <Route path='/educational_packs' component={EducationalPacks}/>
                      <Route path='/winners/:id/scane' component={PrizesScanner}/>
                      <Route path='/winners/:id' component={Winner}/>
                      <Route path='/winners' component={Prizes}/>
                      
                    </Switch>
                  )
                }
              </Main>
            </AppWrapper>
          </MuiThemeProvider>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.staffReducer.isAuthenticated,
  staff: state.staffReducer.staff,
  winner: state.prizesReducer.current_winner,
  timeOpenPage: state.staffReducer.timeOpenPage,
  event: state.staffReducer.event,
  isRequest: state.staffReducer.isRequest,
  found_user: state.securityReducer.found_user,
  isRegisteredToEvent: state.securityReducer.isRegisteredToEvent,
  chosenEvent: state.userReducer.chosenEventObj
})

export default connect(mapStateToProps)(withWidth()(App));
