/**
 * Created by piter on 4/21/19.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';

class RegisteryCounter extends Component {
  render(){
    const { classes, myRegistrations = 0, bestRegistrations = 0 } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.blockLeft}>
          <Typography component="h4" variant="h4" classes={{h4: classes.h4}}>
            my registrations
          </Typography>
          <div>
            <span className={classNames(classes.count, classes.accentCount)}>{myRegistrations}</span>
          </div>
        </div>
        <div className={classes.blockRight}>
          <Typography component="h4" variant="h4" classes={{h4: classes.h4}}>
            best registrations
          </Typography>
          <div>
            <span className={classes.count}>{bestRegistrations}</span>
          </div>
        </div>
      </div>
    )
  }
}

RegisteryCounter.propTypes = {
  classes: PropTypes.object.isRequired,
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  myRegistrations: state.staffReducer.myRegistrations, 
  bestRegistrations: state.staffReducer.bestRegistrations, 
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisteryCounter));
