export default {
  apiUrl: process.env.NODE_ENV === 'development' ? 'https://jti.home.systemnik.com/api/' : `${window.location.origin}/api/`,
  imagesUrl: process.env.NODE_ENV === 'development' ? 'https://jti.home.systemnik.com/' : `${window.location.origin}/`,
  themes: {
    1: {
      background: '#3472A6',
      checkbox: {
        defaultColor: '#fff',
        checkedColor: '#fff',
        errorColor: '#F13E3E',
      },
      switch: {
        borderColor: '#fff',
        checkedColor: '#fff',
        checkedBorderCoror: '#B4D3E2',
        checkedBackgroundColor: '#B4D3E2',
      },
      text: {
        color: '#fff'
      },
      header: {
        lineColor: 'rgba(255, 255, 255, 0.2)',
        iconColor: '#fff',
        iconActiveColor: '#B4D3E2'
      },
      button: {
        submit: {
          backgroundColor: '#fff',
          color: '#203764'
        },
        cancel: {
          borderColor: '#FFF',
          color: '#FFF'
        }
      },
      logo: {
        name: 'logo_export_a',
        width: 140,
        height: 22,
      }
    },
    4: {
      background: '#5ABFD1',
      buttonColor: '',
      switch: {
        borderColor: '#fff',
        checkedColor: '#FFD200',
      },
      text: {
        color: '#fff'
      },
      header: {
        iconColor: '#fff',
        iconActiveColor: '#FFD200'
      },
      button: {
        submit: {
          backgroundColor: '#FFD200',
          color: '#fff'
        },
        cancel: {
          borderColor: '#FFD200',
          color: '#fff'
        }
      },
      logo: {
        name: 'spirit',
        width: 145,
        height: 65,
      }
    },
    3: {
      background: '#fff',
      input: {
        defaultBorderColor: '#A2AAAD',
        onlyReadBorderColor: '#8189A5',
        labelColor: '#000000'
      },
      checkbox: {
        defaultColor: '#A2AAAD',
        checkedColor: '#A2AAAD',
        errorColor: '#F13E3E',
        labelColor: '#433F3F'
      },
      switch: {
        borderColor: '#A2AAAD',
        checkedColor: '#C90D0D',
        labelColor: '#000000'
      },
      text: {
        color: '#000000'
      },
      header: {
        lineColor: 'rgba(162, 170, 173, 0.2)',
        iconColor: '#A2AAAD',
        iconActiveColor: '#C90D0D'
      },
      button: {
        submit: {
          backgroundColor: '#CB1318',
          color: '#fff'
        },
        cancel: {
          borderColor: '#A2AAAD',
          color: '#CB1318'
        }
      },
      logo: {
        name: 'LD',
        width: 145,
        height: 65,
      }
    }
  }
}
