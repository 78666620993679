import {
  MAIN_ACCENT_COLOR,
  MAIN_ACCENT_DARK_COLOR,
  MAIN_TEXT_LG,
  MAIN_TEXT_MD,
  MAIN_TEXT_SM,
  NORMAL_TEXT_X_MD,
  MAIN_COLOR,
  ACCENT_GRAY_COLOR,
  NORMAL_TEXT_X_SM
} from '../../../../styles/constants';
  
const styles = theme => ({
  content: {
    marginBottom: 90,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 16,
      marginBottom: 40,
    }
  },

  winnerItem: {
    backgroundColor: 'white',
    borderRadius: 2,
    overflow: 'hidden',
    display: 'flex',
    cursor: 'pointer',
    marginBottom: 90,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 60,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 45,
    },
  },
  imageBlock: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    height: 145,
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      height: 145,
    },
  },
  image: {
    maxWidth: 145,
    maxHeight: 145,
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
      maxHeight: 120,
    },
  },
  contentBlock: {
    width: '50%',
    padding: '35px 0',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '15px 22px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
  },
  title: {
    fontFamily: "Montserrat-Bold",
    fontSize: `30px`,
    color: MAIN_COLOR,
    lineHeight: '30px',
    letterSpacing: '-0.5px',
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `24px`,
      lineHeight: '26px',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
      lineHeight: '22px',
      marginBottom: '5px',
    },
  },
  description: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_COLOR,
    lineHeight: '28px',
    opacity: 0.3,
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
      lineHeight: '18px',
    },
  },
  statusText: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_ACCENT_COLOR,
    fontWeight: 'bold',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
    },
  },
  statusTextFailute: {
    color: '#EA4D5C',
  },
  rateBlock: {
    width: '20%',
    padding: '35px 0',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rateText: {
    fontSize: `${NORMAL_TEXT_X_MD}px`,
    color: MAIN_COLOR,
    paddingLeft: 9,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
    },
  },
  starIcon: {
    width: 24,
    [theme.breakpoints.down('xs')]: {
      width: 16,
    },
  },
  contentInfo: {
    '& div': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    }
  },
  rate: {
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  contentImage: {
    opacity: 0.3,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: 120,
      justifyContent: 'center'
    },
  },
  
  label: {
    fontFamily: "Montserrat-Bold",
    fontSize: `30px`,
    color: 'white',
    letterSpacing: '-0.5px',
    lineHeight: '46px',
    width: '70%',
    marginBottom: '42px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `24px`,
      lineHeight: '36px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: `${MAIN_TEXT_LG}px`,
      lineHeight: '26px',
      marginBottom: '15px',
    },
  },
  disabled: {
    opacity: 0.3
  },

  simpleItem: {
    backgroundColor: 'white',
    position: 'relative',
    borderRadius: 2,
    overflow: 'hidden',
    padding: '35px 40px 35px 65px',
    display: 'flex',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      padding: '25px',
      marginBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '18px 80px 18px 18px',
      flexDirection: 'column',
      marginBottom: 10,
    },
  },
  simpleContentBlock: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  simpleRateBlock: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: '35%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  formButtonRoot: {
    backgroundColor: MAIN_ACCENT_COLOR,
    border: `2px solid ${MAIN_ACCENT_COLOR}`,
    boxSizing: 'border-box',
    borderRadius: '2px',
    boxShadow: 'none!important',
    padding: '13px',
    '&:hover': {
      backgroundColor: MAIN_ACCENT_DARK_COLOR,
      border: `2px solid ${MAIN_ACCENT_DARK_COLOR}`,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  formButtonLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${MAIN_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
    color: 'white',
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    boxShadow: 'none!important',
    fontWeight: 'bold',
    minWidth: '210px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '150px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '80px',
    }
  },
  buttonWrapper: {
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: '17px',
      top: '21px',
      paddingLeft: 0
    }
  },
  formButtonDisabled: {
    backgroundColor: `${ACCENT_GRAY_COLOR}!important`,
    border: `2px solid ${ACCENT_GRAY_COLOR}!important`,
    color: `white!important`
  },
  iconButton: {
    display: 'none',
    backgroundColor: MAIN_ACCENT_COLOR,
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  }
});

export default styles;