import {
} from '../../styles/constants';

const styles = theme => ({
  scannerContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'block'
  },
  text: {
    fontSize: 22,
    color: 'white',
    lineHeight: '32px',
    marginBottom: 20,
    [theme.breakpoints.up('xs')]: {
      fontSize: 18,
      marginBottom: 10,
    },
  },
  textWrap: {
    zIndex: 1,
    position: 'absolute',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    [theme.breakpoints.up('xs')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xs')]: {
      width: '70%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },
  wrapLink: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    }
  },
  iconLink: {
    fontSize: 28, 
    marginRight: 10, 
    color: 'white',
  },
  textLink: {
    color: 'white',
    lineHeight: '36px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `16px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `17px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `18px`,
    },
  },
});

export default styles;