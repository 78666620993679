import {
  GRAY_COLOR,
  NORMAL_TEXT_X_SM,
  NORMAL_TEXT_X_MD,
  NORMAL_TEXT_X_LG,
  LABEL_TEXT_LG,
  lABEL_TEXT_MD,
  LABEL_TEXT_SM,
  MAIN_ACCENT_COLOR,
  ERROR_RED
} from '../../../styles/constants';
import brand_theme from '../../../styles/brand_theme';

const styles = theme => ({
  bootstrapRoot: {
    boxShadow: 'none!important',
    '-webkit-appearance': 'none',
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapFormLabel: {
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat-Bold',
    letterSpacing: '2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LABEL_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${lABEL_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LABEL_TEXT_LG}px`,
    },
  },
  bootstrapInput: {
    position: 'relative',
    boxShadow: 'none!important',
    '-webkit-appearance': 'none',
    background: theme.palette.common.white,
    border: '2px solid white',
    borderRadius: 2,
    lineHeight: '30px',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
      padding: `0 10px 0 18px`,
      height: 56,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
      padding: `0 5px 0 22px`,
      height: 66,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${NORMAL_TEXT_X_LG}px`,
      padding: `0 0px 0 28px`,
      height: 76,
    },
    '&::placeholder': {
      color: GRAY_COLOR
    },
  },
  select: {
    '&:focus': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 2,
    }
  },
  icon: {
    right: 10,
    background: 'white',
    padding: '0 5px',
  },
  ...brand_theme,
  isValidInput: {
    border: `2px solid ${MAIN_ACCENT_COLOR}`,
  },
  NotValidInput: {
    border: `2px solid ${ERROR_RED}`,
  },
});

export default styles;