// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants';

export const { getBarList, setBarChoosen, authUser, cleareUser, updateUser } = createActions({
  [ActionTypes.GET_BAR_LIST]: (name, event_id, province_id, city_id) => ({name, event_id, province_id, city_id}),
  [ActionTypes.SET_BAR_CHOOSEN]: (bar) => ({bar}),
  [ActionTypes.AUTH_USER]: (phone, phone_checkword, event_id, chosenEventObj ) => ({phone, phone_checkword, event_id, chosenEventObj}),
  [ActionTypes.CLEARE_USER]: ()=> ({}),
  [ActionTypes.UPDATE_USER]: ( phone, send_sms_notifications, email, send_email_notifications, firstname, lastname, birthdate, age_verified, bar_id, event_id, opt_in_trade_insights_survey) => ({
    phone, 
    send_sms_notifications, 
    email, 
    send_email_notifications, 
    firstname, 
    lastname, 
    birthdate,
    age_verified,
    bar_id,
    event_id,
    opt_in_trade_insights_survey
  }),
});