import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import { Container } from 'styled-minimal';
import history from '../../modules/history';
import withStyles from '@material-ui/core/styles/withStyles';
import { updateTimeOpenPage } from '../../actions/staff';
import RegisteryCounter from '../../components/RegisteryCounter';
import CButton from '../../components/FormElements/CButton';
import styles from './styles';

import { cleareUser } from '../../actions/user';

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.updateTimeOpenPage()
  }

  handleClick = () => {
    const { cleareUser, staff } = this.props;

    cleareUser();
    history.replace((staff.role === 'street_team') ? '/phone_auth' : '/home');
  }

  render(){
    const { classes, user, staff, event_id, surveyUrl} = this.props;

    return (
      <HomeContainer>
        {(staff.role === 'street_team') && <RegisteryCounter/>} 
        <Grid container spacing={24} justify="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <div className={classes.textBlock}>
              <Typography component="h1" variant="h1" classes={{h1: classes.h1}}>
                {`${user.firstname} ${user.lastname}`}
              </Typography>
              <Typography component="p" variant="body2" classes={{body2: classes.text}}>
                {staff.role === 'street_team' && event_id && 'Was signed up for the event.' }
                {staff.role === 'street_team' && !event_id && 'Registered in Internal Database.' }
                {staff.role !== 'street_team' && 'Was signed up and checked in for the event'}
              </Typography>
            </div>
            {!!surveyUrl && <a href={surveyUrl} target="_blank" className={classes.surveyLink}>Proceed to survey</a>}
            <CButton
              onClick={this.handleClick}
              className={classes.submit}
              fullWidth
            >
              {staff.role === 'street_team' ? 'Next user' : 'BACK'}
            </CButton>
          </Grid>
          <Grid item xs={12} sm={6}/>
        </Grid>  
      </HomeContainer>
    )
  }
}

Success.propTypes = {
  classes: PropTypes.object.isRequired,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  surveyUrl: state.userReducer.surveyUrl,
  user: state.userReducer.user,
  staff: state.staffReducer.staff,
  event_id: state.userReducer.event_id,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    cleareUser,
    updateTimeOpenPage
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Success));
