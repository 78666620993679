import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, FormControl, InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import Select from 'react-select';
import styles from './styles';
import CInput from '../CInput';
import config from '../../../config';

const customStyles = {
  container: () => ({
    marginTop: 24
  }),
  indicatorsContainer: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    padding: 0,
    width: '100%'
  }),
  control: () => ({
    border: 'none'
  }),
  menuList: base => ({
    ...base,
    paddingBottom: 20,
    backgroundColor: 'white'
  })
}

class AuthCompleteWithLabel extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    isValid: PropTypes.bool,
    onValidate: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.array,
    onSelect: PropTypes.func,
    themeId: PropTypes.any, 
    label: PropTypes.string
  };

  renderInput = props => {
    const { onChange, onBlur=()=>{}, onFocus=()=>{}, value, required, isValid, onValidate, placeholderText = 'Search by name or mobile', themeId } = this.props;

    return (
      <CInput
        fullWidth
        autoComplete={'off'}
        autoFocus={false}
        required={required}
        isValid={isValid}
        name="input"
        value={value}
        onChange={(e) => {
          props.onChange(e)
          onChange(e);
        }}
        themeId={themeId}
        validate={(event) => {
          if (!event) return;
          onValidate((event.target) ? event.target.value : event);
          props.onBlur(event);
          onBlur()
        }}
        onFocus={(event) => {
          props.onFocus(event)
          onFocus()
        }}
        onBlur={() => {}}
        placeholder={placeholderText}
      />
    )
  }

  renderOption = ({ data, innerRef, innerProps }) => {
    const { classes } = this.props;

    return (
      <div
        className={classes.option}
        ref={innerRef}
        {...innerProps}
      >
        <span className={classes.optionName}>{data.label}</span>
        <span className={classes.optionPhone}>{data.subLabel.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3-$4")}</span>
      </div>
    )
  }

  render() {
    const { name, label="", options, onSelect, value, themeId, menuIsOpen, margin = 'none', fullWidth, classes } = this.props;

    return (
      <FormControl margin={margin} fullWidth={fullWidth}>
        <InputLabel
          shrink
          className={
            classNames(
              classes.bootstrapFormLabel,
              (config.themes[themeId]) && classes[`input_label_${themeId}`],
            )
          }
          focused={false}
        >
          {label}
        </InputLabel>
        <Select
          styles={customStyles}
          name={name}
          inputValue={value}
          onChange={onSelect}
          options={options}
          menuIsOpen={menuIsOpen}
          components={{
            Input: this.renderInput,
            Option: this.renderOption
          }}
          noOptionsMessage={() => {
            return 'No results found'
          }}
        />
      </FormControl>

    );
  }
}

export default withStyles(styles)(AuthCompleteWithLabel);