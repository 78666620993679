import {
  MAIN_ACCENT_COLOR,
  MAIN_TEXT_LG,
  MAIN_TEXT_MD,
  MAIN_TEXT_SM,
  NORMAL_TEXT_X_MD,
  MAIN_COLOR,
  NORMAL_TEXT_X_SM
} from '../../styles/constants';

const styles = theme => ({
  content: {
    marginBottom: 90,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 16,
      marginBottom: 40,
    }
  },
  header: {
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      backgroundColor: 'rgba(196, 196, 196, 0.1)',
      borderRadius: 2,
      margin: '16px 0',
      overflow: 'hidden',
      minHeight: '44px'
    },
  },
  justTextHeader: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: 'transparent',
      margin: '16px 0 0 0',
    }
  },
  headerText: {
    fontFamily: "Montserrat-Bold",
    fontSize: `${MAIN_TEXT_LG}px`,
    lineHeight: '30px',
    letterSpacing: '-0.5px',
    color: 'white',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
      textDecoration: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
      lineHeight: '16px'
    },
  },
  active: {
    '& button': {
      opacity: 1,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: MAIN_ACCENT_COLOR
    },
  }, 
  headerLinkWrap: {
    position: 'relative',
    display: 'inline-block',
    marginRight: '60px',
    padding: '15px 0',
    [theme.breakpoints.down('sm')]: {
      marginRight: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '6px 15px',
      marginRight: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  justTextBlock: {
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0 0 0',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start'
    },
  },
  headerCount: {
    fontFamily: "Montserrat-Bold",
    fontSize: `${MAIN_TEXT_MD}px`,
    color: MAIN_ACCENT_COLOR,
    lineHeight: '30px',
    letterSpacing: '2px',
    position: 'relative',
    top: -10,
    paddingLeft: 8,
    
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  emptyListText: {
    fontFamily: "Montserrat-Bold",
    fontSize: `30px`,
    lineHeight: '46px',
    letterSpacing: '-0.5px',
    color: 'white',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      fontSize: `26px`,
      lineHeight: '36px',
      width: '85%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
      lineHeight: '36px',
      width: '100%',
    },
  },

  winnerItem: {
    backgroundColor: 'white',
    borderRadius: 2,
    overflow: 'hidden',
    display: 'flex',
    cursor: 'pointer',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 13,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  imageBlock: {
    width: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    height: 145,
    [theme.breakpoints.down('sm')]: {
      width: '25%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      height: 145,
    },
  },
  image: {
    maxWidth: 145,
    maxHeight: 145,
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
      maxHeight: 120,
    },
  },
  contentInfo: {
    '& div': {
      display: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    }
  },
  contentBlock: {
    width: '50%',
    padding: '35px 0',
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '15px 22px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end'
    },
  },
  title: {
    fontFamily: "Montserrat-Bold",
    fontSize: `30px`,
    color: MAIN_COLOR,
    lineHeight: '30px',
    letterSpacing: '-0.5px',
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `24px`,
      lineHeight: '26px',
      marginBottom: '10px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
      lineHeight: '22px',
      marginBottom: '5px',
    },
  },
  description: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_COLOR,
    lineHeight: '28px',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
      lineHeight: '24px',
      marginTop: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
      lineHeight: '18px',
    },
  },
  statusText: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_ACCENT_COLOR,
    fontWeight: 'bold',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
    },
  },
  statusTextFailute: {
    color: '#EA4D5C',
  },
  rateBlock: {
    width: '20%',
    padding: '35px 0',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  rateText: {
    fontSize: `${NORMAL_TEXT_X_MD}px`,
    color: MAIN_COLOR,
    paddingLeft: 9,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
    },
  },
  starIcon: {
    width: 24,
    [theme.breakpoints.down('xs')]: {
      width: 16,
    },
  },
  rate: {
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15
  },
  contentImage: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: 120,
      justifyContent: 'center'
    },
  },
  disabled: {
    opacity: 0.3
  },

  simpleItem: {
    backgroundColor: 'white',
    borderRadius: 2,
    overflow: 'hidden',
    padding: '35px 15px 35px 65px',
    display: 'flex',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      padding: '25px',
      marginBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '18px 22px',
      flexDirection: 'column',
      marginBottom: 10,
    },
  },
  simpleContentBlock: {
    width: '75%',
    display: 'flex',
    alignItems: 'center',
    '& h4': {
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  simpleRateBlock: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: '35%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }
});

export default styles;