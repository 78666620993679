import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import { Typography, Link } from '@material-ui/core';
import { Container } from 'styled-minimal';
import history from '../../modules/history';
import { getWinnersList } from '../../actions/prizes';
import { updateTimeOpenPage } from '../../actions/staff';
import config from '../../config';
import { ReactComponent as StarIcon } from '../../resources/images/star.svg';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';


const HomeContainer = styled(Container)`
  align-items: top;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class PrizesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'winners'
    }
  }

  componentDidMount() {
    const { getWinnersList, updateTimeOpenPage } = this.props;

    updateTimeOpenPage();
    getWinnersList()
  }

  renderCalc = (list) => {
    return `${list.reduce(((val, item) => val + item.prize_received), 0)}/${list.length}`
  }

  renderEmptyList = () => {
    const { classes } = this.props;

    return (
      <div>
        <Typography component="p" variant="body1" classes={{ body1: classes.emptyListText }}>The list of winners will appear when they are determined.</Typography>
      </div>
    )
  }

  renderWinnerItem = (item) => {
    const { classes } = this.props;
    const received = item.prize_received;

    return (
      <div
        key={item.id}
        className={classes.winnerItem}
        onClick={() => history.push(`winners/${item.id}`)}
      >
        <div className={classNames(classes.imageBlock, received && classes.disabled)}>
          <img src={config.imagesUrl + item.prizeWon['^img'][""]} alt="prize" className={classes.image} />
        </div>
        <div className={classes.contentBlock}>
          <div className={classes.contentInfo}>
            <Typography component="h4" variant="h4" classes={{ h4: classNames(classes.title, received && classes.disabled) }}>{`${item.firstname} ${item.lastname}`}</Typography>
            <div className={classNames(classes.rate, received && classes.disabled)}>
              <StarIcon className={classes.starIcon} />
              <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${item.points} points`}</Typography>
            </div>
            <Typography component="p" variant="body1" classes={{ body1: classNames(classes.description, received && classes.disabled) }}>{item.prizeWon.name}</Typography>
            <Typography component="span" variant="body2" classes={{ body2: classNames(classes.statusText, !received && classes.statusTextFailute) }}>{`${received ? 'Received' : 'Not received'}`}</Typography>
          </div>
          <div className={classNames(classes.contentImage, received && classes.disabled)}>
            <img src={config.imagesUrl + item.prizeWon['^img'].small} alt="prize" className={classes.image} />
          </div>
        </div>
        <div className={classes.rateBlock}>
          <div className={classNames(classes.rate, received && classes.disabled)}>
            <StarIcon />
            <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${item.points} points`}</Typography>
          </div>
        </div>
      </div>
    )
  }

  renderSimpleItem = (item) => {
    const { classes } = this.props;

    return (
      <div key={item.id} className={classes.simpleItem}>
        <div className={classes.simpleContentBlock}>
          <Typography component="h4" variant="h4" classes={{ h4: classes.title }}>{`${item.firstname} ${item.lastname}`}</Typography>
        </div>
        <div className={classes.simpleRateBlock}>
          <div className={classes.rate}>
            <StarIcon className={classes.starIcon} />
            <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${item.points} points`}</Typography>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { classes, spare_winners, replaced_winners, winners } = this.props;
    const { active } = this.state;

    return (
      <HomeContainer>
        <div className={classNames(classes.header, winners && !winners.length && classes.justTextHeader)}>
          <div className={classNames(classes.headerLinkWrap, active === 'winners' && classes.active, winners && !winners.length && classes.justTextBlock)}>
            <Link
              component="button"
              variant="body2"
              classes={{ button: classNames(classes.headerText) }}
              onClick={() => this.setState({ active: 'winners' })}
            >
              Winners
            </Link>
            {(winners && winners.length) ? <span className={classes.headerCount}>{this.renderCalc(winners)}</span> : <div/>}
          </div>
          {
            winners && winners.length ? (
              <React.Fragment>
                {
                  spare_winners && spare_winners.length ? (
                    <div className={classNames(classes.headerLinkWrap, active === 'space_winners' && classes.active)}>
                      <Link
                        component="button"
                        variant="body2"
                        classes={{ button: classes.headerText }}
                        onClick={() => this.setState({ active: 'space_winners' })}
                      >
                        Spare Winners
                  </Link>
                    </div>
                  ) : <div />
                }
                {
                  replaced_winners && replaced_winners.length ? (
                    <div className={classNames(classes.headerLinkWrap, active === 'replaced' && classes.active)}>
                      <Link
                        component="button"
                        variant="body2"
                        classes={{ button: classes.headerText }}
                        onClick={() => this.setState({ active: 'replaced' })}
                      >
                        Replaced
                      </Link>
                      {(winners && winners.length) ? <span className={classes.headerCount}>{replaced_winners.length}</span> : <div/>}
                    </div>
                  ) : <div />
                }
              </React.Fragment>
            ) : <div />
          }
        </div>
        <div className={classes.content}>
          {
            (winners && !winners.length) ? (
              this.renderEmptyList()
            ) : (
                <React.Fragment>
                  {
                    (active === 'winners' && winners) && winners.map((item) => this.renderWinnerItem(item))
                  }{
                    (active === 'space_winners' && spare_winners) && spare_winners.map((item) => this.renderSimpleItem(item))
                  }{
                    (active === 'replaced' && replaced_winners) && replaced_winners.map((item) => this.renderSimpleItem(item))
                  }
                </React.Fragment>
              )
          }
        </div>
      </HomeContainer >
    )
  }
}

PrizesScreen.propTypes = {
  classes: PropTypes.object.isRequired
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  winners: state.prizesReducer.winners,
  spare_winners: state.prizesReducer.spare_winners,
  replaced_winners: state.prizesReducer.replaced_winners,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getWinnersList,
    updateTimeOpenPage
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(PrizesScreen)));
