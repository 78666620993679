import {
  MAIN_ACCENT_COLOR,
  ERROR_RED
} from '../../../styles/constants';
import brand_theme from '../../../styles/brand_theme';


const styles = theme => ({
  label: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: -0.5
  },
  root: {
    color: MAIN_ACCENT_COLOR,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px'
    },
  },
  labelRoot: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  checked: {
    color: `${MAIN_ACCENT_COLOR}!important`,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px'
    },
  },
  rootError: {
    color: ERROR_RED,
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px'
    },
  },
  ...brand_theme
});

export default styles;