import { 
  MAIN_COLOR,
  GRAY_COLOR,
  MAIN_ACCENT_COLOR
} from '../../styles/constants';

const styles = theme => ({
  bottomContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: 130
    },
  },
  bottomContent: {
    maxWidth: '1170px',
    padding: '50px 15px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 100,
    },
    [theme.breakpoints.up('md')]: {
      margin: -12,
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
    },
  },
  codeWrap: {
    padding: 20,
    marginRight: 40,
    backgroundColor: '#fff',
    boxShadow: '0px 2px 18px rgba(103, 124, 154, 0.15)',
    borderRadius: 2,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: -110,
    }
  },
  userInfoWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    }
  },
  codeImage: {
    height: 140,
    width: 140
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    [theme.breakpoints.down('sm')]: {
      right: 15,
      top: 30,
    },
    [theme.breakpoints.up('md')]: {
      top: 65,
    },
    [theme.breakpoints.up('lg')]: {
      top: 70
    },
  },
  wrapInput: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    paddingTop: 60,
    [theme.breakpoints.down('sm')]: {
      padding: '30px 15px',
    },
  },
  name: {
    fontFamily: "Montserrat-Bold",
    fontSize: 24,
    lineHeight: '40px',
    letterSpacing: -0.5,
    color: MAIN_COLOR,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    },
  },
  text: {
    fontSize: 20,
    lineHeight: '32px',
    color: '#8E95AE',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: 5
    },
  },
  imageWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    },
  },
  paper: {
    margin: '0 auto',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing.unit * 42,
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 50,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing.unit * 70,
    }
  },
  textLink: {
    color: 'white',
    lineHeight: '36px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `16px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `17px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `18px`,
    },
  },
  wrapLink: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: 50,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      paddingTop: 80,
    }
  },
  wrapBottomLink: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: -90,
      display: 'flex',
      width: '100%',
      left: 0,
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  iconLink: {
    fontSize: 28, 
    marginRight: 10, 
    color: 'white',
  },
  link: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover span': {
      backgroundColor: 'transparent',
      color: MAIN_ACCENT_COLOR,
    },
    '&:hover p': {
      backgroundColor: 'transparent',
      color: MAIN_ACCENT_COLOR,
    },
  },
  hideXS: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  modalContent: {
    width: '100%',
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '40px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  titleModal: {
    color: MAIN_COLOR,
    fontFamily: "Montserrat-Bold",
    lineHeight: '60px',
    marginTop: 20,
    marginBottom: 20,
    letterSpacing: -0.5,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: `30px`,
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `35px`,
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `40px`,
      textAlign: 'center'
    },
  },
  codeComments: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },

  },
  vipWrapper: {
    backgroundColor: '#E52033',
    borderRadius: '30px',
    display: 'inline-block',
    padding: '4px 16px'
  },
  vipText: {
    color: '#fff!important',
    lineHeight: '16px',
    fontWeight: 600,
    letterSpacing: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: `12px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `12px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `13px`,
    },
  },
  textModal: {
    color: GRAY_COLOR,
    opacity: 0.9,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `20px`,
      marginBottom: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `25px`,
      marginBottom: 40
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `30px`,
      marginBottom: 50
    },
  },
});

export default styles;