import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { STATUS, ActionTypes } from "../constants/index";

export const staffState = {
    isAuthenticated: false,
    staff: {},
    event: {},
    checkedInUsers: null,
    checkedInUsersOutOf: null,
    myRegistrations: null,
    bestRegistrations: null,
    errorMessage: null,
    status: STATUS.IDLE,
    timeOpenPage: null,
    isRequest: false,
    ressetPasswordStep: null,
    events: [],
    globalChosenEvent: true,
};

export default {
    staffReducer: handleActions(
            {
                [ActionTypes.AUTH_STAFF]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                        errorMessage: {$set: null},
                    }),
                [ActionTypes.AUTH_STAFF_SUCCESS]: (
                        state,
                {
                payload: {
                event,
                        staff,
                        checkedInUsers,
                        checkedInUsersOutOf,
                        myRegistrations,
                        bestRegistrations,
                },
                }
                ) =>
                    immutable(state, {
                        event: {$set: event},
                        checkedInUsers: {$set: checkedInUsers},
                        checkedInUsersOutOf: {$set: checkedInUsersOutOf},
                        myRegistrations: {$set: myRegistrations},
                        bestRegistrations: {$set: bestRegistrations},
                        errorMessage: {$set: null},
                        staff: {$set: staff},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.AUTH_STAFF_FAILURE]: (
                        state,
                { payload: { statusMeans } }
                ) =>
                    immutable(state, {
                        staff: {$set: {}},
                        errorMessage: {$set: statusMeans},
                        event: {$set: {}},
                        status: {$set: STATUS.ERROR},
                    }),
                [ActionTypes.GET_STAFF_SUCCESS]: (
                        state,
                {
                payload: {
                event,
                        staff,
                        checkedInUsers,
                        checkedInUsersOutOf,
                        myRegistrations,
                        bestRegistrations,
                },
                }
                ) =>
                    immutable(state, {
                        event: {$set: event},
                        staff: {$set: staff},
                        checkedInUsers: {$set: checkedInUsers},
                        checkedInUsersOutOf: {$set: checkedInUsersOutOf},
                        myRegistrations: {$set: myRegistrations},
                        bestRegistrations: {$set: bestRegistrations},
                        status: {$set: STATUS.READY},
                        isAuthenticated: {$set: true},
                        isRequest: {$set: false},
                    }),
                [ActionTypes.GET_STAFF_FAILURE]: (state) =>
                    immutable(state, {
                        isAuthenticated: {$set: false},
                        isRequest: {$set: false},
                    }),
                [ActionTypes.CHECK_USER_TO_EVENT_SUCCESS]: (state) =>
                    immutable(state, {
                        checkedInUsers: {$set: state.checkedInUsers + 1},
                    }),
                [ActionTypes.LOG_OUT_STAFF_SUCCESS]: (state) =>
                    immutable(state, {
                        staff: {$set: {}},
                        event: {$set: {}},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.LOG_OUT_STAFF_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                    }),
                [ActionTypes.UPDATE_TIME_OPEN_PAGE]: (state) =>
                    immutable(state, {
                        timeOpenPage: {$set: new Date().valueOf()},
                    }),
                [ActionTypes.RESET_PASSWORD_STAFF]: (state) =>
                    immutable(state, {
                        isRequest: {$set: true},
                    }),
                [ActionTypes.RESET_PASSWORD_STAFF_SUCCESS]: (state) =>
                    immutable(state, {
                        ressetPasswordStep: {$set: 1},
                        isRequest: {$set: false},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.CREATE_NEW_PASSWORD_STAFF_SUCCESS]: (state) =>
                    immutable(state, {
                        ressetPasswordStep: {$set: 2},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.RESET_PASSWORD_STAFF_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                        isRequest: {$set: false},
                    }),
                [ActionTypes.RESET_PASS_STEP]: (state) =>
                    immutable(state, {
                        ressetPasswordStep: {$set: null},
                    }),
                [ActionTypes.GET_EVENTS_SUCCESS]: (
                        state,
                { payload: { events } }
                ) =>
                    immutable(state, {
                        events: {$set: events},
                    }),
                [ActionTypes.GET_EVENTS_FAILURE]: (
                        state,
                        ) =>
                    immutable(state, {
                        events: {$set: []},
                    }),

                [ActionTypes.SET_GLOBAL_CHOSEN_EVENT]: (state, { payload }) =>
                    immutable(state, {
                        globalChosenEvent: {$set: payload},
                    }),
            },
            staffState
            ),
};
