import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { STATUS, ActionTypes } from '../constants/index';

export const prizesReducer = {
    status: null,
    winners: null,
    spare_winners: null,
    current_winner: null,
    replaced_winners: null,
    sentUserMessages: {},
};

export default {
    prizesReducer: handleActions(
            {
                [ActionTypes.GET_WINNERS_LIST]: state =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                    }),
                [ActionTypes.GET_WINNERS_LIST_SUCCESS]: (state, { payload: { winners, spare_winners, replaced_winners } }) =>
                    immutable(state, {
                        spare_winners: {$set: spare_winners.sort((a, b) => b.points - a.points)},
                        winners: {$set: winners.sort((a, b) => b.points - a.points)},
                        replaced_winners: {$set: replaced_winners.sort((a, b) => b.points - a.points)},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.GET_WINNERS_LIST_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                        winners: {$set: []},
                    }),

                [ActionTypes.GET_CURRENT_WINNER]: state =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                    }),
                [ActionTypes.GET_CURRENT_WINNER_SUCCESS]: (state, { payload: { user } }) => {
                    if (!user || !user.id) {
                        return state; // Prevent state mutation if user data is invalid
                    }

                    return immutable(state, {
                        current_winner: {$set: user},
                        status: {$set: STATUS.READY},
                    })
                },
                [ActionTypes.GET_CURRENT_WINNER_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                    }),

                [ActionTypes.SET_HAND_OUT_PRIZE]: state =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                    }),
                [ActionTypes.SET_HAND_OUT_PRIZE_SUCCESS]: (state, { payload: { user } }) =>
                    immutable(state, {
                        current_winner: {$set: user},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.SET_HAND_OUT_PRIZE_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                    }),

                [ActionTypes.REPLACE_WINNER]: state =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                    }),
                [ActionTypes.REPLACE_WINNER_SUCCESS]: (state, { payload: { user } }) =>
                    immutable(state, {
                        current_winner: {$set: user},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.REPLACE_WINNER_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                    }),

                [ActionTypes.SEND_WINNER_MESSAGE]: state =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                    }),
                [ActionTypes.SEND_WINNER_MESSAGE_SUCCESS]: (state, { payload: { user, default_message } }) => {
                    let sentUserMessages = {...state.sentUserMessages};

                    if (default_message) {
                        if (sentUserMessages[user.id]) {
                            console.log(sentUserMessages)
                            sentUserMessages[user.id] = {...sentUserMessages[user.id], [default_message]: true}
                        } else {
                            sentUserMessages[user.id] = {[default_message]: true}
                        }
                    }
                    return (
                            immutable(state, {
                                current_winner: {$set: user},
                                sentUserMessages: {$set: sentUserMessages},
                                status: {$set: STATUS.READY},
                            })
                            )
                },
                [ActionTypes.SEND_WINNER_MESSAGE_FAILURE]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.ERROR},
                    }),
            },
            prizesReducer,
            ),
};