import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { withStyles, } from '@material-ui/core/styles';
import config from '../../../config';
import styles from './styles';

class CButton extends React.Component {
  static propTypes = {
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func,
    typeStyle: PropTypes.string,
  };

  render() {
    const { fullWidth, children, type, typeStyle = 'accent', onClick, disabled, themeId, style, classes } = this.props;
    return (
      <Button
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        disabled={disabled}
        style={style}
        onClick={onClick}
        type={type}
        classes={{
          root: classNames(
            classes.defaultRoot,
            (config.themes[themeId]) && classes[`button_${themeId}`],
            (typeStyle === 'dark') && classes.darkRoot, 
            (typeStyle === 'white') && classes.whiteRoot,
            (typeStyle === 'transparent') && classes.transparentRoot,
            (typeStyle === 'white' && config.themes[themeId]) && classes[`button_white_${themeId}`],
          ),
          disabled: classes.disabled,
          label: classes.label
        }}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(styles)(CButton);