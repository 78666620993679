import userReducer from './userReducer';
import staffReducer from './staffReducer';
import errorReducer from './errorReducer';
import securityReducer from './securityReducer';
import prizesReducer from './prizesReducer'

export default {
  ...userReducer,
  ...staffReducer,
  ...errorReducer,
  ...securityReducer,
  ...prizesReducer
};