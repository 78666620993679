import { 
  LARGE_TEXT_SM,
  LARGE_TEXT_MD,
  LARGE_TEXT_LG,
  MAIN_TEXT_LG,
  MAIN_COLOR,
  GRAY_COLOR
} from '../../styles/constants';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit*6,
  },
  emptyBlock: {
    margin: '25px 12px',
    backgroundColor: '#4F555F',
    height: 2,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: '15px 12px',
    },
  },
  body2: {
    display: 'block',
    width: '100%',
    marginBottom: 15,
    color: 'white',
    textAlign: 'left',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: `16px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `17px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `18px`,
    },
  },
  textBlock: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-start'
  },
  smPaddingTopHide: {
    [theme.breakpoints.down('xs')]: {
      marginTop: -24,
    },
  },
  h1: {
    color: 'white',
    fontFamily: "Montserrat-Bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LARGE_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${LARGE_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LARGE_TEXT_LG}px`,
    },
    marginBottom: 10
  },
  surveyLink: {
    fontFamily: 'Montserrat-Bold',
    display: 'block',
    color: '#00AA60',
    marginBottom: 20,
  },
  text: {
    color: 'white',
    marginBottom: 65,
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: `18px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `19px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
  },
  paper: {
    margin: '0 auto',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing.unit * 42,
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 50,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing.unit * 70,
    },
  },
  modalContent: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '40px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  titleModal: {
    color: MAIN_COLOR,
    fontFamily: "Montserrat-Bold",
    lineHeight: '60px',
    letterSpacing: -0.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: `30px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `35px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `40px`,
    },
  },
  textModal: {
    color: GRAY_COLOR,
    opacity: 0.9,
    lineHeight: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `20px`,
      marginBottom: 20
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `25px`,
      marginBottom: 40
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `30px`,
      marginBottom: 50
    },
  },
  xsHide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
  },
  xsShow: {
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  wrapInput: {
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    paddingBottom: 18
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 25
    },
    [theme.breakpoints.up('md')]: {
      top: 30
    },
    [theme.breakpoints.up('lg')]: {
      top: 34
    },
  },
});

export default styles;