import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl, InputLabel, InputBase, FormHelperText, withStyles } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import styles from './styles';
import config from '../../../config';

function TextMaskCustom (props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      showMask
      guide={false}
      mask={['+', /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

class CInput extends Component {
  constructor(props){
    super(props)
    this.inputElement = null;
  }

  static propTypes = {
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    error_text: PropTypes.string,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    autoFocus: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    // onPaste: PropTypes.func,
    // onCut: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    isValid: PropTypes.bool,
    validate: PropTypes.func,
    maxLenth: PropTypes.number,
    margin: PropTypes.string,
    inputProps: PropTypes.any,
    readOnly: PropTypes.bool,
    themeId: PropTypes.any,
    autoComplete: PropTypes.string, 
  };

  // componentDidMount() {
  //   this.inputElement.focus()
  // }

  componentDidUpdate(prevProps) {
    const { value, maxLenth } = this.props;

    if (prevProps.value !== value && value) {
      if (maxLenth && value.length === maxLenth) {
        this.inputElement.blur()
     };
    }
  }

  render() {
    const { 
      fullWidth, 
      required, 
      label,
      error_text,
      classes, 
      defaultValue,
      name,
      type,
      autoFocus,
      value,
      onChange,
      // onPaste,
      // onCut,
      onFocus,
      placeholder,
      isValid,
      validate,
      inputProps,
      margin = 'none',
      readOnly,
      themeId,
      autoComplete='' 
    } = this.props;

    return (
      <FormControl margin={margin} fullWidth={fullWidth}>
        <InputLabel 
          shrink 
          className={
            classNames(
              classes.bootstrapFormLabel,
              (config.themes[themeId]) && classes[`input_label_${themeId}`],
            )
          } 
          focused={false}
        >
          {label}
        </InputLabel>
        <InputBase
          ref={'input'}
          inputRef={(ref) => this.inputElement = ref}
          defaultValue={defaultValue}
          name={name}
          type={type}
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          // onPaste={onPaste}
          // onCut={onCut}
          onFocus={onFocus}
          onBlur={validate}
          readOnly={readOnly}
          placeholder={placeholder}
          inputProps={inputProps}
          autoComplete={autoComplete}
          classes={{
            root: (label) && classes.bootstrapRoot,
            input: classNames(
              classes.bootstrapInput,
              (config.themes[themeId]) && classes[`input_${themeId}`],
              (required && isValid === true) && classes.isValidInput,  
              (required && isValid === false) && classes.NotValidInput,
              (readOnly && !value.length) && classes.onlyReadInput,
              (readOnly && !value.length && config.themes[themeId]) && classes[`input_read_${themeId}`],
            ),
          }}
          inputComponent={(type === 'tel') ? TextMaskCustom: 'input'}
        />
        {
          (required && isValid === false) && <FormHelperText error classes={{root: classes.helperText}} >{error_text}</FormHelperText>
        }
      </FormControl>
    );
  }
}

export default withStyles(styles)(CInput);