import {
  MAIN_ACCENT_COLOR,
  MAIN_TEXT_LG,
  MAIN_TEXT_SM,
  MAIN_TEXT_MD
} from '../../styles/constants';

const styles = theme => ({
  scannerContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    display: 'block'
  },
  text: {
    fontSize: 22,
    color: 'white',
    lineHeight: '32px',
    marginBottom: 20,
    [theme.breakpoints.up('xs')]: {
      fontSize: 18,
      marginBottom: 10,
    },
  },
  textWrap: {
    zIndex: 1,
    position: 'absolute',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    [theme.breakpoints.up('xs')]: {
      width: '70%',
    },
    [theme.breakpoints.up('xs')]: {
      width: '70%',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%',
    },
  },
  wrapLink: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    }
  },
  iconLink: {
    fontSize: 28, 
    marginRight: 10, 
    color: 'white',
  },
  textLink: {
    color: 'white',
    lineHeight: '36px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `16px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `17px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `18px`,
    },
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '23px',
    },
    [theme.breakpoints.up('md')]: {
      width: '26px',
      height: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '33px',
      height: '29px',
    },
  },
  iconWrapper: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 100,
    backgroundColor: '#EA4D5C',
    [theme.breakpoints.down('sm')]: {
      width: '72px',
      height: '72px',
      marginBottom: '18px',
    },
    [theme.breakpoints.up('md')]: {
      width: '90px',
      height: '90px',
      marginBottom: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '106px',
      height: '106px',
      marginBottom: '40px',
    },
  },
  succesColor: {
    backgroundColor: MAIN_ACCENT_COLOR,
  },
  title: {
    fontFamily: "Montserrat-Bold",
    fontSize: `30px`,
    color: 'white',
    letterSpacing: '-0.5px',
    lineHeight: '46px',
    width: '70%',
    marginBottom: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: `24px`,
      lineHeight: '36px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: `${MAIN_TEXT_LG}px`,
      lineHeight: '26px',
    },
  },
  subTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: `${MAIN_TEXT_LG}px`,
    lineHeight: '30px',
    letterSpacing: '-0.5px',
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: '40%',
      fontSize: `${MAIN_TEXT_MD}px`,
      lineHeight: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '35%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      fontSize: `${MAIN_TEXT_MD}px`,
      lineHeight: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
      lineHeight: '16px',
      width: '80%',
    },
  },
  centerBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bottomBlock: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  scanButton: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '25px',
      paddingLeft: 0,
    },
  },
  scanButtonText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${MAIN_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    boxShadow: 'none!important',
    color: 'white',
    fontWeight: 'bold'
  },
  scanIconWrapper: {
    [theme.breakpoints.down('xs')]: {
      width: '47px',
      height: '47px',
      marginRight: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '47px',
      height: '47px',
      marginRight: '15px',
    },
    [theme.breakpoints.up('md')]: {
      width: '57px',
      height: '57px',
      marginRight: '20px',
    },
    border: `2px solid ${MAIN_ACCENT_COLOR}`,
    width: '68px',
    height: '68px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: '30px',
  },
  topBlock: {
    height: '84px',
    [theme.breakpoints.down('xs')]: {
      height: '0px',
    },
  }
});

export default styles;