import {
  MAIN_ACCENT_COLOR,
  MAIN_ACCENT_DARK_COLOR,
  MAIN_TEXT_LG,
  MAIN_TEXT_MD,
  MAIN_TEXT_SM,
  NORMAL_TEXT_X_MD,
  MAIN_COLOR,
  ACCENT_GRAY_COLOR,
  NORMAL_TEXT_X_SM
} from '../../styles/constants';

const styles = theme => ({
  content: {
    marginBottom: 90,
    padding: '40px 68px 70px 68px',
    backgroundColor: 'white',
    borderRadius: 2,
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 40px 70px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '22px 22px 38px',
    },
  },
  header: {
    margin: '25px 0',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 0',
    },
  },
  headerText: {
    fontFamily: "Montserrat-Bold",
    fontSize: `${MAIN_TEXT_LG}px`,
    lineHeight: '30px',
    letterSpacing: '-0.5px',
    color: 'white',
    opacity: 0.5,
    padding: '0 18px',
    '&:hover': {
      textDecoration: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
      lineHeight: '16px',
      padding: '0 9px',
    },
  },
  headerIcon: {
    width: '35px',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '18px',
    },
  },
  headerLinkWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0',
  },

  title: {
    fontFamily: "Montserrat-Bold",
    fontSize: `50px`,
    color: MAIN_COLOR,
    letterSpacing: '-0.5px',
    marginBottom: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `34px`,
      marginBottom: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
      lineHeight: '22px',
      marginBottom: '5px',
    },
  },
  rateBlock: {
    padding: '25px 0',
  },
  rateText: {
    fontSize: `${NORMAL_TEXT_X_MD}px`,
    color: MAIN_COLOR,
    paddingLeft: 9,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
    },
  },
  starIcon: {
    width: 24,
    [theme.breakpoints.down('xs')]: {
      width: 16,
    },
  },
  rate: {
    opacity: 0.8,
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_COLOR,
    lineHeight: '28px',
    opacity: 0.8,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
      lineHeight: '24px',
      marginTop: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
      lineHeight: '18px',
      marginTop: 10,
    },
  },
  grayDescription: {
    color: '#8E95AE',
    marginTop: 0
  },
  statusText: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_ACCENT_COLOR,
    fontWeight: 'bold',
    lineHeight: '34px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
    },
  },
  statusTextFailute: {
    color: '#EA4D5C',
  },
  contentInfo: {
    padding: '30px 0 0 0',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 10
    }
  },
  contentImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  imageBlock: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      padding: '15px 0 5px 0',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  image: {
    width: 'auto',
    maxWidth: 270,
    maxHeight: 270,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130,
      maxHeight: 130,
    }
  },
  buttons: {
    padding: '28px 0',
  },
  dialog: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    minHeight: 62
  },
  iconButton: {
    backgroundColor: '#EA4D5C',
    width: 74,
    height: 74,
    marginLeft: '16px',
    [theme.breakpoints.down('md')]: {
      width: 70,
      height: 70,
    },
    [theme.breakpoints.down('sm')]: {
      width: 62,
      height: 62,
      marginLeft: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      width: 44,
      height: 44,
      marginLeft: '10px',
    }
  },
  icon: {
    [theme.breakpoints.down('xs')]: {
      width: 16,
      height: 16,
    }
  },
  succesColor: {
    backgroundColor: MAIN_ACCENT_COLOR
  },
  dialogTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_COLOR,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    marginRight: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: `16px`,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `14px`,
    }
  },

  messageTitle: {
    fontFamily: "Montserrat-Bold",
    fontSize: `30px`,
    color: MAIN_COLOR,
    letterSpacing: '-0.5px',
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
      marginBottom: 15,
    }
  },
  chatContent: {
    padding: '25px 0 0 0',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 0 0 0',
    }
  },
  itemMessage: {
    backgroundColor: '#EFF2F9',
    borderRadius: 3,
    padding: '22px 22px 28px 42px',
    position: 'relative',
    marginLeft: '20px',
    marginBottom: '30px',
    '&:before': {
      content: `''`,
      position: 'absolute',
      top: 0,
      left: '-38px',
      border: '20px solid transparent',
      borderTop: '0px solid #EFF2F9',
      borderRight: '20px solid #EFF2F9',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '17px 17px 16px 28px',
      marginBottom: '12px',
    }
  },
  borderedMessage: {
    backgroundColor: 'white',
    border: '1px solid #CDD1E2',
    borderRadius: '0 3px 3px 3px',
    '&:before': {
      content: `''`,
      position: 'absolute',
      top: '-1px',
      left: '-40px',
      border: '20px solid transparent',
      borderTop: '0px solid #CDD1E2',
      borderRight: '20px solid #CDD1E2',
    },
    '&:after': {
      content: `''`,
      position: 'absolute',
      top: 0,
      left: '-38px',
      border: '20px solid transparent',
      borderTop: '0px solid white',
      borderRight: '20px solid white',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '17px 17px 22px 28px',
    }
  },
  messageText: {
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_COLOR,
    lineHeight: '28px',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${MAIN_TEXT_MD}px`,
      lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `14px`,
      lineHeight: '20px',
    }
  },
  infoMessage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  leftInfoMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  statusMessage: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 30
  },
  statusIcon: {
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      marginRight: 4,
      width: 11
    }
  },
  repeatIcon: {
    marginRight: 10
  },
  repeatIcon_xs: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: `block`,
    }
  },
  messTextDetail: {
    fontSize: `${MAIN_TEXT_MD}px`,
    lineHeight: '28px',
    color: '#4C525E',
    opacity: 0.8,
    textTransform: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: `12px`,
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  dark: {
    color: MAIN_COLOR,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  input: {
    display: 'block',
    marginBottom: '25px',
    fontSize: `${MAIN_TEXT_LG}px`,
    color: MAIN_COLOR,
    lineHeight: '28px',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${MAIN_TEXT_MD}px`,
      lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `14px`,
      lineHeight: '20px',
    }
  },
  formButtonRoot: {
    backgroundColor: MAIN_ACCENT_COLOR,
    border: `2px solid ${MAIN_ACCENT_COLOR}`,
    boxSizing: 'border-box',
    borderRadius: '2px',
    boxShadow: 'none!important',
    padding: '13px',
    '&:hover': {
      backgroundColor: MAIN_ACCENT_DARK_COLOR,
      border: `2px solid ${MAIN_ACCENT_DARK_COLOR}`,
    },
  },
  formButtonLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${MAIN_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
    color: 'white',
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    boxShadow: 'none!important',
    fontWeight: 'bold',
    minWidth: '250px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '120px',
    }
  },
  formButtonDisabled: {
    backgroundColor: `${ACCENT_GRAY_COLOR}!important`,
    border: `2px solid ${ACCENT_GRAY_COLOR}!important`,
    color: `white!important`
  },
});

export default styles;