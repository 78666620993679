/**
 * @module Sagas/Prizes
 * @desc Prizes
 */

import { all, put, takeLatest, call } from 'redux-saga/effects';
import { request } from '../modules/client';
import { ActionTypes } from '../constants/index';
import history from '../modules/history';

export function* getWinnersList() {
  try {
    const response = yield call(
      request,
      `staff/prizes/winners`,
      {
        method: 'GET',
      }
    );
    yield put({
      type: ActionTypes.GET_WINNERS_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_WINNERS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getCurrentWinner({payload: {id}}) {
  try {
    const response = yield call(
      request,
      `staff/prizes/winners?user_id=${id}`,
      {
        method: 'GET',
      }
    );
    yield put({
      type: ActionTypes.GET_CURRENT_WINNER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CURRENT_WINNER_FAILURE,
      payload: err,
    });
  }
}

export function* setHandOutPrize({payload: {user_id}}) {
  try {
    const response = yield call(
      request,
      `staff/Prizes/handOutPrize`,
      {
        method: 'POST',
        payload: { user_id }
      }
    );
    yield put({
      type: ActionTypes.SET_HAND_OUT_PRIZE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SET_HAND_OUT_PRIZE_FAILURE,
      payload: err,
    });
  }
}

export function* replaceWinner({payload: {user_id, new_user_id}}) {
  try {
    const response = yield call(
      request,
      `staff/Prizes/replaceWinner`,
      {
        method: 'POST',
        payload: { user_id, new_user_id }
      }
    );
    history.replace(`/winners`);
    yield put({
      type: ActionTypes.REPLACE_WINNER_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.REPLACE_WINNER_FAILURE,
      payload: err,
    });
  }
}

export function* sendWinnerMessage({payload: {user_id, message_id, text, default_message}}) {
  try {
    const response = yield call(
      request,
      `staff/Prizes/sendSMS`,
      {
        method: 'POST',
        payload: { user_id, message_id, text }
      }
    );
    yield put({
      type: ActionTypes.SEND_WINNER_MESSAGE_SUCCESS,
      payload: {...response, default_message},
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SEND_WINNER_MESSAGE_FAILURE,
      payload: err,
    });
  }
}

/**
 * Prizes Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_WINNERS_LIST, getWinnersList),
    takeLatest(ActionTypes.GET_CURRENT_WINNER, getCurrentWinner),
    takeLatest(ActionTypes.SET_HAND_OUT_PRIZE, setHandOutPrize),
    takeLatest(ActionTypes.REPLACE_WINNER, replaceWinner),
    takeLatest(ActionTypes.SEND_WINNER_MESSAGE, sendWinnerMessage),
  ]);
}
