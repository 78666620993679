import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Typography, Grid, Button, Icon } from '@material-ui/core';
import { Container } from 'styled-minimal';
import withStyles from '@material-ui/core/styles/withStyles';
import { SCANE_IMAGE } from '../../styles/constants';
import history from '../../modules/history';
import AddUserIcon from '../../components/AddUserIcon'
import ShowQRIcon from '../../components/ShowQRIcon'
import CheckInCounter from '../../components/CheckInCounter';
import styles from './styles';
import { cleareEventUser, updateTimeOpenPage } from '../../actions/staff';

import './keyframes.css';

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { updateTimeOpenPage } = this.props
    updateTimeOpenPage();
  }

  render() {
    const { classes, cleareEventUser, staff } = this.props;

    return (
      <HomeContainer>
        {
          (
            <div className={classes.wrapLink}>
              <Button
                aria-label="Search"
                style={{ textTransform: 'none', marginTop: 'none' }}
                className={classes.link}
                onClick={() => {
                  cleareEventUser();
                  history.push('/search_phone', { phoneField: true });
                }}
              >
                <Icon className={classes.icon}>
                  search
                </Icon>
                <Typography component="p" variant="body2" classes={{ body2: classes.text }}>
                  Search
                </Typography>
              </Button>
              {
                staff.role === "security" && (
                  <Button
                    aria-label="New guest"
                    style={{ textTransform: 'none', marginTop: 'none', marginLeft: '50px' }}
                    className={classes.link}
                    onClick={() => {
                      cleareEventUser();
                      history.push('/phone_auth');
                    }}
                  >
                    <AddUserIcon style={{ marginRight: 7 }} />
                    <Typography component="p" variant="body2" classes={{ body2: classes.text }}>
                      New guest
                    </Typography>
                  </Button>
                )
              }
              
              <Button
                aria-label="App QR"
                style={{ textTransform: 'none', marginTop: 'none', marginLeft: '50px' }}
                className={classes.link}
                onClick={() => {
                    cleareEventUser();
                    history.push('/qr');
                }}
              >
                <ShowQRIcon style={{ marginRight: 7 }} />
                <Typography component="p" variant="body2" classes={{ body2: classes.text }}>
                  App QR
                </Typography>
              </Button>
              
            </div>
          )
        }
        {
          (staff.role === 'security') && <CheckInCounter containerClasses={classes.checkCounter} />
        }
        <Grid container spacing={24} justify="center" alignItems="center" className={classes.contentWrap}>
          <Grid item xs={12} sm={6} className={classes.hideXS}>
            <Typography component="h1" variant="h1" classes={{ h1: classes.h1 }}>
              Scan personal <br /> QR code
            </Typography>
            {
              (staff.role === 'edu_team') && (
                <Typography component="p" variant="body2" classes={{ body2: classes.text }}>
                  Scan user’s personal QR code
              </Typography>
              )
            }
          </Grid>
          <Grid item xs={12} sm={6} className={classes.imageWrap}>
            <div className={classes.pulseBtn} onClick={() => { history.push('/qr_scan'); }}>
              <div className={`${classes.circle} ${classes.circleBig}`}></div>
              <div className={`${classes.circle} ${classes.circleMedium}`}></div>
              <div className={`${classes.circle} ${classes.circleSmall}`}></div>
              <img src={SCANE_IMAGE} className={classes.image} alt="Scane" />
            </div>
          </Grid>
          <div className={classes.bottomTextWrap}>
            <Typography component="p" variant="body2" classes={{ body2: classes.bottomTitle }}>
              Scan personal QR code
            </Typography>
            {
              (staff.role === 'edu_team') && (
                <Typography component="p" variant="body2" classes={{ body2: classes.text }}>
                  Scan user’s personal QR code
              </Typography>
              )
            }
          </div>
        </Grid>
      </HomeContainer>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  staff: state.staffReducer.staff
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    cleareEventUser,
    updateTimeOpenPage,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home));
