import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import './index.css';
import App from './App';
import { store, persistor } from './store/index';
import * as serviceWorker from './serviceWorker';

export const app = {
  cssRetries: 0,
  fetchRetries: 0,

  run() {
    this.render(App);
  },

  render(Component) {
    const root = document.getElementById('root');

    /* istanbul ignore next */
    if (root) {
      ReactDOM.render(
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Component />
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>,
        root,
      );
    }
  },
};
  
app.run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
