import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QrReader from 'react-qr-reader';
import styled from "styled-components";
import { Typography, Button, Icon } from '@material-ui/core';
import { Container } from "styled-minimal";
import history from '../../modules/history';
import { updateTimeOpenPage } from '../../actions/staff';
import { getCurrentWinner, setHandOutPrize } from '../../actions/prizes';
import CButton from "../../components/FormElements/CButton";
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import withStyles from '@material-ui/core/styles/withStyles';
import { ReactComponent as OKIcon } from '../../resources/images/ok.svg';
import { ReactComponent as CloseIcon } from '../../resources/images/close.svg';
import { ReactComponent as ScannerIcon } from '../../resources/images/scanner_zone_small.svg';
import styles from './styles';
import { SCANNER_ZONE } from '../../styles/constants';

const RESULT_TITLES = {
    SUCCESS: 'You can hand out the prize.',
    FAILURE: 'This guest is not a winner.'
}

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  padding: 15px;
  padding-bottom: 25px;
`;

class prizesCodeScanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            legacyMode: false,
            error: false,
            foundUserId: null,
            winner: null
        }
    }

    componentDidMount() {
        const {match, updateTimeOpenPage, getCurrentWinner} = this.props;

        updateTimeOpenPage();

        if (match.params.id) {
            getCurrentWinner(match.params.id)
        }
    }

    handleScan = (value) => {
        const {winner, setHandOutPrize} = this.props;

        if (!value && this.state.error){
            alert('QR-code was not scan correctly, please try again');
        }

        if (value && value.indexOf('X-USER-ID') !== -1 && value.indexOf('X-USER-CODE') !== -1) {

            const lines = value.split('\n'); 
            const userIdLine = lines.find(line => line.startsWith('X-USER-ID:')); 
            const userId = parseInt(userIdLine.split(':')[1]);
            console.log('Extracted X-USER-ID:', userId);
            
            if (winner.id === userId) {
                setHandOutPrize(winner.id);
            }
            this.setState({foundUserId: userId, winner: winner});
        }
    }

    handleError = (err) => {
        this.setState({legacyMode: true, error: true});
        this.openImageDialog();
    }

    openImageDialog = () => {
        this.refs.qrReader.openImageDialog();
    }

    renderResultScreen = () => {
        const {match, classes} = this.props;
        const {foundUserId, winner} = this.state;
        
        const winnerId = parseInt(match.params.id);
        const isWinner = winnerId === +foundUserId;

        if (!winner)
            return null;

        return (
                <HomeContainer>
                    <div className={classes.topBlock}/>
                    <div className={classes.centerBlock}>
                        <div className={classNames(classes.iconWrapper, isWinner && classes.succesColor)}>
                            {isWinner ? <OKIcon className={classNames(classes.icon)} /> : <CloseIcon className={classNames(classes.icon)} />}
                        </div>
                        <Typography component="h3" variant="h3" classes={{h3: classes.title}}>{RESULT_TITLES[(isWinner) ? 'SUCCESS' : 'FAILURE']}</Typography>   
                        {(isWinner) ? <Typography component="p" variant="body2" classes={{body2: classes.subTitle}}>{`${winner.firstname} ${winner.lastname}`}</Typography> : <div/>}
                    </div>
                    <div className={classes.bottomBlock}>
                        <Button 
                            className={classes.scanButton}
                            onClick={() => this.setState({foundUserId: null})}
                            >
                            <div className={classes.scanIconWrapper}>
                                <ScannerIcon className={classes.iconScanner}/>
                            </div>
                            <Typography component="span" variant="body2" classes={{body2: classes.scanButtonText}}>
                                Scan qr code
                            </Typography>
                        </Button>
                        <CButton
                            style={ isWidthDown('xs', this.props.width) ? {width: '100%'} : {width: '50%'}}
                            typeStyle={'transparent'}
                            onClick={() => history.goBack()}
                            fullWidth
                            >
                            Back to winners
                        </CButton>
                    </div>
                </HomeContainer>
                )
    }

    render() {
        const {classes} = this.props;
        const {legacyMode, error, foundUserId} = this.state;

        if (foundUserId) {
            return this.renderResultScreen()
        }

        return (
                <div className={classes.content}>
                    <QrReader
                        ref="qrReader"
                        delay={1000}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        className={classes.scannerContent}
                        showViewFinder={false}
                        legacyMode={legacyMode}
                        />
                
                    <div className={classes.textWrap}>
                        <Typography component="p" variant="body2" classes={{body2: classes.text}}>
                            Hover over the QR-code to scan
                        </Typography>
                        <img src={SCANNER_ZONE} alt={'Scaner zone'} className={classes.img}/>
                        {
                            (error) && <Button 
                                aria-label="Camera"
                                style={{textTransform: 'none', marginTop: 'none'}}
                                className={classes.link}
                                onClick={this.openImageDialog}
                                >
                                <Icon className={classes.iconLink}>
                                    camera_alt
                                </Icon>
                                <Typography component="p" variant="body2" classes={{body2: classes.textLink}}>
                                    Open camera
                                </Typography>
                            </Button>
                        }
                    </div>
                </div>
                )
    }
}

prizesCodeScanner.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
        winner: state.prizesReducer.current_winner || {},
});

const mapDispatchToProps = dispatch => bindActionCreators({
                updateTimeOpenPage,
                getCurrentWinner,
                setHandOutPrize
},dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(prizesCodeScanner)));