import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import { Container } from 'styled-minimal';
import withStyles from '@material-ui/core/styles/withStyles';
import CButton from '../../components/FormElements/CButton';
import styles from './styles';
import history from '../../modules/history';

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%
`;

class Success extends Component {

 return = () => {
    history.replace('/home');
  };

  render(){
    const { classes, found_user, pinCode } = this.props;

    return (
      <HomeContainer>
        <div className={classes.textBlock} >
          <Typography component="h1" variant="h1" classes={{h1: classes.h1}}>
            Success
          </Typography>
          <Typography component="p" variant="body2" classes={{body2: classes.text}}>
            Educational pack completed for {found_user.firstname}.
          </Typography>
          {pinCode && ( // Conditionally render the PIN code if it exists
            <Typography component="p" variant="body2" classes={{ body2: classes.text }}>
              Game PIN: {pinCode}
            </Typography>
          )}
        </div>
          <Grid container spacing={24} className={classes.button}>
            <Grid item xs={12} sm={6} >
              <CButton
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={this.return}
                fullWidth type='submit'
              >Return
              </CButton>
            </Grid>
          </Grid>
      </HomeContainer>
    )
  }
}

Success.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  found_user: state.securityReducer.found_user,
  pinCode: state.securityReducer.pinCode
});

const mapDispatchToProps = dispatch => bindActionCreators({},
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Success));
