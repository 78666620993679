import { 
  LARGE_TEXT_LG,
  LARGE_TEXT_MD,
  LARGE_TEXT_SM,
  MAIN_TEXT_LG,
  LINK_TEXT_LG,
  LINK_TEXT_SM,
  LINK_ACCENT_COLOR
} from '../../styles/constants';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit*6,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    width: '100%',
    alignItems: 'flex-start'
  },
  h1: {
    color: 'white',
    fontFamily: "Montserrat-Bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LARGE_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${LARGE_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LARGE_TEXT_LG}px`,
    },
    marginBottom: 10
  },
  text: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: `18px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `19px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
  },
  link: {
    color: 'white',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LINK_TEXT_SM}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LINK_TEXT_LG}px`,
    },
    '&:hover': {
      color: LINK_ACCENT_COLOR,
      textDecoration: 'none'
    },
  },
  wrapLink: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  xsHide: {
    [theme.breakpoints.down('xs')]: {
      display: `none`,
    },
  }
});

export default styles;