import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div/>
    )
  }
}


/* istanbul ignore next */
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Loading);