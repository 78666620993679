/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { request } from '../modules/client';
import { ActionTypes } from '../constants/index';
import history from '../modules/history';
import { getStaff } from './staff';

export function* getBars({ payload: { name }} ) {
  try {
    const response = yield call(
      request,
      `bars`,
      {
        method: 'POST',
        payload: { 
          name, 
         }
      }
    );
    yield put({
      type: ActionTypes.GET_BAR_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_BAR_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* eventsSignUp(event_id) {
  try {
    const response = yield call(
      request,
      `events/signUp`,
      {
        method: 'POST',
        payload: { event_id }
      }
    );
    if (response.status === 200) {
      yield put({
        type: ActionTypes.EVENTS_SIGNUP_SUCCESS,
        payload: response,
      });
      history.replace('/success');
    } else {
      yield put({
        type: ActionTypes.SET_ERROR_MESSAGE,
        payload: {message: response.statusMeans},
      });
    }
  } catch (err) {
    console.log(err)
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EVENTS_SIGNUP_FAILURE,
      payload: err,
    });
  }
}

export function* updateNewUser({ payload: {phone, send_sms_notifications, email, send_email_notifications, firstname, lastname, birthdate, age_verified, bar_id, event_id, opt_in_trade_insights_survey}} ) {
  try {
    const response = yield call(
      request,
      `staff/updateUser`,
      {
        method: 'POST',
        payload: { 
          phone, 
          send_sms_notifications, 
          email, 
          send_email_notifications, 
          firstname, 
          lastname, 
          birthdate,
          age_verified,
          bar_id,
          event_id,
          opt_in_trade_insights_survey
        }
      }
    );

    if (response) {
      yield put({
        type: ActionTypes.UPDATE_USER_SUCCESS,
        payload: {phone, send_sms_notifications, email, send_email_notifications, firstname, lastname, birthdate, age_verified},
      });
    }
    if (response.status === 200) {
      yield put({
        type: ActionTypes.EVENTS_SIGNUP_SUCCESS,
        payload: response,
      });
      yield getStaff({payload: false});
      history.replace('/success');
    } else {
      yield put({
        type: ActionTypes.SET_ERROR_MESSAGE,
        payload: {message: response.statusMeans},
      });
    }
    
    
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_USER_FAILURE,
      payload: err,
    });
  }
}

export function* authNewUser({ payload: {phone, phone_checkword, event_id}} ) {
  try {
    const response = yield call(
      request,
      `staff/authUser`,
      {
        method: 'POST',
        payload: { 
          phone, 
          phone_checkword,
          event_id
        }
      }
    );
    yield put({
      type: ActionTypes.AUTH_USER_SUCCESS,
      payload: response,
    });
    history.push('/register');
    if (response.bar) {
      yield getBars(response.bar)
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.AUTH_USER_FAILURE,
      payload: err,
    });
  }
}


/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_BAR_LIST, getBars),
    takeLatest(ActionTypes.AUTH_USER, authNewUser),
    takeLatest(ActionTypes.UPDATE_USER, updateNewUser)
  ]);
}
