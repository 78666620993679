/** COLORS */

export const MAIN_ACCENT_COLOR = '#00AA60';
export const MAIN_ACCENT_DARK_COLOR = '#14925B';
export const LINK_ACCENT_COLOR = '#059A5B';
export const MAIN_COLOR = '#232B37';
export const MAIN_BACKGROUND_COLOR = '#17202C';
export const GRAY_COLOR = '#8189A5';
export const ACCENT_GRAY_COLOR = '#C8CDDD';
export const ERROR_RED = '#F13E3E';

/** SIZES */
export const MAIN_TEXT_LG = '20';
export const MAIN_TEXT_MD = '16';
export const MAIN_TEXT_SM = '13';

export const NORMAL_TEXT_X_LG = '22';
export const NORMAL_TEXT_X_MD = '18';
export const NORMAL_TEXT_X_SM = '15';

export const LARGE_TEXT_LG = '50';
export const LARGE_TEXT_MD = '45';
export const LARGE_TEXT_SM = '40';

export const LINK_TEXT_LG = '16';
export const LINK_TEXT_SM = '15';

export const LABEL_TEXT_LG = '16';
export const lABEL_TEXT_MD = '16';
export const LABEL_TEXT_SM = '16';

/** IMAGES */
export const SITE_LOGO = require('../resources/images/logos/logo.png');
export const BACKGROUND_IMAGE_1 = 'resources/images/background_1.png';
export const BACKGROUND_IMAGE_2 = 'resources/images/background_2.png';
export const SCANE_IMAGE = require('../resources/images/scane_image.png');
export const BUTTON_CIRCLE = require('../resources/images/ellipce.png');
export const USER_NOT_FOUND_IMAGE = require('../resources/images/user_not_found.png');
export const SCANNER_ZONE = require('../resources/images/scanner_zone.png');
export const DEMO_IMAGE_1 = require('../resources/images/demo_image_1.png');