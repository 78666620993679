// @flow
/**
 * Client
 * @module Client
 */
import axios from 'axios';
import Cookies from 'js-cookie';
import api_config from '../config';
import history from '../modules/history';

/**
 * Axios data
 *
 * @param {string} url
 * @param {Object} options
 * @param {string} [options.method] - Request method ( GET, POST, PUT, ... ).
 * @param {string} [options.payload] - Request body.
 * @param {Object} [options.headers]
 *
 * @returns {Promise}
 */
export function request(url, options = {}) {
  const config = {
    method: 'GET',
    ...options,
  };
  const errors = [];

  if (!url) {
    errors.push('url');
  }

  if (!config.payload && (config.method !== 'GET' && config.method !== 'DELETE')) {
    errors.push('payload');
  }

  if (errors.length) {
    throw new Error(`Error! You must pass \`${errors.join('`, `')}\``);
  }

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  };

  const params = {
    headers,
    method: config.method,
    withCredentials: true,
  };

  if (params.method !== 'GET') {
    let formData = new FormData();
    for (let item in config.payload) {
      if (config.payload[item] !== undefined && config.payload[item] !== null) {
        formData.append(item, config.payload[item]);
      }
    }

    formData.append('__qf_csrf', (Cookies.get('csrf'))? Cookies.get('csrf'): '890925944');
    params.data = formData;
  }

  return axios({
    url: /^(https?:\/\/)/.test(url) ? url : `${api_config.apiUrl}${url}`,
    ...params
  })
  .then(async response => {
    if (response.data.status >= 400) throw response.data;
    return response.data;
  })
  .catch(async err => {
    if (err.status === 403) history.replace('/login');
    throw err;
  })
}