import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { STATUS, ActionTypes } from '../constants/index';

export const userState = {
  bars: [],
  user: {},
  user_bar: null,
  status: STATUS.IDLE,
  alreadyRegistered: false,
  event_id: null,
  chosenEventObj: {},
  surveyUrl: null,
};

export default {
  userReducer: handleActions(
    {
      [ActionTypes.GET_BAR_LIST_SUCCESS]: (state, { payload: { bars }}) => {
        let formatedArray = [];
        bars.forEach((item) => {
          formatedArray.push({
            ...item,
            label: item.name,
          })
        })
        return (
          immutable(state, {
            bars: { $set: formatedArray },
            status: { $set: STATUS.READY }
          })
        )
      },
      [ActionTypes.GET_BAR_LIST_FAILURE]: state => 
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),
      [ActionTypes.SET_BAR_CHOOSEN]: (state, { payload: { bar }}) =>
        immutable(state, {
          user_bar: { 
            $set: bar
          },
        }),
      
        [ActionTypes.AUTH_USER]: (state, { payload: { event_id, chosenEventObj }}) =>
        immutable(state, {
          event_id: { $set: event_id },
          chosenEventObj: { $set: chosenEventObj}
        }),

        [ActionTypes.AUTH_USER_SUCCESS]: (state, { payload: { user, bar }}) =>
        immutable(state, {
          user: { $set: user },
          user_bar: { $set: (bar) ? bar : null },
          status: { $set: STATUS.READY },
        }),
      [ActionTypes.AUTH_USER_FAILURE]: (state) =>
        immutable(state, {
          status: { $set: STATUS.ERROR },
        }),
      [ActionTypes.UPDATE_USER_SUCCESS]: (state, { payload: { phone, send_sms_notifications, email, send_email_notifications, firstname, lastname, birthdate, age_verified }}) =>
      immutable(state, {
        user: { 
          $set: {
            ...state.user,
            phone,
            send_sms_notifications, 
            email, 
            send_email_notifications, 
            firstname, 
            lastname,
            age_verified
          }
        },
        status: { $set: STATUS.READY },
      }),
      [ActionTypes.EVENTS_SIGNUP_SUCCESS]: (state, { payload: {surveyUrl} }) => 
        immutable(state, {
          surveyUrl: { $set: surveyUrl },
        }),
      [ActionTypes.EVENTS_SIGNUP_FAILURE]: (state) => 
        immutable(state, {
          surveyUrl: { $set: null },
        }),
      [ActionTypes.CLEARE_USER]: (state) => 
        immutable(state, {
          user: { $set: {} },
          event_id: { $set: null },
        }),
    },
    userState,
  ),
};