import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Typography } from '@material-ui/core';
import { toggleErrorPopup } from '../../actions/error';
import styles from './styles';

class ErrorDrawer extends React.Component {
  state = {
    show: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showPopup !== this.props.showPopup && this.props.showPopup) {
      setTimeout(()=> {
        this.props.toggleErrorPopup(false)
      }, 3000)
    }
  }


  render() {
    const { classes, showPopup, errorMessage, toggleErrorPopup } = this.props;
    
    return (
      <Drawer anchor="top" open={showPopup} onClose={() => toggleErrorPopup(false)} className={classes.background} classes={{ paperAnchorTop: classes.errorBlock, modal: classes.background}}>
        <Typography component="h4" variant="h4" classes={{h4: classes.errorText}}>
          {errorMessage}
        </Typography>
      </Drawer>
    );
  }
}

ErrorDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  showPopup: state.errorReducer.showPopup,
  errorMessage: state.errorReducer.errorMessage,
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    toggleErrorPopup
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ErrorDrawer));