import { 
    LARGE_TEXT_LG,
    LARGE_TEXT_MD,
    LARGE_TEXT_SM,
  } from '../../styles/constants';
  
  const styles = theme => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit*6,
    },
    textBlock: {
      width: '100%',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        marginTop: 30
      },
    },
    h1: {
      color: 'white',
      fontFamily: "Montserrat-Bold",
      
      [theme.breakpoints.down('sm')]: {
        fontSize: `${LARGE_TEXT_SM}px`,
        width: '50%'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `${LARGE_TEXT_MD}px`,
        width: '40%'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `${LARGE_TEXT_LG}px`,
        width: '40%'
      },
      marginBottom: 10
    },

  });
  
  export default styles;