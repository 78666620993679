import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Container } from "styled-minimal";
import { Typography, Grid } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { authUser } from "../../actions/user";
import {
	updateTimeOpenPage,
	getEvents,
	setGlobalChosenEvent,
} from "../../actions/staff";
import CButton from "../../components/FormElements/CButton";
import RegisteryCounter from "../../components/RegisteryCounter";
import CInput from "../../components/FormElements/CInput";
import CSelect from "../../components/FormElements/CSelect";
import styles from "./styles";

const HomeContainer = styled(Container)`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1170px;
	margin: 0 auto;
	padding: 0 15px;
`;

class PhoneAuth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			phone: null,
			chosenEvent: null,
			confirmPhone: null,
			isValidPhone: null,
			isValidConfirmPhone: null,
			eventsWithAdditions: [],
			defaultEvent: { name: "Internal Database", id: 0 },
		};
	}

	componentDidMount() {
		const { events } = this.props;
		const {
			updateTimeOpenPage,
			getEvents,
			event,
			setGlobalChosenEvent,
		} = this.props;
		updateTimeOpenPage();
		getEvents();

		if (event) {
			this.setState({
				defaultEvent: event,
				chosenEvent: event.id,
				eventsWithAdditions: [{ name: "Internal Database", id: 0 }, ...events],
			});
			setGlobalChosenEvent(event.id);
		}
	}

	componentDidUpdate() {
		const { chosenEvent } = this.state;
		const { setGlobalChosenEvent } = this.props;
		if (chosenEvent === 0) {
			setGlobalChosenEvent(chosenEvent);
		}

		// let eventsWithAdditions = events ? [{ name: "MIE Database", id: 0 }, ...events]: [{ name: "MIE Database", id: 0 }];
		// let defaultEvent =  eventsWithAdditions.find(
		// 	(elem) => elem.id === 0
		// );
		// if (staff && staff.event_id) {
		// 	const eventById = events.find((elem) => elem.id === staff.event_id);
		// 	if (eventById) {
		// 		defaultEvent = eventById;
		// 	}
		// }
		// console.log(eventsWithAdditions, 'def');

		// eventsWithAdditions = [...eventsWithAdditions.filter(
		// 	(elem) => elem.id !== defaultEvent.id
		// )];

		// console.log(eventsWithAdditions, 'def2');
	}

	handleChange = (event, isConfirm) => {
		const { phone, confirmPhone } = this.state;

		this.setState({
			phone: isConfirm ? phone : event.target.value,
			confirmPhone: isConfirm ? event.target.value : confirmPhone,
		});
	};

	handleSubmit = () => {
		const { phone, confirmPhone = "", chosenEvent } = this.state;
		const { authUser, events, event_id } = this.props;

		if (this.validateForm(phone, confirmPhone) && confirmPhone) {
			let formatedConfirmPhone = confirmPhone
				.replace("+", "")
				.split(" ")
				.join("");
			const chosenEventObj = events.find(
				(elem) => +elem.id === +this.state.chosenEvent
			);

			authUser(formatedConfirmPhone, "", chosenEvent, chosenEventObj);
		}
	};

	validatePhone = (value) => {
		let re = /^\+?([0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})?[-. ]([0-9]{4})$/;
		return re.test(value) && value.length === 15;
	};

	validateForm = (phone, confirmPhone) => {
		let result = false;

		if (phone !== null) {
			this.setState({
				isValidPhone: !(!phone || !this.validatePhone(phone)),
			});
			result = !(!phone || !this.validatePhone(phone));
		}

		if (confirmPhone !== null) {
			this.setState({
				isValidConfirmPhone: !(
					!confirmPhone ||
					!this.validatePhone(confirmPhone) ||
					this.state.phone !== confirmPhone
				),
			});
			result = !(
				!confirmPhone ||
				!this.validatePhone(confirmPhone) ||
				this.state.phone !== confirmPhone
			);
		}

		return result;
	};

	render() {
		const { classes, staff, events } = this.props;
		const {
			phone,
			confirmPhone,
			isValidPhone,
			isValidConfirmPhone,
			chosenEvent,
			defaultEvent,
			eventsWithAdditions,
		} = this.state;

		// let eventsWithAdditions = events ? [{ name: "MIE Database", id: 0 }, ...events]: [{ name: "MIE Database", id: 0 }];
		// let defaultEvent =  eventsWithAdditions.find(
		// 	(elem) => elem.id === 0
		// );
		// if (staff && staff.event_id) {
		// 	const eventById = events.find((elem) => elem.id === staff.event_id);
		// 	if (eventById) {
		// 		defaultEvent = eventById;
		// 	}
		// }

		// eventsWithAdditions = [...eventsWithAdditions.filter(
		// 	(elem) => elem.id !== defaultEvent.id
		// )];


		return (
			<HomeContainer>
				{staff.role === "street_team" && !!chosenEvent && (
					<RegisteryCounter />
				)}
				<div style={{ height: 80 }} />
				<div className={classes.textBlock}>
					<Typography
						component="h1"
						variant="h1"
						classes={{ h1: classes.h1 }}
					>
						Enter user’s {"\n"}phone number
					</Typography>
				</div>
				<form
					className={classes.form}
					noValidate
					onSubmit={this.handleSubmit}
				>
					<Grid
						container
						spacing={24}
						justify="center"
						alignItems="center"
					>
						<Grid item xs={12} sm={12}>
							<CSelect
								fullWidth
								autoFocus
								value={chosenEvent}
								onChange={(event) => {
									if (+event.target.value === 0) {
										this.setState({ chosenEvent: null });
										this.props.setGlobalChosenEvent(null);
										return;
									}
									this.setState({
										chosenEvent: event.target.value
									});
									this.props.setGlobalChosenEvent(
										+event.target.value
									);
								}}
								name={"event"}
								defaultValue={defaultEvent}
								placeholder={"Event name"}
								label={"Choose Event"}
								options={eventsWithAdditions}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						spacing={24}
						justify="center"
						alignItems="center"
					>
						<Grid item xs={12} sm={6}>
							<CInput
								required
								fullWidth
								name="phone"
								type={"tel"}
								autoFocus
								value={phone}
								onChange={(event) =>
									this.handleChange(event, false)
								}
								isValid={
									isValidPhone &&
									isValidConfirmPhone !== false
								}
								placeholder={"+_ (__)___-__-__"}
								label={"Phone number"}
								validate={(event) => {
									const { relatedTarget } = event;
									if (
										relatedTarget &&
										"button" ===
											relatedTarget.getAttribute("type")
									) {
										this.handleSubmit();
									}
									this.validateForm(
										event.target.value,
										confirmPhone
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CInput
								required
								fullWidth
								name="phone"
								type={"tel"}
								value={confirmPhone}
								onChange={(event) =>
									this.handleChange(event, true)
								}
								onCut={(e) => {
									e.preventDefault();
								}}
								isValid={isValidConfirmPhone}
								placeholder={"+_ (__)___-__-__"}
								label={"Repeat phone number"}
								error_text={
									confirmPhone && phone
										? "You entered different numbers"
										: ""
								}
								validate={(event) => {
									const { relatedTarget } = event;
									if (
										relatedTarget &&
										"button" ===
											relatedTarget.getAttribute("type")
									) {
										this.handleSubmit();
									}
									this.validateForm(
										phone,
										event.target.value
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CButton
								style={{ marginTop: 20 }}
								onClick={this.handleSubmit}
								disabled={
									(isValidPhone && isValidConfirmPhone) ===
									false
								}
								className={classes.submit}
								fullWidth
							>
								Next
							</CButton>
						</Grid>
						<Grid item xs={12} sm={6} />
					</Grid>
				</form>
			</HomeContainer>
		);
	}
}

PhoneAuth.propTypes = {
	classes: PropTypes.object.isRequired,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
	staff: state.staffReducer.staff,
	events: state.staffReducer.events,
	event: state.staffReducer.event,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			authUser,
			updateTimeOpenPage,
			getEvents,
			setGlobalChosenEvent,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(PhoneAuth));
