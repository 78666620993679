import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import QRCode from 'qrcode.react';
import classNames from 'classnames'
import styled from 'styled-components';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Typography, Grid, IconButton, Icon, Modal, Button } from '@material-ui/core';
import { Container } from 'styled-minimal';
import withStyles from '@material-ui/core/styles/withStyles';
import { getEventUser, checkUserToEvent, checkUserToEventToggle, updateTimeOpenPage, cleareEventUser, cleareAllFoundUsers, setFoundUser } from '../../actions/staff';
import AutoComplete from '../../components/FormElements/AutoComplete';
import CheckInCounter from '../../components/CheckInCounter';
import CButton from '../../components/FormElements/CButton';
import styles from './styles';
import history from '../../modules/history';
import { BUTTON_CIRCLE } from '../../styles/constants';


const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0;
`;


class SearchPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      isValidInput: null,
      isPhoneField: (props.location.state && props.location.state.phoneField) ? props.location.state.phoneField : false,
    }
  }

  componentDidMount() {
    this.props.updateTimeOpenPage();
  }

  validatePhone = (value) => {
    let re = /^\+?([0-9]{1})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})?[-. ]([0-9]{4})$/
    return (re.test(value) && value.length === 15);
  }

  validateForm = (value, notRequest) => {
    const { getEventUser, cleareAllFoundUsers } = this.props;
    const phoneRegex = /^\d+$/;
    const nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    
    if (value.length <= 2) return cleareAllFoundUsers();

    if (!phoneRegex.test(value) && !nameRegex.test(value)) {
      this.setState({ isValidInput: false })
    } else {
      this.setState({ isValidInput: true })
      if (notRequest) return;
      if (phoneRegex.test(value)) {
        getEventUser(
          value.replace('+', '').split(' ').join(''), 
          null, 
          null, 
          null,
          null, 
          true, 
          false
        );
      }
      if (nameRegex.test(value)) {
        getEventUser(
          null, 
          value, 
          null, 
          null, 
          null, 
          true, 
          false
        );
      }
    }
  }

  handleValue = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validateForm(this.state.input);
    });
  }
  
  handleSelect = (event) => {
    const { staff } = this.props;

    this.setState({input: event.label});
    this.props.getEventUser(
      null, 
      null, 
      event.data.id, 
      event.data.code, 
      staff.role, 
      false,
      true
    )
  }

  cleaneField = () => {
    const { updateTimeOpenPage, cleareEventUser, cleareAllFoundUsers } = this.props;
    
    this.setState({input: '', isValidInput: null});
    updateTimeOpenPage();
    cleareEventUser();
    cleareAllFoundUsers();
  }

  clickButton = () => {
    const { user, checkUserToEvent, isRegisteredToEvent, userAlreadyChecked, staff } = this.props;

    if (Object.entries(user).length === 0 || !isRegisteredToEvent || userAlreadyChecked) {
      history.replace('/home');
    } else {
      (staff.role === 'security') ? checkUserToEvent(user.id, user.code) : history.push('/educational_packs');
    }
  }

  sortAllUsers = (arr) => {
    let array = [];

    arr.forEach((item) => {
      array.push({
        data: item,
        value: item.firstname + item.lastname + item.phone,
        label: item.firstname + ' ' + item.lastname,
        subLabel: item.phone
      })
    })

    return array
  }

  renderModal() {
    const { classes, userChecked, checkUserToEventToggle, modalComment } = this.props;

    return (
      <Modal 
        open={userChecked}
        className={classes.paper}
        disableAutoFocus
        >
        <div className={classes.modalContent}>
          <div className={classes.imageWrap}>
            <img src={BUTTON_CIRCLE} alt="logo" />
          </div>
          <Typography component="h3" variant="h3" classes={{h3: classes.titleModal}}>
            {modalComment}
          </Typography>
          <div >
            <CButton
              fullWidth
              onClick={()=>{
                checkUserToEventToggle(false);
                history.replace('/home')
              }}
            >
              Home
            </CButton>  
          </div>
        </div>
      </Modal>
    )
  }

  render(){
    const { classes, user, isRegisteredToEvent, userAlreadyChecked, staff, allUsers, userError } = this.props;
    const { input, isValidInput, isPhoneField } = this.state;

    return (
      <HomeContainer>
        {this.renderModal()}    
        {
          (isPhoneField) ? (
            <div className={classes.wrapInput}>
              <AutoComplete
                required
                value={input} 
                name="input" 
                options={this.sortAllUsers(allUsers)}
                isValid={isValidInput}
                onChange={this.handleValue}
                onSelect={this.handleSelect}
                menuIsOpen={((this.sortAllUsers(allUsers).length || input.length > 5) && !user.id)}
                onValidate={(e) => this.validateForm(e, true)}
              />
              {
                (input.length > 0) && (
                  <IconButton aria-label="Close" className={classes.closeIcon} onClick={this.cleaneField}>
                    <Icon fontSize="large" style={{color: '#232B37', fontSize: 35}}>cleare</Icon>
                  </IconButton>
                )
              }
            </div>
          ) : (
            <div className={classes.wrapLink}>
              <Button 
                aria-label="Search"
                style={{ textTransform: 'none', marginTop: 'none' }}
                className={classNames(classes.link, classes.hideXS)}
                onClick={() => this.setState({isPhoneField: true})}
                >
                <Icon className={classes.iconLink}>
                  search
                </Icon>
                <Typography component="p" variant="body2" classes={{body2: classes.textLink}}>
                  Search
                </Typography>
              </Button>
            </div>
          )
        }
        {
          (!isPhoneField && (staff.role === 'security')) && <CheckInCounter accentStyle={Object.entries(user).length !== 0 && isRegisteredToEvent}/>
        }
        {
          (Object.entries(user).length !== 0 || userError) && (
            <div className={classes.bottomContainer}>
              <Grid container spacing={24} alignItems="center" justify="center" className={classes.bottomContent}>
                <Grid item xs={12} sm={6} className={classes.userInfoWrap}>
                  <div className={classes.codeWrap}>
                    <QRCode 
                      value={JSON.stringify(`X-USER-ID ${user.id}X-USER-CODE ${user.code}END`)} 
                      renderAs='svg'
                      size={140}
                    />
                    {
                      (!isPhoneField) &&<div className={classes.wrapBottomLink}>
                        <Button 
                          aria-label="Search"
                          style={{ textTransform: 'none', marginTop: 'none' }}
                          className={classes.link}
                          onClick={() => this.setState({isPhoneField: true})}
                          >
                          <Icon className={classes.iconLink}>
                            search
                          </Icon>
                          <Typography component="p" variant="body2" classes={{body2: classes.textLink}}>
                            Search
                          </Typography>
                        </Button>
                      </div>
                    }
                  </div>
                  <div className={classes.codeComments}>
                    {
                      (user.vip === 1 && !isWidthDown('xs', this.props.width)) ? (
                        <div className={classes.vipWrapper}>
                          <Typography component="p" variant="body2" classes={{body2: classes.vipText}}>
                            VIP
                          </Typography>
                        </div>
                      ) : null
                    }
                    <Typography component="h4" variant="h4" classes={{h4: classes.name}}>
                      {(Object.entries(user).length === 0) ? 'User not found': `${user.firstname} ${user.lastname}`}
                    </Typography>
                    <Typography component="p" variant="body2" classes={{body2: classes.text}}>
                      {
                        (Object.entries(user).length === 0) ? 'Try again': 
                          (!isRegisteredToEvent) ? 'Not registered for this event' : 
                          (userAlreadyChecked) ? 'Already checked in' : moment(user.birthdate).format('YYYY.MM.DD')
                        }
                    </Typography>
                    {
                      (user.vip === 1 && isWidthDown('xs', this.props.width)) ? (
                        <div className={classes.vipWrapper}>
                          <Typography component="p" variant="body2" classes={{body2: classes.vipText}}>
                            VIP
                          </Typography>
                        </div>
                      ) : null
                    }
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CButton
                    onClick={this.clickButton}
                    fullWidth
                    typeStyle={(Object.entries(user).length === 0 || !isRegisteredToEvent || userAlreadyChecked) ? 'white' : null}
                  >
                    {(Object.entries(user).length === 0 || !isRegisteredToEvent || userAlreadyChecked) ? 'back' : (staff.role === 'security') ? 'check in' : 'next'}
                  </CButton>
                </Grid>
              </Grid> 
            </div>
          )
        }
      </HomeContainer>
    )
  }
}

SearchPhone.propTypes = {
  classes: PropTypes.object.isRequired,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  staff: state.staffReducer.staff,
  allUsers: state.securityReducer.all_found_users || [],
  user: state.securityReducer.found_user,
  status: state.securityReducer.status,
  userChecked: state.securityReducer.userChecked,
  userAlreadyChecked: state.securityReducer.userAlreadyChecked,
  modalComment: state.securityReducer.modalComment,
  isRegisteredToEvent: state.securityReducer.isRegisteredToEvent,
  userError: state.securityReducer.userError,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getEventUser,
    checkUserToEvent,
    checkUserToEventToggle,
    updateTimeOpenPage,
    cleareEventUser,
    cleareAllFoundUsers,
    setFoundUser
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(SearchPhone)));
