import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Typography, Grid, Link } from "@material-ui/core";
import { Container } from "styled-minimal";
import {
  authStaff,
  resetPassStep,
  logOutStaff,
  updateTimeOpenPage
} from "../../actions/staff";
import withStyles from "@material-ui/core/styles/withStyles";
import CButton from "../../components/FormElements/CButton";
import CInput from "../../components/FormElements/CInput";
import styles from "./styles";
import history from "../../modules/history";

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 15px;
  padding-bottom: 30px;
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isValidEmail: null,
      isValidPassword: null,
      errorMessage: null
    };
  }

  componentDidMount() {
    this.props.logOutStaff();
    this.props.updateTimeOpenPage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorMessage !== this.props.errorMessage) {
      this.setState({
        errorMessage: this.props.errorMessage,
        isValidEmail: this.props.errorMessage ? false : true,
        isValidPassword: this.props.errorMessage ? false : true
      });
    }
  }

  handleSubmit = () => {
    const { authStaff } = this.props;
    const { email, password } = this.state;

    if (this.validateForm(email, password)) {
      authStaff(email, password);
    }
  };

  validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validatePassword(pass) {
    return pass.indexOf(" ") === -1 && pass.length >= 3;
  }

  validateForm = (email, password) => {
    let result = true;

    if (email !== null) {
      this.setState({ isValidEmail: !(!email || !this.validateEmail(email)) });
      result = !(!email || !this.validateEmail(email));
    }

    if (password !== null) {
      this.setState({
        isValidPassword: !(!password || !this.validatePassword(password))
      });
      result = !(!password || !this.validatePassword(password));
    }

    return result;
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetPassword = () => {
    const { resetPassStep } = this.props;

    resetPassStep();
    history.push("/send_email");
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      isValidPassword,
      isValidEmail,
      errorMessage
    } = this.state;

    return (
      <HomeContainer>
        <div className={classes.textBlock}>
          <Typography component="h1" variant="h1" classes={{ h1: classes.h1 }}>
            Hi, there
          </Typography>
          <Typography
            component="p"
            variant="body2"
            classes={{ body2: classes.text }}
          >
            Log in to access staff functionality.
          </Typography>
        </div>
        <form noValidate className={classes.form} onSubmit={this.handleSubmit}>
          <Grid container spacing={24} justify="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <CInput
                required
                fullWidth
                name="email"
                type="email"
                label="Email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={this.handleChange}
                placeholder={"Email"}
                isValid={isValidEmail}
                validate={event => {
                  const { relatedTarget } = event;
                  if (
                    relatedTarget &&
                    "button" === relatedTarget.getAttribute("type")
                  ) {
                    this.handleSubmit();
                  }
                  this.validateForm(event.target.value, null);
                }}
                error_text={errorMessage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CInput
                required
                fullWidth
                name="password"
                type="password"
                label="Password"
                autoComplete="password"
                value={password}
                onChange={this.handleChange}
                placeholder={"Password"}
                isValid={isValidPassword}
                validate={event => {
                  const { relatedTarget } = event;
                  if (
                    relatedTarget &&
                    "button" === relatedTarget.getAttribute("type")
                  ) {
                    this.handleSubmit();
                  }
                  this.validateForm(null, event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CButton
                style={{ marginTop: 20 }}
                onClick={this.handleSubmit}
                className={classes.submit}
                fullWidth
              >
                Login
              </CButton>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.xsHide} />
          </Grid>
        </form>
        <div className={classes.wrapLink}>
          <Link
            component="button"
            variant="body2"
            classes={{ button: classes.link }}
            onClick={this.resetPassword}
          >
            Reset password
          </Link>
        </div>
      </HomeContainer>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

/* istanbul ignore next */
const mapStateToProps = state => ({
  staff: state.staffReducer.staff,
  errorMessage: state.staffReducer.errorMessage
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      authStaff,
      resetPassStep,
      logOutStaff,
      updateTimeOpenPage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Login));
