import React from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography, Button, Modal, SvgIcon } from "@material-ui/core";
import { Container } from "styled-minimal";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import styles from "./styles";
import history from "../../modules/history";
import config from "../../config";
import { logOutStaff } from "../../actions/staff";
import { ReactComponent as LogoExportA } from "../../resources/images/logos/logo_export_a.svg";
import { ReactComponent as AmsLogo } from "../../resources/images/logos/ams_logo.svg";
import { ReactComponent as LogoIdBlack } from "../../resources/images/logos/logo_ld_black.svg";
import { ReactComponent as LogoJTI } from "../../resources/images/logos/logo_jti.svg";
import CButton from "../../components/FormElements/CButton";
        const HeaderWrapper = styled(Container)`
	max-width: 1170px;
	padding: 0px 15px 0px 15px;
`;
        class Header extends React.PureComponent {
        constructor(props) {
        super(props);
                this.state = {
                show_modal: false,
                        timeOpenPage: props.timeOpenPage,
                };
        }

        static propTypes = {
        dispatch: PropTypes.func.isRequired,
        };
                componentDidUpdate(prevProps) {
        if (prevProps.timeOpenPage !== this.props.timeOpenPage)
                this.setState({ timeOpenPage: this.props.timeOpenPage });
                const { events, event_id } = this.props;
                if (event_id) {
        this.setState({
        chosenEvent: events.find((elem) => + elem.id === + event_id),
        });
        }
        }

        goHome = () => {
        const { staff } = this.props;
                if (Object.entries(staff).length === 0) return;
                if (
                        staff.role === "street_team" &&
                        history.location.pathname !== "/phone_auth"
                        )
                history.push("/phone_auth");
                if (staff.role === "prizes" && history.location.pathname !== "/winners")
                history.push("/winners");
                if (
                        (staff.role === "security" || staff.role === "edu_team") &&
                        history.location.pathname !== "/home"
                        )
                history.push("/home");
        };
                logOut = () => {
        const { logOutStaff } = this.props;
                logOutStaff();
                history.replace("/login");
        };
                renderLogo = (event) => {
        let uri = <LogoJTI style={this.logoStyles(event)} />;
                if (
                        event &&
                        event.brand_id &&
                        config.themes[event.brand_id] &&
                        history.location.pathname === "/register"
                        ) {
        switch (config.themes[event.brand_id].logo.name) {
        case "logo_export_a":
                uri = <LogoExportA style={this.logoStyles(event)} />;
                break;
                case "spirit":
                uri = <AmsLogo style={this.logoStyles(event)} />;
                break;
                case "LD":
                uri = <LogoIdBlack style={this.logoStyles(event)} />;
                break;
                default:
                uri = <LogoJTI style={this.logoStyles(event)} />;
                break;
        }
        }
        return uri;
        };
                logoStyles = (event) => {
        let styles = {};
                if (
                        event &&
                        event.brand_id &&
                        config.themes[event.brand_id] &&
                        history.location.pathname === "/register"
                        ) {
        styles.width = isWidthDown("sm", this.props.width)
                ? config.themes[event.brand_id].logo.width * 0.7
                : config.themes[event.brand_id].logo.width;
                styles.height = isWidthDown("sm", this.props.width)
                ? config.themes[event.brand_id].logo.height * 0.7
                : config.themes[event.brand_id].logo.height;
        }

        return styles;
        };
                themes = (event) => {
        let styles = {
        hoverColor: "#059A5B",
                color: null,
                textColor: null,
        };
                if (
                        event &&
                        event.brand_id &&
                        config.themes[event.brand_id] &&
                        history.location.pathname === "/register"
                        ) {
        styles = {
        color: config.themes[event.brand_id].header.iconColor,
                hoverColor:
                config.themes[event.brand_id].header.iconActiveColor,
                textColor: config.themes[event.brand_id].text.color,
                borderColor: config.themes[event.brand_id].header.lineColor,
        };
        }
        return styles;
        };
                renderModal() {
        const { show_modal } = this.state;
                const { classes } = this.props;
                return (
                        <Modal open={show_modal} className={classes.paper} disableAutoFocus>
    <div className={classes.modalContent}>
        <div>
            <Typography
                component="h3"
                variant="h3"
                classes={{ h3: classes.titleModal }}
                >
                Log out
            </Typography>
            <Typography
                component="p"
                variant="body2"
                classes={{ body2: classes.textModal }}
                >
                Are you sure you want to Log out?
            </Typography>
        </div>
        <div>
            <CButton
                fullWidth
                onClick={() => {
                this.setState({ show_modal: false }, () => {
                this.logOut();
                });
                }}
                >
                Log out
            </CButton>
            <CButton
                style={{ marginTop: 20 }}
                onClick={() => this.setState({ show_modal: false })}
                fullWidth
                typeStyle={"white"}
                >
                Cancel
            </CButton>
        </div>
    </div>
</Modal>
                        );
        }

        render() {
        const {
        event,
                classes,
                isAuthenticated,
                event_id,
                events,
                globalChosenEvent,
                staff,
        } = this.props;
                const CustomIcon = withStyles((theme) => ({
                icon: {
                color: this.themes(event).color
                        ? this.themes(event).color
                        : theme.palette.common.white,
                        fontSize: 20,
                        overflow: "unset",
                        "&:hover": {
                        //opacity: 0.6,
                        color: this.themes(event).hoverColor,
                                textDecoration: "none",
                        },
                },
                }))(({ classes, ...props }) => {
        return (
                <SvgIcon
    classes={{
                root: classes.icon,
                }}
    {...props}
    />
                );
        });
                return (
                        <HeaderWrapper>
    {this.renderModal()}
    <div className={classes.wrapper}>
        <div
            className={classes.headerContent}
            style={
                        this.themes(event).borderColor && {
                borderBottomColor: this.themes(event)
                        .borderColor,
                }
                                                }
            >
            <div className={classes.headerData}>
                <div
                    className={classes.logo}
                    style={this.logoStyles(this.state.chosenEvent)}
                    onClick={() => this.goHome()}
                    >
                    {this.renderLogo(this.state.chosenEvent)}
                </div>
                {event &&
                        Object.keys(event).length &&
                        event.name &&
                        history.location.pathname !== "/register" &&
                        !this.state.chosenEvent &&
                        globalChosenEvent ? (
                                <>
                <div>
                    <Typography
                        component="p"
                        variant="body2"
                        classes={{ body2: classes.text }}
                        style={
                                this.themes(event)
                                .textColor && {
                                color: this.themes(event)
                                        .textColor,
                                }
                                                                                        }
                        >
                        {ReactHtmlParser(event.name)}
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        classes={{ body2: classes.text }}
                        style={
                                this.themes(event)
                                .textColor && {
                                color: this.themes(event)
                                        .textColor,
                                }
                                                                                        }
                        >
                        {event ? event["^venue"].name : ''},{" "}
                        {event ? event.date_start_formatted : ''}
                    </Typography>
                </div>
                </>
                ) : null}
                {event_id &&
                                this.state.chosenEvent &&
                                history.location.pathname !== "/phone_auth" &&
                                history.location.pathname !== "/login" && (
                                        <>
                <div>
                    <Typography
                        component="p"
                        variant="body2"
                        classes={{
                                        body2: classes.text,
                                        }}
                        style={
                                        this.themes(
                                                this.state.chosenEvent
                                                ).textColor && {
                                color: this.themes(
                                        this.state
                                        .chosenEvent
                                        ).textColor,
                                }
                                                                                                }
                        >
                        {ReactHtmlParser(
                                        this.state.chosenEvent.name
                                        )}
                    </Typography>
                    <Typography
                        component="p"
                        variant="body2"
                        classes={{
                                body2: classes.text,
                                }}
                        style={
                                        this.themes(
                                                this.state.chosenEvent
                                                ).textColor && {
                                color: this.themes(
                                        this.state
                                        .chosenEvent
                                        ).textColor,
                                }
                                                                                                }
                        >
                        {
                                        this.state.chosenEvent[
                                                "^venue"
                                        ].name
                        }
                        , {event ? this.state.chosenEvent.datetime_start_formatted : ''}
                    </Typography>
                </div>
                </>
                )}
            </div>
                                        <div>
                {((event && Object.keys(event).length) ||
                                                isAuthenticated) &&
                                        history.location.pathname !== "/login" ? (
                                                <Button
                    type={"exit"}
                    className={classes.button}
                    aria-label="Delete"
                    onClick={() =>
                                                this.setState({ show_modal: true })
                                                                        }
                    >
                    <CustomIcon>
                        <path d="M12.2495 23.499H2.49996V2.4999H12.2495C12.6635 2.4999 12.9995 2.16388 12.9995 1.74995C12.9995 1.33601 12.6635 1 12.2495 1H1.74995C1.33601 1 1 1.33595 1 1.74995V24.249C1 24.663 1.33601 24.9989 1.74995 24.9989H12.2495C12.6635 24.9989 12.9994 24.6629 12.9994 24.249C12.9995 23.835 12.6635 23.499 12.2495 23.499Z" />
                        <path d="M24.7825 12.4756L19.6077 7.22654C19.3144 6.9303 18.8397 6.93105 18.5472 7.22654C18.254 7.52203 18.254 8.00198 18.5472 8.29747L22.4471 12.2536H7.003C6.589 12.2536 6.25305 12.5926 6.25305 13.0111C6.25305 13.4295 6.58906 13.7685 7.003 13.7685H22.4471L18.5472 17.7246C18.254 18.0209 18.254 18.5001 18.5472 18.7956C18.8405 19.0918 19.3152 19.0918 19.6077 18.7956L24.7817 13.5466C25.0712 13.2548 25.0742 12.7666 24.7825 12.4756Z" />
                    </CustomIcon>
                </Button>
                                                ) : null}
            </div>
                                        </div>
                                        </div>
                                        </HeaderWrapper>
                                        );
}
}

const mapStateToProps = (state) => ({
                                isAuthenticated: state.staffReducer.isAuthenticated,
                                staff: state.staffReducer.staff,
                                timeOpenPage: state.staffReducer.timeOpenPage,
                                event_id: state.userReducer.event_id,
                                events: state.staffReducer.events,
                                globalChosenEvent: state.staffReducer.globalChosenEvent,
});

const mapDispatchToProps = (dispatch) =>
bindActionCreators(
{
                                logOutStaff,
},
dispatch
);

export default connect(
mapStateToProps,
mapDispatchToProps
)(withStyles(styles)(withWidth()(Header)));
