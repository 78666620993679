import { 
  MAIN_ACCENT_COLOR, 
  MAIN_ACCENT_DARK_COLOR, 
  MAIN_BACKGROUND_COLOR,
  MAIN_TEXT_SM, 
  MAIN_TEXT_MD, 
  MAIN_TEXT_LG,
  ACCENT_GRAY_COLOR
} from '../../../styles/constants';
import brand_theme from '../../../styles/brand_theme';

const styles = theme => ({
  defaultRoot: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: MAIN_ACCENT_COLOR,
    border: `2px solid ${MAIN_ACCENT_COLOR}`,
    boxSizing: 'border-box',
    borderRadius: '2px',
    boxShadow: 'none!important',
    [theme.breakpoints.down('sm')]: {
      padding: `20px 5px 20px 5px`
    },
    [theme.breakpoints.up('md')]: {
      padding: `23px 10px 23px 10px`
    },
    [theme.breakpoints.up('lg')]: {
      padding: `25.5px 10px 25.5px 10px`
    },
    '&:hover': {
      backgroundColor: MAIN_ACCENT_DARK_COLOR,
      border: `2px solid ${MAIN_ACCENT_DARK_COLOR}`,
    },
  },
  darkRoot: {
    backgroundColor: 'transparent',
    boxShadow: 'none!important',
    '&:hover': {
      backgroundColor: MAIN_BACKGROUND_COLOR,
      border: `2px solid ${MAIN_BACKGROUND_COLOR}`,
    },
  },
  whiteRoot: {
    backgroundColor: 'transparent',
    color: MAIN_ACCENT_COLOR,
    boxShadow: 'none!important',
    border: `2px solid #8189A5`,
    '&:hover': {
      backgroundColor: `${MAIN_BACKGROUND_COLOR}!important`,
      color: '#fff!important',
      border: `2px solid ${MAIN_BACKGROUND_COLOR}!important`,
    },
  },
  transparentRoot: {
    backgroundColor: 'transparent',
  },
  label: {
    [theme.breakpoints.down('sm')]: {
      fontSize: `${MAIN_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${MAIN_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
    lineHeight: 'normal',
    textAlign: 'center',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    boxShadow: 'none!important',
  },
  disabled: {
    backgroundColor: `${ACCENT_GRAY_COLOR}!important`,
    border: `2px solid ${ACCENT_GRAY_COLOR}!important`,
    color: `white!important`
  },
  ...brand_theme
})

export default styles;