import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QrReader from 'react-qr-reader';
import { Typography, Button, Icon } from '@material-ui/core';
import { getEventUser, updateTimeOpenPage } from '../../actions/staff';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { SCANNER_ZONE } from '../../styles/constants';
import { STATUS } from '../../constants';

class codeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacyMode: false,
      error: false
    }
  }

  componentDidMount() {
    this.props.updateTimeOpenPage();
  }

  handleScan = (value) => {
    const { getEventUser, staff, statusRequest } = this.props;

    if (statusRequest === STATUS.RUNNING) return;
    
    if (!value && this.state.error) alert('QR-code was not scan correctly, please try again');
    if (value && value.indexOf('X-USER-ID') !== -1  && value.indexOf('X-USER-CODE') !== -1 ) {
      getEventUser(
        null, 
        null, 
        value.substring(value.indexOf('X-USER-ID')+10, value.indexOf('X-USER-CODE')).replace('\n', ''), 
        value.substring(value.indexOf('X-USER-CODE')+12, value.indexOf('END')).replace('\n', ''), 
        staff.role,
        false,
        true
      );
    }
  }

  handleError = (err) => {
    this.setState({legacyMode: true, error: true});
    this.openImageDialog();
  }

  openImageDialog = () => {
    this.refs.qrReader.openImageDialog();
  }

  render(){
    const { classes } = this.props;
    const { legacyMode, error } = this.state;

    return (
      <div className={classes.content}>
        <QrReader
          ref="qrReader"
          delay={1000}
          onError={this.handleError}
          onScan={this.handleScan}
          className={classes.scannerContent}
          showViewFinder={false}
          legacyMode={legacyMode}
        />
        
        <div className={classes.textWrap}>
          <Typography component="p" variant="body2" classes={{body2: classes.text}}>
            Hover over the QR-code to scan
          </Typography>
          <img src={SCANNER_ZONE} alt={'Scaner zone'} className={classes.img}/>
          {
            (error) &&  <Button 
              aria-label="Camera"
              style={{ textTransform: 'none', marginTop: 'none' }}
              className={classes.link}
              onClick={this.openImageDialog}
              >
              <Icon className={classes.iconLink}>
                camera_alt
              </Icon>
              <Typography component="p" variant="body2" classes={{body2: classes.textLink}}>
                Open camera
              </Typography>
            </Button>
          }
        </div>
      </div>
    )
  }
}

codeScanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
  staff: state.staffReducer.staff,
  statusRequest: state.securityReducer.status
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getEventUser,
    updateTimeOpenPage
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(codeScanner));
