import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import { Typography, Button, IconButton } from '@material-ui/core';
import config from '../../../../config';
import { ReactComponent as StarIcon } from '../../../../resources/images/star.svg';
import { ReactComponent as ArrowIcon } from '../../../../resources/images/arrow_right.svg';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

class CancelledWinnerScreen extends Component {

  renderSimpleItem = (item) => {
    const { classes, onChooseClick } = this.props;

    return (
      <div key={item.id} className={classes.simpleItem}>
        <div className={classes.simpleContentBlock}>
          <Typography component="h4" variant="h4" classes={{ h4: classes.title }}>{`${item.firstname} ${item.lastname}`}</Typography>
        </div>
        <div className={classes.simpleRateBlock}>
          <div className={classes.rate}>
            <StarIcon className={classes.starIcon} />
            <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${item.points} points`}</Typography>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => onChooseClick(item.id)}
            classes={{
              root: classNames(
                classes.formButtonRoot,
              ),
              disabled: classes.formButtonDisabled,
              label: classes.formButtonLabel
            }}
          >
            Choose
          </Button>
          <IconButton aria-label="ok" className={classNames(classes.iconButton)} onClick={() => onChooseClick(item.id)}>
            <ArrowIcon />
          </IconButton>
        </div>

      </div>
    )
  }

  render() {
    const { classes, winner, spare_winners } = this.props;

    return (
      <div className={classes.content}>
        <div className={classes.winnerItem} >
          <div className={classNames(classes.imageBlock, classes.disabled)}>
            <img src={config.imagesUrl + winner.prizeWon['^img'][""]} alt="prize" className={classes.image} />
          </div>
          <div className={classes.contentBlock}>
            <div className={classes.contentInfo}>
              <Typography component="h4" variant="h4" classes={{ h4: classNames(classes.title, classes.disabled) }}>{`${winner.firstname} ${winner.lastname}`}</Typography>
              <div className={classNames(classes.rate, classes.disabled)}>
                <StarIcon className={classes.starIcon} />
                <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${winner.points} points`}</Typography>
              </div>
              <Typography component="p" variant="body1" classes={{ body1: classes.description }}>{winner.prizeWon.name}</Typography>
              <Typography component="span" variant="body2" classes={{ body2: classNames(classes.statusText, classes.statusTextFailute) }}>Cancelled</Typography>
            </div>
            <div className={classNames(classes.contentImage, classes.disabled)}>
              <img src={config.imagesUrl + winner.prizeWon['^img'].small} alt="prize" className={classes.image} />
            </div>
          </div>
          <div className={classes.rateBlock}>
            <div className={classNames(classes.rate, classes.disabled)}>
              <StarIcon />
              <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${winner.points} points`}</Typography>
            </div>
          </div>
        </div>
        <Typography component="h3" variant="h3" classes={{ h3: classes.label }}>Please choose one of the spare winners who will get the prize.</Typography>
        {
          spare_winners.map((item) => this.renderSimpleItem(item))
        }
      </div>
    )
  }
}

CancelledWinnerScreen.propTypes = {
  classes: PropTypes.object.isRequired
};


export default withStyles(styles)(withWidth()(CancelledWinnerScreen));
