import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import classNames from 'classnames';
import moment from 'moment';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import history from '../../modules/history';
import { Typography, Link, Grid, IconButton, Button, InputBase } from '@material-ui/core';
import { Container } from 'styled-minimal';
import CButton from '../../components/FormElements/CButton';
import CancelledWinnerComponent from './components/CanceledWinner';
import { getCurrentWinner, getWinnersList, replaceWinner, sendWinnerMessage } from '../../actions/prizes';
import { updateTimeOpenPage } from '../../actions/staff';
import config from '../../config';
import { ReactComponent as ArrowIcon } from '../../resources/images/arrow.svg';
import { ReactComponent as StarIcon } from '../../resources/images/star.svg';
import { ReactComponent as OKIcon } from '../../resources/images/ok.svg';
import { ReactComponent as CloseIcon } from '../../resources/images/close.svg';
import { ReactComponent as CloseRedIcon } from '../../resources/images/close_red.svg';
import { ReactComponent as CheckIcon } from '../../resources/images/check.svg';
import { ReactComponent as RepeatIcon } from '../../resources/images/repeat.svg';
import withStyles from '@material-ui/core/styles/withStyles';

import styles from './styles';

const DEFAULT_MESSAGE_1 = 'You’ve got 2 minutes left to claim your prize!';
const DEFAULT_MESSAGE_2 = 'Sorry, looks like your 5 minutes are up. Thanks for playing!';

const HomeContainer = styled(Container)`
  align-items: top;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class WinnerScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialogType: null,
      cancelledWinner: false,
      text_1: DEFAULT_MESSAGE_1,
      text_2: DEFAULT_MESSAGE_2,
      text: '',
    }
  }

  componentDidMount() {
    const { getCurrentWinner, updateTimeOpenPage, match } = this.props;
    
    updateTimeOpenPage()
    if (match.params.id) {
      getCurrentWinner(match.params.id)
    }
  }

  handleClick = (dialogType) => {
    const { getWinnersList, winner } = this.props;
    
    if (dialogType === 'hand_out') {
      history.push(`${winner.id}/scane`)
    } else {
      getWinnersList()
      this.setState({cancelledWinner: true})
    }

    this.setState({ showDialogType: null })
  }

  onChangeWinner = (new_user_id) => {
    const { winner, replaceWinner } = this.props;

    replaceWinner(winner.id, new_user_id);
    this.setState({cancelledWinner: false})
  }

  handleChange = (event, name) => {
    this.setState({ [name]: event.target.value });
  };

  formatPhoneNumber(phone) {
    phone = phone.replace(/[^\d]/g, "");

    if (phone.length === 11) {
      return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3 $4");
    }

    return phone;
}


  renderDialogButton = (buttonProps, title, dialogTitle, dialogType ) => {
    const { classes } = this.props;
    const { showDialogType } = this.state;

    if (showDialogType === dialogType) {
      return (
        <div className={classes.dialog}>
          <Typography component="span" variant="body1" classes={{ body1: classes.dialogTitle }}>{dialogTitle}</Typography>
          <IconButton aria-label="ok" className={classNames(classes.iconButton, classes.succesColor)} onClick={() => this.handleClick(dialogType)}>
            <OKIcon className={classNames(classes.icon)} />
          </IconButton>
          <IconButton aria-label="close" className={classNames(classes.iconButton)} onClick={() => this.setState({ showDialogType: null })}>
            <CloseIcon className={classNames(classes.icon)} />
          </IconButton>
        </div>
      )
    }
    return (
      <CButton
        {...buttonProps}
      >
        {title}
      </CButton>
    )
  }

  renderMessage = (message) => {
    const { classes, sendWinnerMessage, winner } = this.props;

      return (
        <div className={classes.itemMessage} key={message.id}>
          <Typography component="p" variant="body1" classes={{ body1: classes.messageText }}>{message.body}</Typography>
          <div className={classes.infoMessage}>
            <div className={classes.leftInfoMessage}>
              <Typography component="span" variant="body1" classes={{ body1: classes.messTextDetail }}>{moment(message.date_add*1000).format('LT').toLowerCase()}</Typography>
              <div className={classes.statusMessage}>
                {
                  (message.status === 1 ) ? (
                    <CheckIcon className={classes.statusIcon} />
                  ) : (message.status === 2) ? 
                    <CloseRedIcon className={classes.statusIcon} />
                  : <div/>
                }
                <Typography component="span" variant="body1" classes={{ body1: classNames(classes.messTextDetail, classes.bold) }}>{message.statusMsg}</Typography>
              </div>
            </div>
            <div>
              <Button 
                className={classNames(classes.messTextDetail, classes.bold, classes.dark)}
                onClick={() => sendWinnerMessage(winner.id, message.id, message.body)}
              >
                <RepeatIcon className={classes.repeatIcon} />
                Repeat
              </Button>
              <IconButton 
                aria-label="repeat" 
                className={classes.repeatIcon_xs}
                onClick={() => sendWinnerMessage(winner.id, message.id, message.body)}
              >
                <RepeatIcon />
              </IconButton>
            </div>
          </div>
        </div>
      )
  }

  renderCreateMessage = (name, default_message = '', number) => {
    const { classes, winner, sendWinnerMessage } = this.props;
    if (!winner) return null;

    return (
      <div className={classNames(classes.itemMessage, classes.borderedMessage)}>
        <InputBase
          className={classes.input}
          multiline
          placeholder="Your text..."
          value={this.state[name]}
          onChange={(e) => this.handleChange(e, name)}
          inputProps={{ 'aria-label': 'naked' }}
        />
        <Button
          onClick={() => {
            sendWinnerMessage(winner.id, null, this.state[name], (this.state[name] === default_message) ? number : null);
            this.setState({[name]: default_message, })
          }}
          disabled={!this.state[name] || this.state[name].length < 10}
          classes={{
            root: classNames(
              classes.formButtonRoot,
            ),
            disabled: classes.formButtonDisabled,
            label: classes.formButtonLabel
          }}
        >
          Send
        </Button>
      </div>
    )
  }

  render() {
    const { classes, winner, match, spare_winners, sentUserMessages } = this.props;
    const { cancelledWinner } = this.state;

    if (!winner || winner.id !== +match.params.id) return null;
    const received = winner.prize_received;

    return (
      <HomeContainer>
        <div className={classes.header}>
          <div className={classNames(classes.headerLinkWrap)} onClick={() => history.goBack()}>
            <ArrowIcon className={classNames(classes.headerIcon)} />
            <Link
              component="button"
              variant="body2"
              classes={{ button: classNames(classes.headerText) }}
            >
              Winners
            </Link>
          </div>
        </div>
        {
          cancelledWinner ? (
            <CancelledWinnerComponent 
              winner={winner}
              spare_winners={spare_winners}
              onChooseClick={this.onChangeWinner}
            />
          ) : (
              <div className={classes.content}>
                <Grid container className={classes.mainInfo}>
                  <Grid item xs={12} sm={8} className={classes.leftPart}>
                    <div className={classes.contentHeader}>
                      <Typography component="h1" variant="h1" classes={{ h1: classes.title }}>{`${winner.firstname} ${winner.lastname}`}</Typography>
                      <div className={classes.rate}>
                        <StarIcon className={classes.starIcon} />
                        <Typography component="span" variant="body2" classes={{ body2: classes.rateText }}>{`${winner.points} points`}</Typography>
                      </div>
                    </div>
                    <div className={classes.imageBlock}>
                      <img src={config.imagesUrl + winner.prizeWon['^img'].small} alt="prize" className={classes.image} />
                    </div>
                    <div className={classes.contentInfo}>
                      <Typography component="span" variant="body1" classes={{ body1: classNames(classes.description, classes.grayDescription) }}>{this.formatPhoneNumber(winner.phone)}</Typography>
                      <Typography component="span" variant="body1" classes={{ body1: classNames(classes.description, classes.grayDescription) }}>{winner.email}</Typography>
                      <Typography component="p" variant="body1" classes={{ body1: classes.description }}>{winner.prizeWon.name}</Typography>
                      <Typography component="span" variant="body2"classes={{ body2: classNames(classes.statusText, !received && classes.statusTextFailute) }}>{`${received ? 'Received' : 'Not received'}`}</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} className={classes.contentImage}>
                    <img src={config.imagesUrl + winner.prizeWon['^img'][""]} alt="prize" className={classes.image} />
                  </Grid>
                </Grid>
                <Grid container spacing={isWidthDown('xs', this.props.width) ? 8 : 16} className={classes.buttons}>
                  <Grid item xs={12} sm={6}>
                    {this.renderDialogButton(
                      {
                        onClick: () => { this.setState({ showDialogType: 'hand_out' }) },
                        fullWidth: true,
                        disabled: received ? true : false,
                      },
                      'hand out',
                      'hand out?',
                      'hand_out'
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {this.renderDialogButton(
                      {
                        onClick: () => { this.setState({ showDialogType: 'replace' }) },
                        fullWidth: true,
                        typeStyle: 'white',
                        disabled: received ? true : false,
                      },
                      'Replace winner',
                      'Replace?',
                      'replace'
                    )}
                  </Grid>
                </Grid>
                <div className={classes.chatContent}>
                  <Typography component="h3" variant="h3" classes={{ h3: classes.messageTitle }}>Messages</Typography>
                  { winner.messagesLog.map((item) => this.renderMessage(item))}
                  {(!sentUserMessages[winner.id] || !sentUserMessages[winner.id][1]) && this.renderCreateMessage('text_1', DEFAULT_MESSAGE_1, 1)}
                  {(!sentUserMessages[winner.id] || !sentUserMessages[winner.id][2]) && this.renderCreateMessage('text_2', DEFAULT_MESSAGE_2, 2)}
                  {(sentUserMessages[winner.id] && sentUserMessages[winner.id][1] && sentUserMessages[winner.id][2]) && this.renderCreateMessage('text')}
                </div>
              </div>
            )
        }
      </HomeContainer >
    )
  }
}

WinnerScreen.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  winner: state.prizesReducer.current_winner,
  sentUserMessages: state.prizesReducer.sentUserMessages || {},
  spare_winners: state.prizesReducer.spare_winners || [],
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getCurrentWinner,
    getWinnersList,
    replaceWinner,
    sendWinnerMessage,
    updateTimeOpenPage
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(WinnerScreen)));
