import { 
    LARGE_TEXT_LG,
    LARGE_TEXT_MD,
    LARGE_TEXT_SM,
    MAIN_TEXT_LG,
    LINK_TEXT_LG,
    LINK_TEXT_SM,
    LINK_ACCENT_COLOR,
    MAIN_COLOR,
    GRAY_COLOR
  } from '../../styles/constants';
  
  const styles = theme => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit*6,
    },
    submit: {
      marginTop: theme.spacing.unit * 3,
    },
    textBlock: {
      width: '100%',
      alignItems: 'flex-start'
    },
    h1: {
      color: 'white',
      fontFamily: "Montserrat-Bold",
      [theme.breakpoints.down('sm')]: {
        fontSize: `${LARGE_TEXT_SM}px`,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `${LARGE_TEXT_MD}px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `${LARGE_TEXT_LG}px`,
      },
      marginBottom: 10
    },
    text: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: `18px`,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `19px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `${MAIN_TEXT_LG}px`,
      },
    },
    link: {
      color: 'white',
      marginTop: 15,
      [theme.breakpoints.down('sm')]: {
        fontSize: `${LINK_TEXT_SM}px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `${LINK_TEXT_LG}px`,
      },
      '&:hover': {
        color: LINK_ACCENT_COLOR,
        textDecoration: 'none'
      },
    },
    paper: {
      margin: '0 auto',
      outline: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing.unit * 42,
      },
      [theme.breakpoints.up('md')]: {
        width: theme.spacing.unit * 50,
      },
      [theme.breakpoints.up('lg')]: {
        width: theme.spacing.unit * 70,
      },
    },
    modalContent: {
      backgroundColor: '#fff',
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '30px',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '40px',
      },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    titleModal: {
      color: MAIN_COLOR,
      fontFamily: "Montserrat-Bold",
      lineHeight: '60px',
      letterSpacing: -0.5,
      [theme.breakpoints.down('sm')]: {
        fontSize: `30px`,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `35px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `40px`,
      },
    },
    textModal: {
      color: GRAY_COLOR,
      opacity: 0.9,
      lineHeight: '40px',
      [theme.breakpoints.down('sm')]: {
        fontSize: `20px`,
        marginBottom: 20
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `25px`,
        marginBottom: 40
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `30px`,
        marginBottom: 50
      },
    },
    xsHide: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      },
      [theme.breakpoints.up('lg')]: {
        display: 'block'
      },
    },
    xsShow: {
      [theme.breakpoints.down('xs')]: {
        display: 'block'
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      },
    },
    imageWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  });
  
  export default styles;