import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl, InputLabel, withStyles } from '@material-ui/core';
import { DatePicker } from "material-ui-pickers";
import moment from 'moment';
import config from '../../../config';
import styles from './styles';

class CDateInput extends React.Component {
  static propTypes = {
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    error_text: PropTypes.string,
    value: PropTypes.any,
    onChangeDate: PropTypes.func,
    isValid: PropTypes.bool,
    validate: PropTypes.func,
    margin: PropTypes.string,
    placeholder: PropTypes.string,
    themeId: PropTypes.any 
  };

  render() {
    const { 
      fullWidth, 
      required, 
      label,
      placeholder,
      classes,
      value,
      onChangeDate,
      isValid,
      validate,
      margin = 'none',
      themeId
    } = this.props;

    return (
      <FormControl margin={margin} fullWidth={fullWidth}>
        <InputLabel 
          shrink 
          className={
            classNames(
              classes.bootstrapFormLabel,
              (config.themes[themeId]) && classes[`input_label_${themeId}`],
            )
          } 
          focused={false}
        >
          {label}
        </InputLabel>
        <DatePicker
          keyboard
          clearable
          placeholder={placeholder}
          value={value}
          disableFuture
          maxDate={moment().add(-16, 'years')}
          initialFocusedDate={moment().add(-16, 'years')}
          openTo="year"
          format={"YYYY/MM/DD"}
          invalidLabel={'yyyy/mm/dd'}
          views={["year", "month", "day"]}
          onBlur={validate}
          mask={value =>
            value ? [/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/] : []
          }
          className={
            classNames(
              classes.bootstrapInput,
              (config.themes[themeId]) && classes[`input_${themeId}`], 
              (required && isValid === true) && classes.isValidInput,  
              (required && isValid === false) && classes.NotValidInput,
              (!value || value === null || value === '') && classes.placeholderColor
            )
          }
          classes={{
            root: (label) && classes.bootstrapRoot,
          }}
          onChange={(event) => onChangeDate((event) ? event.format('YYYY-MM-DD') : event)}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(CDateInput);