// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants';

export const { 
  getWinnersList,
  getCurrentWinner,
  setHandOutPrize,
  replaceWinner,
  sendWinnerMessage 
} = createActions({
  [ActionTypes.GET_WINNERS_LIST]: () => ({}),
  [ActionTypes.GET_CURRENT_WINNER]: (id) => ({id}),
  [ActionTypes.SET_HAND_OUT_PRIZE]: (user_id) => ({user_id}),
  [ActionTypes.REPLACE_WINNER]: (user_id, new_user_id) => ({user_id, new_user_id}),
  [ActionTypes.SEND_WINNER_MESSAGE]: (user_id, message_id, text, default_message) => ({user_id, message_id, text, default_message}),
});