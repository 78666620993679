// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants';

export const { toggleErrorPopup, setErrorMessage } = createActions({
  [ActionTypes.TOGGLE_ERROR_POPUP]: (value) => (value),
  [ActionTypes.SET_ERROR_MESSAGE]: (message) => ({message}) 
});