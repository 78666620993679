import {
  MAIN_ACCENT_COLOR,
} from '../../../styles/constants';

const styles = theme => ({
  option: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 18px 0 18px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '18px 22px 0 22px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px 30px 0 30px',
    },
  },
  optionName: {
    color: 'black',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '25px',
    },
    '&:hover': {
      color: MAIN_ACCENT_COLOR
    }
  },
  optionPhone: {
    color: '#8189A5',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
})

export default styles;