import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { ActionTypes } from '../constants/index';

export const errorState = {
  errorMessage: null,
  showPopup: false,
};

export default {
  errorReducer: handleActions(
    {
      [ActionTypes.SET_ERROR_MESSAGE]: (state, {payload: { message }}) =>
        immutable(state, {
          showPopup: { $set: true },
          errorMessage: { $set: message},
        }),
      [ActionTypes.TOGGLE_ERROR_POPUP]: (state, {payload}) =>
        immutable(state, {
          showPopup: { $set: payload },
        }),
    },
    errorState,
  ),
};