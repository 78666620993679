
import { all, fork } from 'redux-saga/effects';

import user from './user';
import staff from './staff';
import prizes from './prizes';

/**
 * rootSaga
 */
export default function* root() {
  yield all([fork(user), fork(staff), fork(prizes)]);
}