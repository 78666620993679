import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default class ShowQRIcon extends React.Component {

  render() {
    const { width = 24, height = 24, color = "#fff", style = {} } = this.props;

    return (
      <SvgIcon viewBox="0 0 24 24" style={{width, height, ...style}}>
        <path
          d="M15 21h-2v-2h2v2zm-2-7h-2v5h2v-5zm8-2h-2v4h2v-4zm-2-2h-2v2h2v-2zM7 12H5v2h2v-2zm-2-2H3v2h2v-2zm7-5h2V3h-2v2zm-7.5-.5v3h3v-3h-3zM9 9H3V3h6v6zm-4.5 7.5v3h3v-3h-3zM9 21H3v-6h6v6zm7.5-16.5v3h3v-3h-3zM21 9h-6V3h6v6zm-2 10v-3h-4v2h2v3h4v-2h-2zm-2-7h-4v2h4v-2zm-4-2H7v2h2v2h2v-2h2v-2zm1-1V7h-2V5h-2v4h4zM6.75 5.25h-1.5v1.5h1.5v-1.5zm0 12h-1.5v1.5h1.5v-1.5zm12-12h-1.5v1.5h1.5v-1.5z" 
          fill={color}
        />
      </SvgIcon>
    );
  }
}