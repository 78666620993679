import {
  MAIN_ACCENT_COLOR,
  LABEL_TEXT_SM,
  lABEL_TEXT_MD,
  LABEL_TEXT_LG
} from '../../../styles/constants';
import brand_theme from '../../../styles/brand_theme';

const styles = theme => ({
  option: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 18px 0 18px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '18px 22px 0 22px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '20px 30px 0 30px',
    },
  },
  optionName: {
    color: 'black',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '25px',
    },
    '&:hover': {
      color: MAIN_ACCENT_COLOR
    }
  },
  optionPhone: {
    color: '#8189A5',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '17px',
    },
  },
  bootstrapFormLabel: {
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat-Bold',
    letterSpacing: '2px',
    boxShadow: 'none!important',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LABEL_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${lABEL_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LABEL_TEXT_LG}px`,
    },
  },
  ...brand_theme
})

export default styles;