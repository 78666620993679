import {
  GRAY_COLOR,
  NORMAL_TEXT_X_SM,
  NORMAL_TEXT_X_MD,
  NORMAL_TEXT_X_LG,
  LABEL_TEXT_LG,
  lABEL_TEXT_MD,
  LABEL_TEXT_SM,
  MAIN_ACCENT_COLOR,
  ERROR_RED
} from '../../../styles/constants';
import brand_theme from '../../../styles/brand_theme';

const styles = theme => ({
  bootstrapFormLabel: {
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat-Bold',
    letterSpacing: '2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LABEL_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${lABEL_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LABEL_TEXT_LG}px`,
    },
  },
  bootstrapRoot: {
    boxShadow: 'none!important',
    '-webkit-appearance': 'none',
    'label + & ': {
      marginTop: theme.spacing.unit * 3,
    },
    'label + & div': {
      [theme.breakpoints.down('sm')]: {
        fontSize: `${NORMAL_TEXT_X_SM}px`,
        height: 56,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `${NORMAL_TEXT_X_MD}px`,
        height: 66,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `${NORMAL_TEXT_X_LG}px`,
        height: 76,
      },
      '&:before': {
        display: 'none'
      },
      '&:after': {
        display: 'none'
      }
    },
    'label + & input': {

      [theme.breakpoints.down('sm')]: {
        height: 56,
      },
      [theme.breakpoints.up('md')]: {
        height: 66,
      },
      [theme.breakpoints.up('lg')]: {
        height: 76,
      },
    },
    'label + & p': {
      display: 'none'
    },
  },
  placeholderColor: {
    color: GRAY_COLOR
  },
  bootstrapInput: {
    position: 'relative',
    background: theme.palette.common.white,
    border: '2px solid white',
    borderRadius: 2,
    lineHeight: '30px',
    boxShadow: 'none!important',
    letterSpacing: '-0.5px',
    '-webkit-appearance': 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: `${NORMAL_TEXT_X_SM}px`,
      padding: `0px 18px 0px 18px`,
      height: 56,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${NORMAL_TEXT_X_MD}px`,
      padding: `0px 22px 0px 22px`,
      height: 66,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${NORMAL_TEXT_X_LG}px`,
      padding: `0px 30px 0px 30px`,
      height: 76,
    },
    '&::placeholder': {
      color: GRAY_COLOR
    },
  },
  ...brand_theme,
  isValidInput: {
    border: `2px solid ${MAIN_ACCENT_COLOR}`,
  },
  NotValidInput: {
    border: `2px solid ${ERROR_RED}`,
  },

  helperText: {
    color: `${ERROR_RED}!important`,
    position: 'absolute',
    bottom: -20
  }
});

export default styles;