import keyMirror from 'fbjs/lib/keyMirror';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  /** User*/
  SET_BAR_CHOOSEN: 'SET_BAR_CHOOSEN',
  GET_BAR_LIST: 'GET_BAR_LIST',
  GET_BAR_LIST_SUCCESS: 'GET_BAR_LIST_SUCCESS',
  GET_BAR_LIST_FAILURE: 'GET_BAR_LIST_FAILURE',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  AUTH_USER: 'AUTH_USER',
  AUTH_USER_SUCCESS: 'AUTH_USER_SUCCESS',
  AUTH_USER_FAILURE: 'AUTH_USER_FAILURE',
  CLEARE_USER: 'CLEARE_USER',


  /** Staff*/
  GET_STAFF: 'GET_STAFF',
  GET_STAFF_SUCCESS: 'GET_STAFF_SUCCESS',
  GET_STAFF_FAILURE: 'GET_STAFF_FAILURE',
  AUTH_STAFF: 'AUTH_STAFF',
  AUTH_STAFF_SUCCESS: 'AUTH_STAFF_SUCCESS',
  AUTH_STAFF_FAILURE: 'AUTH_STAFF_FAILURE',
  LOG_OUT_STAFF: 'LOG_OUT_STAFF',
  LOG_OUT_STAFF_SUCCESS: 'LOG_OUT_STAFF_SUCCESS',
  LOG_OUT_STAFF_FAILURE: 'LOG_OUT_STAFF_FAILURE',
  UPDATE_TIME_OPEN_PAGE: 'UPDATE_TIME_OPEN_PAGE',
  RESET_PASSWORD_STAFF: 'RESET_PASSWORD_STAFF',
  RESET_PASSWORD_STAFF_SUCCESS: 'RESET_PASSWORD_STAFF_SUCCESS',
  RESET_PASSWORD_STAFF_FAILURE: 'RESET_PASSWORD_STAFF_FAILURE',
  CREATE_NEW_PASSWORD_STAFF: 'CREATE_NEW_PASSWORD_STAFF',
  CREATE_NEW_PASSWORD_STAFF_SUCCESS: 'CREATE_NEW_PASSWORD_STAFF_SUCCESS',
  CREATE_NEW_PASSWORD_STAFF_FAILURE: 'CREATE_NEW_PASSWORD_STAFF_FAILURE',
  RESET_PASS_STEP: 'RESET_PASS_STEP',
  GET_EVENT_USER: 'GET_EVENT_USER',
  GET_EVENT_USER_SUCCESS: 'GET_EVENT_USER_SUCCESS',
  GET_EVENT_USER_FAILURE: 'GET_EVENT_USER_FAILURE',
  SET_FOUND_USER: 'SET_FOUND_USER',
  CHECK_USER_TO_EVENT: 'CHECK_USER_TO_EVENT',
  CHECK_USER_TO_EVENT_SUCCESS: 'CHECK_USER_TO_EVENT_SUCCESS',
  CHECK_USER_TO_EVENT_FAILURE: 'CHECK_USER_TO_EVENT_FAILURE',
  CHECK_USER_TO_EVENT_TOGGLE: 'CHECK_USER_TO_EVENT_TOGGLE',
  CLEARE_EVENT_USER: 'CLEARE_EVENT_USER',
  CLEARE_ALL_FOUND_USERS: 'CLEARE_ALL_FOUND_USERS',
  GIVE_EDUCATIONAL_PACK: 'GIVE_EDUCATIONAL_PACK',
  GIVE_EDUCATIONAL_PACK_SUCCESS: 'GIVE_EDUCATIONAL_PACK_SUCCESS',
  GIVE_EDUCATIONAL_PACK_FAILURE: 'GIVE_EDUCATIONAL_PACK_FAILURE',
  GET_EVENTS: 'GET_EVENTS',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE' ,
  SET_GLOBAL_CHOSEN_EVENT: 'SET_GLOBAL_CHOSEN_EVENT',

  /** Events*/
  EVENTS_SIGNUP_SUCCESS: 'EVENTS_SIGNUP_SUCCESS',
  EVENTS_SIGNUP_FAILURE: 'EVENTS_SIGNUP_FAILURE',

  /** Prizes*/
  GET_WINNERS_LIST: 'GET_WINNERS_LIST',
  GET_WINNERS_LIST_SUCCESS: 'GET_WINNERS_LIST_SUCCESS',
  GET_WINNERS_LIST_FAILURE: 'GET_WINNERS_LIST_FAILURE',
  
  GET_CURRENT_WINNER: 'GET_CURRENT_WINNER',
  GET_CURRENT_WINNER_SUCCESS: 'GET_CURRENT_WINNER_SUCCESS',
  GET_CURRENT_WINNER_FAILURE: 'GET_CURRENT_WINNER_FAILURE',

  SET_HAND_OUT_PRIZE: 'SET_HAND_OUT_PRIZE',
  SET_HAND_OUT_PRIZE_SUCCESS: 'SET_HAND_OUT_PRIZE_SUCCESS',
  SET_HAND_OUT_PRIZE_FAILURE: 'SET_HAND_OUT_PRIZE_FAILURE',

  REPLACE_WINNER: 'REPLACE_WINNER',
  REPLACE_WINNER_SUCCESS: 'REPLACE_WINNER_SUCCESS',
  REPLACE_WINNER_FAILURE: 'REPLACE_WINNER_FAILURE',

  SEND_WINNER_MESSAGE: 'SEND_WINNER_MESSAGE',
  SEND_WINNER_MESSAGE_SUCCESS: 'SEND_WINNER_MESSAGE_SUCCESS',
  SEND_WINNER_MESSAGE_FAILURE: 'SEND_WINNER_MESSAGE_FAILURE',

  /** Error*/
  TOGGLE_ERROR_POPUP: 'TOGGLE_ERROR_POPUP',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
});

/**
 * @constant {Object} STATUS
 * @memberof Constants
 */
export const STATUS = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};