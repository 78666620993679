/**
 * @module Sagas/User
 * @desc User
 */

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { request } from '../modules/client';
import { ActionTypes } from '../constants/index';
import history from '../modules/history';

export function* getStaff({payload: { noNavigate = false}}) {
  try {
    const response = yield call(
      request,
      `staff/get`,
    );
    yield put({
      type: ActionTypes.GET_STAFF_SUCCESS,
      payload: response,
    });

    if (!noNavigate) {
      if (response.staff.role === 'street_team' && history.location.pathname === '/') {
        history.replace('/phone_auth');
      } else if (( response.staff.role === 'security' || response.staff.role === 'edu_team' ) && history.location.pathname === '/') {
        history.replace('/home');
      } else if (response.staff.role === 'prizes' && history.location.pathname === '/') {
        history.replace('/winners');
      }
    }
    
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAFF_FAILURE,
      payload: err,
    });
  }
}

export function* getEvents() {
  try {
    const response = yield call(
      request,
      `events`,
      {
        method:'GET'
      }
    );
    yield put({
      type: ActionTypes.GET_EVENTS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.GET_EVENTS_FAILURE,
      payload: err,
    });
  }
}

export function* authStaff({payload: {email, password}}) {
  try {
    const response = yield call(
      request,
      `staff/auth`,
      {
        method: 'POST',
        payload: { email, password }
      }
    );
    yield put({
      type: ActionTypes.AUTH_STAFF_SUCCESS,
      payload: response,
    });
    if (response.staff.role === 'street_team') {
      history.replace('/phone_auth');
    } else if (response.staff.role === 'security' || response.staff.role === 'edu_team') {
      history.replace('/home');
    } else if (response.staff.role === 'prizes') {
      history.replace('/winners');
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.AUTH_STAFF_FAILURE,
      payload: err,
    });
  }
}

export function* logOutStaff() {
  try {
    const response = yield call(
      request,
      `staff/logout`,
    );
    yield put({
      type: ActionTypes.LOG_OUT_STAFF_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOG_OUT_STAFF_FAILURE,
      payload: err,
    });
  }
}

export function* resetPasswordStaff({payload: {email}}) {
  try {
    const response = yield call(
      request,
      `staff/resetPassword`,
      {
        method: 'POST',
        payload: { email }
      }
    );
    yield put({
      type: ActionTypes.RESET_PASSWORD_STAFF_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    yield put({
      type: ActionTypes.RESET_PASSWORD_STAFF_FAILURE,
      payload: err,
    });
  }
}

export function* createNewPassStaff({payload: {email, checkword, password}}) {
  try {
    const response = yield call(
      request,
      `staff/changePassword`,
      {
        method: 'POST',
        payload: { email, checkword, password }
      }
    );
    yield put({
      type: ActionTypes.CREATE_NEW_PASSWORD_STAFF_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    yield put({
      type: ActionTypes.CREATE_NEW_PASSWORD_STAFF_FAILURE,
      payload: err,
    });
  }
}

export function* getEventStaff({payload: {phone, name, user_id, user_code, user_role, not_next_page = false, set_user = false}}) {
  try {
    const response = yield call(
      request,
      `staff/getUser`,
      {
        method: 'POST',
        payload: { phone, name, user_id, user_code }
      }
    );
    yield put({
      type: ActionTypes.GET_EVENT_USER_SUCCESS,
      payload: { ...response, set_user },
    });
    if (!not_next_page) history.push('/search_phone');
    if (user_role === 'edu_team') {
      history.push('/educational_packs');
    }
    yield put({
      type: ActionTypes.UPDATE_TIME_OPEN_PAGE,
    });
  } catch (err) {
    if (!not_next_page) history.push('/search_phone');
    yield put({
      type: ActionTypes.UPDATE_TIME_OPEN_PAGE,
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EVENT_USER_FAILURE,
      payload: { ...err, set_user},
    });
  }
}

export function* checkInUserToEvent({payload: {user_id, user_code}}) {
  try {
    const response = yield call(
      request,
      `staff/checkInUser`,
      {
        method: 'POST',
        payload: { user_id, user_code }
      }
    );
    yield put({
      type: ActionTypes.CHECK_USER_TO_EVENT_SUCCESS,
      payload: response,
    });
    yield getStaff({payload: false})
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHECK_USER_TO_EVENT_FAILURE,
      payload: err,
    });
  }
}

export function* giveEducationalPack({payload: {user_id, user_code, educational_pack_ids}}) {
  try {
    const response = yield call(
      request,
      `staff/giveEducationalPack`,
      {
        method: 'POST',
        payload: { user_id, user_code, educational_pack_ids }
      }
    );
    yield put({
      type: ActionTypes.GIVE_EDUCATIONAL_PACK_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.SET_ERROR_MESSAGE,
      payload: {message: err.statusMeans},
    });
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GIVE_EDUCATIONAL_PACK_FAILURE,
      payload: err,
    });
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_STAFF, getStaff),
    takeLatest(ActionTypes.GET_EVENT_USER, getEventStaff),
    takeLatest(ActionTypes.CHECK_USER_TO_EVENT, checkInUserToEvent),
    takeLatest(ActionTypes.AUTH_STAFF, authStaff),
    takeLatest(ActionTypes.LOG_OUT_STAFF, logOutStaff),
    takeLatest(ActionTypes.RESET_PASSWORD_STAFF, resetPasswordStaff),
    takeLatest(ActionTypes.CREATE_NEW_PASSWORD_STAFF, createNewPassStaff),
    takeLatest(ActionTypes.GIVE_EDUCATIONAL_PACK, giveEducationalPack),
    takeLatest(ActionTypes.GET_EVENTS, getEvents)
  ]);
}
