import {
  LINK_TEXT_LG,
  LINK_TEXT_SM,
  LINK_ACCENT_COLOR,
} from '../../styles/constants';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 6,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    flex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
    overflow: 'hidden',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      alignItems: 'end',
      marginBottom: 30,
    },
  },
  h1: {
    color: 'white',
    textTransform: 'none',
    fontFamily: "Montserrat-Bold",
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: `18px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `20px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `20px`,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    },
  },
  h2: {
    color: '#00AA60',
    textTransform: 'none',
    letterSpacing: '2px',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: `14px`,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `14px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `15px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `15px`,
    },
  },
  topText: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '-5px!important',
    },
  },
  text: {
    color: 'white',
    paddingBottom: 5,
    textTransform: 'none',
    letterSpacing: '-0.5px',
    lineHeight: '24px',
    opacity: 0.8,
    [theme.breakpoints.down('xs')]: {
      marginTop: -10,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: `14px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `16px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `16px`,
    },
    '& p': {
      marginTop: 10,
      marginBottom: 10
    }
  },
  link: {
    color: 'white',
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LINK_TEXT_SM}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LINK_TEXT_LG}px`,
    },
    '&:hover': {
      color: LINK_ACCENT_COLOR,
      textDecoration: 'none'
    },
  },
  button: {
    height: '140px',
    top: '60px',
    background: 'rgba(23, 32, 44, 0.9)',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: 'rgba(23, 32, 44, 0.9)',
    },
  },
  carouselSlider: {
    display: 'flex',
    flexDirection: 'column',
  },
  arrowTest: {
    width: '2em',
    height: '2em',
    color: 'white',
  },
  leftButton: {
    color: 'white',
    size: "large",
    position: 'absolute',
    zIndex: 10,
    left: 0,
    marginTop: '120px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  rightButton: {
    color: 'white',
    size: "large",
    position: 'absolute',
    zIndex: 10,
    right: 0,
    marginTop: '120px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  buttonDisable: {
    display: 'none'
  },
  wrapSliders: {
    overflow: 'hidden',
    width: '100%',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    },
  },
  wrapSlidersPadding: {
    overflow: 'hidden',
    width: '100%',
    margin: 0,
    padding: '0 30px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: 0
    },
  },
  mobileStepper: {
    flexGrow: 1,
    background: 'none',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  dotActive: {
    backgroundColor: LINK_ACCENT_COLOR,
  },
  dot: {
    width: 10,
    height: 10,
    margin: '0 10px',
  },
  card: {
    display: 'flex',
    height: '140px',
    position: 'relative',
    background: 'rgba(23, 32, 44, 0.9)',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    '&::selection': {
      color: '#ff0',
      background: '#000',
    },
    [theme.breakpoints.down('xs')]: {
      height: '120px',
    },
  },
  cardSelect: {
    border: '2px solid #00AA60',
  },
  details: {
    display: 'flex',
    width: '70%',
    flexDirection: 'column',
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 15px',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 9px',
    '&:last-child': {
      paddingBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  cover: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardDisable: {
    pointerEvents: 'none',
  },
  received: {
    position: 'absolute',
    zIndex: 9,
    background: 'rgba(23, 32, 44, 0.9)',
    textTransform: 'uppercase',
    width: '100%',
    height: '100%',
    padding: '15px 39px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 15px',
    },
  },
 drinks: {
    position: 'absolute',
    zIndex: 9,
    textTransform: 'uppercase',
    width: '100%',
    height: '100%',
    padding: '15px 39px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 15px',
    },
  },
});

export default styles;