/**
 * Created by piter on 4/21/19.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';

class ChechInCounter extends Component {
  render(){
    const { classes, accentStyle, checkedInUsers = 0, checkedInUsersOutOf = 0, containerClasses } = this.props;

    return (
      <div className={classNames(classes.container, containerClasses)}>
        <Typography component="h4" variant="h4" classes={{h4: classNames(classes.h4, classes.accentH4)}}>
          Checked in
        </Typography>
        <div>
          <span className={classNames(classes.count, classes.accentCount, accentStyle && classes.accentCounterStyle)}>{checkedInUsers}</span>
          <span className={classNames(classes.count, classes.accentCount, accentStyle && classes.accentCounterStyle )}>/</span>
          <span className={classes.count}>{checkedInUsersOutOf}</span>
        </div>
      </div>
    )
  }
}

ChechInCounter.propTypes = {
  classes: PropTypes.object.isRequired,
  accentStyle: PropTypes.bool
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  checkedInUsers: state.staffReducer.checkedInUsers,
  checkedInUsersOutOf: state.staffReducer.checkedInUsersOutOf
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChechInCounter));
