/**
 * Created by piter on 4/21/19.
 */
import {
  MAIN_ACCENT_COLOR,
} from '../../styles/constants';

const styles = theme => ({
  h4: {
    color: 'white',
    opacity: 0.6,
    fontFamily: "Montserrat-Bold",
    fontSize: '10px',
    marginBottom: 5,
    textTransform: 'uppercase'
  },
  accentH4: {
    opacity: 0.8,
  },
  count: {
    fontFamily: "Montserrat-Bold",
    color: 'white',
    paddingRight: '6px',
    letterSpacing: '-0.5px',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '25px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '30px',
    },
  },
  accentCount: {
    color: MAIN_ACCENT_COLOR,
    opacity: 1
  },
  accentCounterStyle: {
    color: 'white'
  },
  container: {
    position: 'absolute',
    right: 0,
    marginRight: 25,
    [theme.breakpoints.up('sm')]: {
      top: 45,
      
    },
    [theme.breakpoints.down('sm')]: {
      top: 20,
      marginRight: 32,
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 16,
    }
  }
});

export default styles;