import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Typography, Grid, Modal } from '@material-ui/core';
import { Container } from 'styled-minimal';
import { createNewPasswordStaff } from '../../actions/staff';
import withStyles from '@material-ui/core/styles/withStyles';
import CButton from '../../components/FormElements/CButton';
import CInput from '../../components/FormElements/CInput';
import styles from './styles';
import history from '../../modules/history';
import { BUTTON_CIRCLE } from '../../styles/constants';

const HomeContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      isValidPassword: null,
      isValidConfirmPassword: null,
      show_modal: false,
      linkParams: {}
    }
  }
  
  componentDidMount() {
    this.setState({linkParams: this.getJsonFromUrl(window.location.search)});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step && this.props.step === 2) this.setState({show_modal: true});
  }

  getJsonFromUrl(url) {
    let query = url.substr(1);
    let result = {};
    query.split("&").forEach((part) => {
      let item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  handleSubmit = () => {
    const { createNewPasswordStaff } = this.props;
    const { password, confirmPassword, linkParams } = this.state;

    if (this.validateForm(password, confirmPassword)) {
      createNewPasswordStaff(linkParams.email, linkParams.checkword, password);
    }
  };

  validatePassword(pass) {
    return (pass.indexOf(' ') === -1  && pass.length >= 3);
  }

  validateForm = (password, confirmPassword) => {
    let result = true;
    
    if (password !== null) {
      this.setState({isValidPassword: !(!password || !this.validatePassword(password))});
      result = !(!password || !this.validatePassword(password));
    }

    if (confirmPassword !== null) {
      this.setState({isValidConfirmPassword: !(!confirmPassword || !this.validatePassword(confirmPassword) || this.state.password !== confirmPassword)});
      result = !(!confirmPassword || !this.validatePassword(confirmPassword) || this.state.password !== confirmPassword);
    }
    
    return result;
  }

  handleChange = (event, isConfirm) => {
    const { password, confirmPassword } = this.state;

    this.setState({
      password: (isConfirm) ? password: event.target.value,
      confirmPassword: (isConfirm) ? event.target.value : confirmPassword,
    });
  };

  renderModal() {
    const { show_modal } = this.state;
    const { classes } = this.props;

    return (
      <Modal 
        open={show_modal}
        className={classes.paper}
        disableAutoFocus
        >
        <div className={classes.modalContent}>
          <div className={classes.imageWrap}>
            <img src={BUTTON_CIRCLE} alt="logo" />
          </div>
          <div>
            <Typography component="h3" variant="h3" classes={{h3: classes.titleModal}}>
              Your password has been reset
            </Typography>
            <Typography component="p" variant="body2" classes={{body2: classes.textModal}}>
              Return to the main screen
            </Typography>
          </div>
          <div >
            <CButton
              fullWidth
              onClick={()=>{
                this.setState({show_modal: false}, () => history.replace('/login'));
              }}
            >
              Back
            </CButton>  
          </div>
        </div>
      </Modal>
    )
  }

  render(){
    const { classes } = this.props;
    const { password, confirmPassword, isValidPassword, isValidConfirmPassword } = this.state;

    return (
      <HomeContainer>
        {this.renderModal()}
        <div className={classes.textBlock} >
          <Typography component="h1" variant="h1" classes={{h1: classes.h1}}>
            Enter a new 
          </Typography>
          <Typography component="h1" variant="h1" classes={{h1: classes.h1}}>
            password
          </Typography>
        </div>
        <form className={classes.form} method="POST">
          <Grid container spacing={24} justify="center" alignItems="center">
            <Grid item xs={12} sm={6}>
              <CInput
                required
                fullWidth
                name="password" 
                type="password" 
                label= 'New password'
                autoComplete="password" 
                value={password} 
                onChange={(event) => this.handleChange(event, false)}
                // onPaste={(e) => { e.preventDefault(); }}
                // onCut={(e) => { e.preventDefault(); }}
                placeholder={'Password'}
                isValid={isValidPassword}
                validate={(event) => {
                  const {relatedTarget} = event;
                  if (relatedTarget && ('button' === relatedTarget.getAttribute('type'))) {
                    this.handleSubmit();
                  }
                  this.validateForm(event.target.value, null)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CInput
                required
                fullWidth
                name="password" 
                type="password" 
                label= 'Repeat password'
                autoComplete="confirmPassword" 
                value={confirmPassword} 
                onChange={(event) => this.handleChange(event, true)}
                // onPaste={(e) => { e.preventDefault(); }}
                // onCut={(e) => { e.preventDefault(); }}
                placeholder={'Repeat password'}
                isValid={isValidConfirmPassword}
                validate={(event) => {
                  const {relatedTarget} = event;
                  if (relatedTarget && ('button' === relatedTarget.getAttribute('type'))) {
                    this.handleSubmit();
                  }
                  this.validateForm(null, event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CButton
                onClick={this.handleSubmit}
                className={classes.submit}
                fullWidth
                submit
              >
                Next
              </CButton>
            </Grid>
            <Grid item xs={12} sm={6}/>
          </Grid>  
        </form>
      </HomeContainer>
    )
  }
}

NewPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  staff: state.staffReducer.staff,
  step: state.staffReducer.ressetPasswordStep
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    createNewPasswordStaff,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewPassword));
