import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';

import { STATUS, ActionTypes } from '../constants/index';

export const securityState = {
    all_found_users: [],
    found_user: {},
    pinCode: null,
    userError: false,
    educationalPacks: {},
    isRegisteredToEvent: false,
    userAlreadyChecked: false,
    userChecked: false,
    modalComment: null,
    status: null,
    educationalPacksStatus: false,
};

export default {
    securityReducer: handleActions(
            {
                [ActionTypes.GET_EVENT_USER]: (state) =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                    }),
                [ActionTypes.SET_FOUND_USER]: (state, {payload: { data }}) =>
                    immutable(state, {
                        found_user: {$set: data},
                        isRegisteredToEvent: {$set: true},
                    }),
                [ActionTypes.GET_EVENT_USER_SUCCESS]: (state, {payload: { users, user, educationalPacks, userCheckedIn, set_user }}) =>
                    immutable(state, {
                        all_found_users: {$set: (users) ? users : (user) ? [user] : []},
                        found_user: {$set: (set_user && user) ? user : {}},
                        userError: {$set: false},
                        isRegisteredToEvent: {$set: true},
                        educationalPacks: {$set: (educationalPacks) ? educationalPacks : {}},
                        status: {$set: STATUS.READY},
                        educationalPacksStatus: {$set: false},
                        userAlreadyChecked: {$set: userCheckedIn},
                        modalComment: {$set: (userCheckedIn) ? 'Already check in' : 'Checked in'},
                    }),
                [ActionTypes.GET_EVENT_USER_FAILURE]: (state, { payload: { user, set_user }}) =>
                    immutable(state, {
                        all_found_users: {$set: (user) ? [user] : []},
                        found_user: {$set: (user && set_user) ? user : {}},
                        userError: {$set: (set_user) ? true : false},
                        isRegisteredToEvent: {$set: false},
                        status: {$set: STATUS.ERROR},
                    }),
                [ActionTypes.CHECK_USER_TO_EVENT_SUCCESS]: (state, {payload: { status, statusMeans }}) =>
                    immutable(state, {
                        userChecked: {$set: true},
                        modalComment: {$set: (status === 200) ? 'Checked in' : statusMeans},
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.CHECK_USER_TO_EVENT_FAILURE]: (state) =>
                    immutable(state, {
                        userChecked: {$set: false},
                        status: {$set: STATUS.ERROR},
                    }),
                [ActionTypes.CHECK_USER_TO_EVENT_TOGGLE]: (state, {payload}) =>
                    immutable(state, {
                        userChecked: {$set: payload},
                    }),
                [ActionTypes.CLEARE_EVENT_USER]: (state) =>
                    immutable(state, {
                        userChecked: {$set: false},
                        userAlreadyChecked: {$set: false},
                        found_user: {$set: {}},
                        isRegisteredToEvent: {$set: false},
                        status: {$set: null},
                    }),
                [ActionTypes.CLEARE_ALL_FOUND_USERS]: (state) =>
                    immutable(state, {
                        all_found_users: {$set: []}
                    }),
                [ActionTypes.GIVE_EDUCATIONAL_PACK]: state =>
                    immutable(state, {
                        status: {$set: STATUS.RUNNING},
                        errorMessage: {$set: null},
                    }),
                [ActionTypes.GIVE_EDUCATIONAL_PACK_SUCCESS]: (state, {payload}) =>
                    immutable(state, {
                        errorMessage: {$set: null},
                        educationalPacksStatus: {$set: true},
                        pinCode: { $set: payload.pin },
                        status: {$set: STATUS.READY},
                    }),
                [ActionTypes.GIVE_EDUCATIONAL_PACK_FAILURE]: (state, { payload: {statusMeans}}) =>
                    immutable(state, {
                        errorMessage: {$set: statusMeans},
                        status: {$set: STATUS.ERROR},
                        educationalPacksStatus: {$set: false},
                    }),
            },
            securityState,
            ),
};