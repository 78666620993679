import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Typography, Grid, Button, Icon } from '@material-ui/core';
import { Container } from 'styled-minimal';
import withStyles from '@material-ui/core/styles/withStyles';
import { SCANE_IMAGE } from '../../styles/constants';
import history from '../../modules/history';
import AddUserIcon from '../../components/AddUserIcon'
import ShowQRIcon from '../../components/ShowQRIcon'
import { ReactComponent as QrIcon } from '../../resources/images/qr-code.svg';
import CheckInCounter from '../../components/CheckInCounter';
import styles from './styles';
import { cleareEventUser, updateTimeOpenPage } from '../../actions/staff';

const QRContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class QR extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { updateTimeOpenPage } = this.props
    updateTimeOpenPage();
  }

  render() {
    const { classes, cleareEventUser, staff } = this.props;

    return (
      <QRContainer>
        <Grid container spacing={24} justify="center" alignItems="center" className={classes.contentWrap}>
          <Grid item xs={12} sm={6} className={classes.imageWrap}>
            <QrIcon style={{ height: 400, width: 400 }} />
          </Grid>
        </Grid>
      </QRContainer>
    )
  }
}

QR.propTypes = {
  classes: PropTypes.object.isRequired,
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  staff: state.staffReducer.staff
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    cleareEventUser,
    updateTimeOpenPage,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QR));
