import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormControl, InputLabel, InputBase, withStyles, NativeSelect } from '@material-ui/core';
import config from '../../../config';
import styles from './styles';

class CSelect extends React.Component {
    static propTypes = {
        fullWidth: PropTypes.bool,
        required: PropTypes.bool,
        label: PropTypes.string,
        defaultValue: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        value: PropTypes.any,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        isValid: PropTypes.bool,
        validate: PropTypes.func,
        margin: PropTypes.string,
        inputProps: PropTypes.any,
        options: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
        themeId: PropTypes.any
    };

    render() {
        const {
            fullWidth,
            required,
            label,
            classes,
            defaultValue,
            name,
            type,
            autoComplete,
            autoFocus,
            value,
            onChange,
            disabled,
            placeholder,
            isValid,
            validate,
            margin = 'none',
            inputProps,
            options,
            themeId
        } = this.props;

        return (
                <FormControl margin={margin} fullWidth={fullWidth}>
                    <InputLabel
                        shrink
                        className={classNames(
                                            classes.bootstrapFormLabel,
                                            config.themes[themeId] && classes[`input_label_${themeId}`]
                                            )}
                        focused={false}
                        >
                        {label}
                    </InputLabel>
                    <NativeSelect
                        value={value}
                        onChange={onChange}
                        classes={{
                                icon: classes.icon,
                                select: classes.select,
                            }}
                        disabled={disabled}
                        input={
                                <InputBase
                                    defaultValue={defaultValue}
                                    name={name}
                                    type={type}
                                    autoComplete={autoComplete}
                                    autoFocus={autoFocus}
                                    onBlur={validate}
                                    placeholder={placeholder}
                                    inputProps={inputProps}
                                    classes={{
                                                            root: label && classes.bootstrapRoot,
                                                            input: classNames(
                                                                    classes.bootstrapInput,
                                                                    config.themes[themeId] &&
                                                                    classes[`input_${themeId}`],
                                                                    required &&
                                                                    isValid === true &&
                                                                    classes.isValidInput,
                                                                    required &&
                                                                    isValid === false &&
                                                                    classes.NotValidInput
                                                                    ),
                                                        }}
                                    />
                        }
                        >
                        {!!defaultValue && (
                                            <option value={defaultValue.id}>
                                                {defaultValue.name}{!!defaultValue.id && '.'}{" "}
                                                {defaultValue && defaultValue["^venue"]
                                                                    ? defaultValue["^venue"].name + ", "
                                                                    : " "}
                                                {defaultValue && defaultValue.date_start_formatted ? defaultValue.date_start_formatted : ''}
                                    
                                            </option>
                                            ) }
                        {options.map((item, i) => {
                                let date = "";
                                if (item.id === defaultValue.id) {
                                    return
                                }
                                if (!!item.date_start) {
                                    date = new Date(item.date_start + " ");
                                    date = date.toDateString();
                                }

                                return (
                                        <option key={i} value={+item.id}>
                                
                                            {item.name}{!item.id ? '' : '.'}{" "}
                                            {item && item["^venue"]
                                                                ? item["^venue"].name + ", "
                                                                : " "}
                                            {date}
                                        </option>
                                        );
                            })}
                    </NativeSelect>
                </FormControl>
                );
    }
}

export default withStyles(styles)(CSelect);