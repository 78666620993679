import brand_theme from '../../../styles/brand_theme';

const styles = theme => ({
  label: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: -0.5,
  },
  labelPlacementStart: {
    marginLeft: 0,
    marginRight: -28,
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelText: {
    position: 'absolute',
    right: 37,
    zIndex: 1,
    color: 'white',
    top: 13,
    fontSize: 16,
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  noActiveLabelText: {
    right: 15,
  },
  ...brand_theme
});

export default styles;