import { 
  ERROR_RED
} from '../../styles/constants';

const styles = theme => ({
  errorText: {
    fontSize: 16,
    color: '#fff',
    textAlign: 'center',
    margin: 20
  },
  errorBlock: {
    backgroundColor: ERROR_RED
  },
  background: {
    backgroundColor:'transparent'
  }
});

export default styles;