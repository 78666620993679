import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, FormControlLabel, withStyles} from '@material-ui/core';
import styles from './styles'; 
import config from '../../../config';

class CCheckbox extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    error: PropTypes.bool,
    themeId: PropTypes.any 
  }

  render() {
    const { 
      label, 
      value, 
      name, 
      onChange, 
      checked,
      classes,
      error,
      themeId
    } = this.props;

    return (
      <FormControlLabel
        classes={{
          root: classes.labelRoot,
          label: classNames(
            classes.label,
            (config.themes[themeId]) && classes[`checkbox_label_${themeId}`],
          )
        }}
        control={
          <Checkbox
            name={name}
            checked={checked}
            onChange={onChange}
            value={value}
            classes={{
              root: (error) ? classNames(
                classes.rootError,
                (config.themes[themeId]) && classes[`checkbox_error_${themeId}`],
              ) : classNames(
                classes.root,
                (config.themes[themeId]) && classes[`checkbox_${themeId}`],
              ),
              checked: classNames(
                classes.checked,
                (config.themes[themeId]) && classes[`checkbox_success_${themeId}`],
              ),
            }}
          />
        }
        label={label}
      />
    );
  }
}

export default withStyles(styles)(CCheckbox);