// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants';

export const { 
  getStaff, 
  authStaff, 
  getEventUser, 
  checkUserToEvent, 
  checkUserToEventToggle, 
  cleareEventUser, 
  cleareAllFoundUsers,
  resetPassStep, 
  logOutStaff, 
  updateTimeOpenPage, 
  createNewPasswordStaff,
  resetPasswordStaff, 
  giveEducationalPack,
  setFoundUser, 
  getEvents,
  setGlobalChosenEvent
} = createActions({
  [ActionTypes.GET_STAFF]: (noNavigate) => ({noNavigate}),
  [ActionTypes.AUTH_STAFF]: (email, password) => ({email, password}),
  [ActionTypes.GET_EVENT_USER]: (phone, name, user_id, user_code, user_role, not_next_page, set_user) => ({phone, name, user_id, user_code, user_role, not_next_page, set_user}),
  [ActionTypes.CHECK_USER_TO_EVENT]: (user_id, user_code) => ({user_id, user_code}),
  [ActionTypes.CLEARE_EVENT_USER]: () => ({}),
  [ActionTypes.CLEARE_ALL_FOUND_USERS]: () => ({}), 
  [ActionTypes.CHECK_USER_TO_EVENT_TOGGLE]: (val) => (val),
  [ActionTypes.LOG_OUT_STAFF]: () => ({}),
  [ActionTypes.UPDATE_TIME_OPEN_PAGE]: () => ({}),
  [ActionTypes.RESET_PASSWORD_STAFF]: (email) => ({email}),
  [ActionTypes.CREATE_NEW_PASSWORD_STAFF]: (email, checkword, password) => ({email, checkword, password}),
  [ActionTypes.RESET_PASS_STEP]: () => ({}),
  [ActionTypes.GIVE_EDUCATIONAL_PACK]: (user_id, user_code, educational_pack_ids) => ({user_id, user_code, educational_pack_ids}),
  [ActionTypes.SET_FOUND_USER]: (data) => ({data}),
  [ActionTypes.GET_EVENTS]: () => ({}),
  [ActionTypes.SET_GLOBAL_CHOSEN_EVENT]: (event) =>(event)
});