import { 
    LARGE_TEXT_LG,
    LARGE_TEXT_MD,
    LARGE_TEXT_SM,
    MAIN_ACCENT_COLOR
  } from '../../styles/constants';
  
  const styles = theme => ({
    h1: {
      color: 'white',
      fontFamily: "Montserrat-Bold",
      lineHeight: '60px',
      [theme.breakpoints.down('sm')]: {
        fontSize: `${LARGE_TEXT_SM}px`,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `${LARGE_TEXT_MD}px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `${LARGE_TEXT_LG}px`,
      },
      marginBottom: 10
    },
    imageWrap: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        order: 1
      },
    },
    pulseBtn: {
      width: '270px',
      height: '270px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative'
    },
    circle: {
      zIndex: 100,
      outline: 'none',
      boxSizing: 'content-box',
      border: `2px solid ${MAIN_ACCENT_COLOR}`,
      position: 'absolute',
      '-webkit-border-radius': '100%',
      '-moz-border-radius': '100%',
      borderRadius: '100%',
      opacity: '.5',
      transformOrigin: 'center',
      '-webkit-animation': 'circle-anim 3.4s infinite ease-in-out !important',
      '-moz-animation': 'circle-anim 3.4s infinite ease-in-out !important',
      '-ms-animation': 'circle-anim 3.4s infinite ease-in-out !important',
      '-o-animation': 'circle-anim 3.4s infinite ease-in-out !important',
      animation: 'circle-anim 3.4s infinite ease-in-out !important',
      '-webkit-transition': 'all 4s',
      '-moz-transition': 'all 4s',
      '-o-transition': 'all 4s',
      transition: 'all 4s'
    },
    circleBig: {
      width: '270px',
      height: '270px',
    },
    circleMedium: {
      width: '220px',
      height: '220px',
    },    
    circleSmall: {
      width: '170px',
      height: '170px',
    },
    image: {
      width: 120,
      height: 120,
      position: 'absolute',
      zIndex: 200
    },
    link: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:hover span': {
        backgroundColor: 'transparent',
        color: MAIN_ACCENT_COLOR,
      },
      '&:hover p': {
        backgroundColor: 'transparent',
        color: MAIN_ACCENT_COLOR,
      },
      '&:hover span svg path': {
        fill: MAIN_ACCENT_COLOR,
      },
    },
    addUserIcon: {
      marginRight: 7,
      '&:hover': {
        color: 'green',
      },
    },
    text: {
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: `13px`,
        textAlign: 'center',
        lineHeight: '21px'
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `15px`,
        lineHeight: '25px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `18px`,
        lineHeight: '30px',
      },
    },
    bottomTitle: {
      fontFamily: "Montserrat-Bold",
      color: 'white',
      lineHeight: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: `15px`,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: `16px`,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: `18px`,
      }
    },
    icon: {
      fontSize: 28, 
      marginRight: 10, 
      color: 'white',
    },
    wrapLink: {
      position: 'absolute',
      top: 60,
      left: 20,
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        top: 20,
        left: 10
      }
    },
    checkCounter: {
      [theme.breakpoints.down('sm')]: {
        left: 20,
        top: 80
      }
    },
    wrapLinkBottom: {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        display: 'flex',
        top: 10
      },
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    contentWrap: {
      position: 'relative',
      padding: `100px 0 100px 0`,
      [theme.breakpoints.down('sm')]: {
        padding: `70px 0 70px 0`,
      }
    },
    bottomTextWrap: {
      position: 'absolute',
      left: 0,
      bottom: 10,
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
    hideXS: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    image_icon: {
      width: 30, 
      height: 25,
    },
  });
  
  export default styles;