import React from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControlLabel, withStyles} from '@material-ui/core';
import styles from './styles'; 
import config from '../../../config';
import classNames from 'classnames';

class CSwitch extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
  }

  render() {
    const { 
      label, 
      value, 
      name, 
      onChange, 
      checked,
      classes,
      themeId
    } = this.props;


    const CustomSwitch = withStyles(theme => ({
      iOSSwitchRoot: {
        width: 110
      },
      iOSSwitchBase: {
        '&$iOSChecked': {
          color: '#FFFFFF',
          '& + $iOSBar': {
            borderColor: '#00AA60',
            backgroundColor: `#00AA60!important`,
          },
        },
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
          easing: theme.transitions.easing.sharp,
        }),
      },
      iOSBar: {
        borderRadius: 35,
        width: 72,
        height: 32,
        marginTop: -18,
        marginLeft: -50,
        border: 'solid 2px',
        borderColor: '#E52033',
        backgroundColor: '#E52033',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      iOSIcon: {
        width: 22,
        height: 22,
        boxShadow: 'none',
        color: '#fff',
      },
      iOSIconChecked: {
        boxShadow: 'none',
        color: '#fff',
      },
      iOSChecked: {
        transform: 'translateX(38px)',
        '& + $iOSBar': {
          opacity: 1,
        },
      },
    }))(({ classes, ...props }) => {
      return (
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.iOSSwitchRoot,
            switchBase: classes.iOSSwitchBase,
            bar: classes.iOSBar,
            icon: classes.iOSIcon,
            iconChecked: classes.iOSIconChecked,
            checked: classes.iOSChecked,
          }}
          {...props}
        />
      );
    });

    return (
      <div style={{position: 'relative'}}>
        <FormControlLabel
          classes={{
            label: classNames(
              classes.label,
              (config.themes[themeId]) && classes[`switch_label_${themeId}`],
            ),
            labelPlacementStart: classes.labelPlacementStart
          }}
          control={
            <CustomSwitch
              name={name}
              checked={checked}
              onChange={onChange}
              value={value}
            />
          }
          labelPlacement="start"
          label={label}
        />
        <span className={classNames(classes.labelText, (!checked) && classes.noActiveLabelText)} onClick={onChange}>{checked? 'Yes': 'No'}</span>
      </div>
    );
  }
}

export default withStyles(styles)(CSwitch);