import config from '../config';

const styles_brand_4 = (config.themes['4']) && {
  switch_label_4: {
    color: config.themes['4'].switch.labelColor ? config.themes['4'].switch.labelColor : 'white'
  },
  button_4: {
    backgroundColor: config.themes['4'].button.submit.backgroundColor ? config.themes['4'].button.submit.backgroundColor : '#00AA60',
    borderColor: config.themes['4'].button.submit.backgroundColor ? config.themes['4'].button.submit.backgroundColor : '#00AA60',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: config.themes['4'].button.submit.backgroundColor ? config.themes['4'].button.submit.backgroundColor : '#00AA60',
      borderColor: config.themes['4'].button.submit.backgroundColor ? config.themes['4'].button.submit.backgroundColor : '#00AA60',
    }
  },
  button_white_4: {
    borderColor: config.themes['4'].button.cancel.borderColor ? config.themes['4'].button.cancel.borderColor : '#17202C',
    color: config.themes['4'].button.cancel.color ? config.themes['4'].button.cancel.color : '#00AA60',
    backgroundColor: 'transparent'
  },
}

const styles_brand_1 =  (config.themes['1']) && {
  checkbox_1: {
    color: config.themes['1'].checkbox.defaultColor ? config.themes['1'].checkbox.defaultColor : '#00AA60'
  },
  checkbox_error_1: {
    color: `${config.themes['1'].checkbox.errorColor ? config.themes['1'].checkbox.errorColor : '#F13E3E'}!important`
  },
  checkbox_success_1: {
    color: `${config.themes['1'].checkbox.checkedColor ? config.themes['1'].checkbox.checkedColor : '#00AA60'}!important`
  },
  button_1: {
    backgroundColor: config.themes['1'].button.submit.backgroundColor ? config.themes['1'].button.submit.backgroundColor : '#00AA60',
    borderColor: config.themes['1'].button.submit.backgroundColor ? config.themes['1'].button.submit.backgroundColor : '#00AA60',
    color: config.themes['1'].button.submit.color ? config.themes['1'].button.submit.color : '#ffffff',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: config.themes['1'].button.submit.backgroundColor ? config.themes['1'].button.submit.backgroundColor : '#00AA60',
      borderColor: config.themes['1'].button.submit.backgroundColor ? config.themes['1'].button.submit.backgroundColor : '#00AA60',
    }
  },
  button_white_1: {
    borderColor: config.themes['1'].button.cancel.borderColor ? config.themes['1'].button.cancel.borderColor : '#17202C',
    color: config.themes['1'].button.cancel.color ? config.themes['1'].button.cancel.color : '#00AA60',
    backgroundColor: 'transparent'
  }
}

const styles_brand_3 =  (config.themes['3']) && {
  input_3: {
    borderColor: config.themes['3'].input.defaultBorderColor ? config.themes['3'].input.defaultBorderColor : 'white'
  },
  input_read_3: {
    borderColor: config.themes['3'].input.onlyReadBorderColor ? config.themes['3'].input.onlyReadBorderColor : 'white'
  },
  input_label_3: {
    color: config.themes['3'].input.labelColor ? config.themes['3'].input.labelColor : 'white'
  },
  checkbox_3: {
    color: config.themes['3'].checkbox.defaultColor ? config.themes['3'].checkbox.defaultColor : '#00AA60'
  },
  checkbox_error_3: {
    color: `${config.themes['3'].checkbox.errorColor ? config.themes['3'].checkbox.errorColor : '#F13E3E'}!important`
  },
  checkbox_success_3: {
    color: `${config.themes['3'].checkbox.checkedColor ? config.themes['3'].checkbox.checkedColor : '#00AA60'}!important`
  },
  checkbox_label_3: {
    color: config.themes['3'].checkbox.labelColor ? config.themes['3'].checkbox.labelColor : 'white'
  },
  switch_label_3: {
    color: config.themes['3'].switch.labelColor ? config.themes['3'].switch.labelColor : 'white'
  },
  button_3: {
    backgroundColor: config.themes['3'].button.submit.backgroundColor ? config.themes['3'].button.submit.backgroundColor : '#00AA60',
    borderColor: config.themes['3'].button.submit.backgroundColor ? config.themes['3'].button.submit.backgroundColor : '#00AA60',
    '&:hover': {
      opacity: 0.8,
      backgroundColor: config.themes['3'].button.submit.backgroundColor ? config.themes['3'].button.submit.backgroundColor : '#00AA60',
      borderColor: config.themes['3'].button.submit.backgroundColor ? config.themes['3'].button.submit.backgroundColor : '#00AA60',
    }
  },
  button_white_3: {
    borderColor: config.themes['3'].button.cancel.borderColor ? config.themes['3'].button.cancel.borderColor : '#17202C',
    color: config.themes['3'].button.cancel.color ? config.themes['3'].button.cancel.color : '#00AA60',
    backgroundColor: 'transparent'
  }
}

const styles = {
  ...styles_brand_1,
  ...styles_brand_4,
  ...styles_brand_3,
}

export default styles;