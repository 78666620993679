import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import moment from 'moment';
import classNames from 'classnames';
import history from '../../modules/history';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Typography, Grid, Button, MobileStepper, Card, CardContent } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Container } from 'styled-minimal';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactHtmlParser from 'react-html-parser';
import SwipeableViews from 'react-swipeable-views';

import CButton from '../../components/FormElements/CButton';
import styles from './styles';
import { giveEducationalPack } from '../../actions/staff';
import Success from '../../components/Success';
import config from '../../config';

const HomeContainer = styled(Container)`
  align-items: top;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
`;

class EducationalPacks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      activeElement: []
    }
  }

  handleNextSlide = (isNext) => {
    const { activeStep } = this.state;

    this.setState({
      activeStep: (isNext) ? activeStep + 1 : activeStep - 1,
    });
  };

  submitForm = (event) => {
    const { activeElement } = this.state;
    const { id, code } = this.props.found_user;
    event.preventDefault();
    this.props.giveEducationalPack(id, code, activeElement);
  };

  handleClick = (id, index) => {
    let activeElement = [...this.state.activeElement];
      if (index === -1) {
        activeElement.push(id)
      } else  {
        activeElement.splice(index, 1);
      }
    this.setState({ activeElement })
  };

  createArraySlide = (max) => {
    let arr = [];
    for (let i = 0; i < max; i++) {
      arr.push(i)
    }
    return arr;
  };


  render() {
    const { classes, educationalPacks, educationalPacksStatus, found_user } = this.props;
    const { activeStep, activeElement } = this.state;
    let count = (isWidthDown('xs', this.props.width)) ? educationalPacks.length : 4;
    const maxSteps = Math.ceil(educationalPacks.length / count);
    const maxStepsMobile = educationalPacks.length;

    return (
      <HomeContainer>
        {
          (!educationalPacksStatus) ? (
            <div style={{padding: '20px 0'}}>
              <div className={classes.textBlock} >
                <Typography component="p" variant="body2" classes={{ body2: classNames(classes.text, classes.topText)  }}>
                  Choose one or more options:
                </Typography>
                <Typography component="h1" variant="h1" classes={{ h1: classes.h1 }} style={{ textAlign: 'end', position: 'relative' }}>
                  {found_user.firstname} {found_user.lastname}
                </Typography>
              </div>
              <div className={classes.carouselSlider} >
                {
                  (maxSteps > 1) && (
                  <>
                    <Button
                      className={classNames(classes.leftButton, (maxStepsMobile <= 4) ? classes.buttonDisable : '')}
                      onClick={() => this.handleNextSlide(false)} disabled={this.state.activeStep === 0}>
                      <KeyboardArrowLeft className={classes.arrowTest} />
                    </Button>
                    <Button
                      className={classNames(classes.rightButton, (maxStepsMobile <= 4) ? classes.buttonDisable : '')}
                      onClick={() => this.handleNextSlide(true)} disabled={this.state.activeStep === maxSteps - 1}>
                      <KeyboardArrowRight className={classes.arrowTest} />
                    </Button>
                  </>
                  )
                }
                <SwipeableViews
                  disabled={isWidthDown('xs', this.props.width)}
                  index={activeStep}
                  onChangeIndex={(event) => this.setState({ activeStep: event })}
                  enableMouseEvents
                  style={{margin: '0 -15px'}}
                >
                  {
                    this.createArraySlide(Math.ceil(maxStepsMobile / count)).map((j) => (
                      <Grid container spacing={24} key={j} className={(maxSteps > 0) ? classes.wrapSlidersPadding : classes.wrapSliders}>
                        {
                          educationalPacks.map((item, i) => {
                            if ((i+1 > count*(activeStep + 1)) || (i+1 <= count*(activeStep))) return false;

                            return (
                              <Grid item key={i} xs={12} sm={6} style={{ padding: '7px 12px' }}>
                                <Card
                                  className={
                                    classNames(classes.card, (activeElement.findIndex((element) => element === item.id) !== -1) && classes.cardSelect, (item.user_got_it === 1) ? classes.cardDisable : '')
                                  }
                                  onClick={() => this.handleClick(item.id, activeElement.findIndex((element) => element === item.id))}
                                >
                                  <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                      <Typography component="h1" variant="h1" classes={{ h1: classes.h1 }}>
                                        {item.name}
                                      </Typography>
                                      
                                      { (!item.user_got_it && item.is_drink_ticket === 1) && (
                                            <Typography component="h2" variant="h2" classes={{ h2: classes.h2 }}>
                                              AVAILABLE: {item.drink_tickets_remaining}
                                            </Typography>
                                          )
                                        }
                                        
                                      <div className={ classes.text }>
                                        {ReactHtmlParser(item.desc)}
                                      </div>
                                    </CardContent>
                                  </div>
                                  
                                  <div className={classes.cover}>
                                    <img
                                      style={{
                                        objectFit: 'cover',
                                        height: '100%'
                                      }}
                                      src={config.imagesUrl + item['^img'].small}
                                      alt='pack_photo'
                                    />
                                  </div>
                                  
                                  { (item.user_got_it === 1 && !item.is_drink_ticket && !item.pin) && (
                                    <div className={ classes.received }>
                                      <Typography component="h2" variant="h2" classes={{ h2: classes.h2 }}>
                                        COMPLETED {moment(item.date_got * 1000).format('YYYY.MM.DD')}
                                      </Typography>
                                    </div>
                                    )
                                  }
                                  
                                   { (item.user_got_it === 1 && item.is_drink_ticket === 1) && (
                                    <div className={ classes.received }>
                                      <Typography component="h2" variant="h2" classes={{ h2: classes.h2 }}>
                                        AVAILABLE: NONE
                                      </Typography>
                                    </div>
                                    )
                                  }
                                  
                                  { (item.user_got_it === 1 && !item.is_drink_ticket && item.pin !== "") && (
                                        <div className={ classes.received }>
                                            <Typography component="h2" variant="h2" classes={{ h2: classes.h2 }}>
                                              COMPLETED {moment(item.date_got * 1000).format('YYYY.MM.DD')}<br />
                                              GAME PIN: {item.pin}
                                            </Typography>
                                        </div>     
                                      )
                                    }
                                  
                                </Card>
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    ))
                  }
                </SwipeableViews>
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={12} style={{ paddingTop: '40px' }}>
                    {
                        (maxSteps > 1) && (
                          <MobileStepper
                            variant="dots"
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            className={classNames(classes.mobileStepper, (maxStepsMobile <= 4) ? classes.buttonDisable : '')}
                            classes={{
                              dotActive: classes.dotActive,
                              dot: classes.dot
                            }}
                          />
                        )
                    }
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={24} style={{ paddingTop: '20px' }}>
                <Grid item xs={12} sm={6} >
                  <CButton
                    onClick={this.submitForm}
                    fullWidth
                    type='submit'
                    disabled={(activeElement.length === 0)? true : false}
                  >
                    Submit
                  </CButton>
                </Grid>
                <Grid item xs={12} sm={6} >
                </Grid>
              </Grid>
            </div>
          ) : (
            <Success />
          )
        }
      </HomeContainer>

    )
  }
}

EducationalPacks.propTypes = {
  classes: PropTypes.object.isRequired
};


/* istanbul ignore next */
const mapStateToProps = (state) => ({
  found_user: state.securityReducer.found_user,
  educationalPacks: state.securityReducer.educationalPacks,
  educationalPacksStatus: state.securityReducer.educationalPacksStatus
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    giveEducationalPack
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(EducationalPacks)));
