import React from 'react';
import PropTypes from 'prop-types';
import CInput from '../CInput';
import { withStyles } from '@material-ui/core';
import Select from 'react-select';
import styles from './styles';

const customStyles = {
  indicatorsContainer: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    padding: 0,
    width: '100%'
  }),
  control: () => ({
    border: 'none'
  }),
  menuList: base => ({
    ...base,
    paddingBottom: 20,
    backgroundColor: 'white'
  })
}

class AutoCompete extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    isValid: PropTypes.bool,
    onValidate: PropTypes.func,
    name: PropTypes.string,
    options: PropTypes.array,
    onSelect: PropTypes.func,
  };

  renderInput = props => {
    const { onChange, value, required, isValid, onValidate, placeholderText = 'Search by name or mobile' } = this.props;

    return (
      <CInput
        fullWidth
        autoComplete={'off'}
        autoFocus={false}
        required={required}
        isValid={isValid}
        name="input"
        value={value}
        onChange={(e) => {
          props.onChange(e)
          onChange(e);
        }}
        validate={(event) => {
          if (!event) return;
          onValidate((event.target) ? event.target.value: event);
          props.onBlur(event);
        }}
        onFocus={props.onFocus}
        placeholder={placeholderText}
        
      />
    )
  }

  renderOption = ({ data, innerRef, innerProps }) => {
    const { classes } = this.props;
    
    return (
      <div 
        className={classes.option} 
        ref={innerRef} 
        {...innerProps}
      >
        <span className={classes.optionName}>{data.label}</span>
        <span className={classes.optionPhone}>{data.subLabel.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3-$4")}</span>
      </div>
    )
  }

  render() {
    const { name, options, onSelect, value, menuIsOpen } = this.props;

    return (
      <Select  
        styles={customStyles}
        name={name}
        inputValue={value}
        onChange={onSelect}
        options={options}
        menuIsOpen={menuIsOpen}
        components={{ 
          Input: this.renderInput,
          Option: this.renderOption
        }}
        noOptionsMessage={() => {
          return 'No results found'
        }}
      />
    );
  }
}

export default withStyles(styles)(AutoCompete);