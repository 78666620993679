import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Container } from "styled-minimal";
import { Typography, Grid, Modal, IconButton, Icon } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import history from "../../modules/history";
import config from "../../config";
import { setErrorMessage } from "../../actions/error";
import { logOutStaff, updateTimeOpenPage } from "../../actions/staff";
import {
	setBarChoosen,
	getBarList,
	updateUser,
	cleareUser,
} from "../../actions/user";
import AuthCompleteWithLabel from "../../components/FormElements/AuthCompleteWithLabel";
import CInput from "../../components/FormElements/CInput";
import CButton from "../../components/FormElements/CButton";
import CCheckbox from "../../components/FormElements/CCheckbox";
import CSwitch from "../../components/FormElements/CSwitch";
import CDateInput from "../../components/FormElements/CDateInput";

import styles from "./styles";

const HomeContainer = styled(Container)`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1170px;
	margin: 0 auto;
	padding: 15px 15px 100px 15px;
`;

class Registration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name:
				props.user && props.user.firstname ? props.user.firstname : "",
			last_name:
				props.user && props.user.lastname ? props.user.lastname : "",
			date_birthday:
				props.user && props.user.birthdate
					? props.user.birthdate
					: null,
			email: props.user && props.user.email ? props.user.email : "",
			repeat_email:
				props.user && props.user.email ? props.user.email : "",
			send_sms_notifications:
				props.user && props.user.send_sms_notifications
					? props.user.send_sms_notifications === 1
					: false,
			send_email_notifications:
				props.user && props.user.send_email_notifications
					? props.user.send_email_notifications === 1
					: false,
			age_verified: false,
			confirm_licensed: false,
			show_modal: false,
			bar_name: props.user_bar ? props.user_bar.name : "",
			/** Errors */
			isValid_first_name:
				props.user && props.user.firstname ? true : null,
			isValid_last_name: props.user && props.user.lastname ? true : null,
			isValid_date_birthday:
				props.user && props.user.birthdate ? true : null,
			isValid_email: props.user && props.user.email ? true : null,
			isValid_repeat_email: props.user && props.user.email ? true : null,
			isValid_bar_name:
				props.user_bar && props.user_bar.name ? true : null,
			isValid_age_verified: null,
			isValid_confirm_licenced: null,
			//** Additional */
			showOptions: false,
			chosenEvent: null,
			opt_in_trade_insights_survey: false,
			// opt_in_trade_insights_survey:
			// 	props.user && props.user.opt_in_trade_insights_survey
			// 		? props.user.opt_in_trade_insights_survey === 1
			// 		: false,
		};
	}

	componentDidMount() {
		const { events, event_id } = this.props;

		this.props.updateTimeOpenPage();
		this.setState({
			chosenEvent: events.find((elem) => +elem.id === +event_id),
		});
	}

	componentDidUpdate(prevProps) {
		const { user_bar } = this.props;

		if (prevProps.user_bar !== user_bar) {
			this.setState({
				bar_name: user_bar ? user_bar.name : "",
				isValid_bar_name: user_bar ? true : null,
			});
		}
	}

	componentWillUnmount() {
		const { cleareUser } = this.props;

		// cleareUser();
	}

	validateForm = (value, type, name) => {
		const { email, repeat_email } = this.state;

		switch (type) {
			case "text":
				this.setState({ [name]: this.validateText(value) });
				break;
			case "email":
				this.setState({
					isValid_email: this.validateEmail(
						name === "isValid_email" ? value : email,
						repeat_email
					),
					isValid_repeat_email: this.validateEmail(
						name === "isValid_repeat_email" ? value : repeat_email,
						email
					),
				});
				break;
			case "date":
				this.setState({
					date_birthday:
						!value ||
						value.indexOf("_") !== -1 ||
						moment(value).format("YYYY/MM/DD") === "Invalid date"
							? null
							: moment(value).format("YYYY/MM/DD"),
					[name]:
						moment(value).format("YYYY/MM/DD") === "Invalid date" ||
						moment(value).format("YYYY/MM/DD") >
							moment().add(-16, "years").format("YYYY/MM/DD") ||
						moment(value).format("YYYY/MM/DD") < "1900/01/01"
							? false
							: true,
				});
				break;
			default:
				break;
		}
	};

	validateText = (val) => {
		let re = /^[a-zA-Z]+(([a-zA-Z ])?[' -.][a-zA-Z]*)*$/;
		return re.test(val) && val.length >= 2;
	};

	validateEmail = (val, confirmVal) => {
		let re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let result =
			confirmVal.length && val.length
				? re.test(val) && confirmVal === val
				: val.length
				? re.test(val)
				: null;

		return result;
	};

	checkFullValidate = async () => {
		const {
			isValid_first_name,
			isValid_last_name,
			isValid_date_birthday,
			isValid_email,
			isValid_repeat_email,
			isValid_bar_name,
			isValid_age_verified,
			isValid_confirm_licenced,
		} = this.state;

		let promise = new Promise((resolve, reject) => {
			this.setState(
				{
					isValid_first_name: isValid_first_name === true,
					isValid_last_name: isValid_last_name === true,
					isValid_date_birthday: isValid_date_birthday === true,
					isValid_email: isValid_email === true,
					isValid_repeat_email: isValid_repeat_email === true,
					isValid_bar_name: isValid_bar_name === true,
					isValid_age_verified: isValid_age_verified === true,
					isValid_confirm_licenced: isValid_confirm_licenced === true,
				},
				() => {
					const {
						isValid_first_name,
						isValid_last_name,
						isValid_date_birthday,
						isValid_email,
						isValid_repeat_email,
						isValid_bar_name,
						isValid_age_verified,
						isValid_confirm_licenced,
					} = this.state;

					isValid_first_name &&
					isValid_last_name &&
					isValid_date_birthday &&
					isValid_email &&
					isValid_repeat_email &&
					isValid_bar_name &&
					isValid_age_verified &&
					isValid_confirm_licenced
						? resolve(true)
						: reject(false);
				}
			);
		});
		return promise;
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const { updateUser, user_bar, setErrorMessage } = this.props;
		const {
			send_sms_notifications,
			email,
			send_email_notifications,
			first_name,
			last_name,
			date_birthday,
			age_verified,
			opt_in_trade_insights_survey,
		} = this.state;

		try {
			await this.checkFullValidate();

			updateUser(
				user_bar.phone,
				+send_sms_notifications,
				email,
				+send_email_notifications,
				first_name,
				last_name,
				moment(date_birthday).format("DD-MM-YYYY"),
				+age_verified,
				user_bar.id,
				this.props.event_id,
				+opt_in_trade_insights_survey
			);
		} catch (err) {
			console.log("error");
			setErrorMessage("Please fill in all required fields!");
		}
	};

	logOut = () => {
		const { cleareUser } = this.props;

		cleareUser();
		history.replace("/phone_auth");
	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	cleaneField = () => {
		const { setBarChoosen } = this.props;

		this.setState({ bar_name: "", isValid_bar_name: null });
		setBarChoosen(null);
	};

	getThemeText = (brand_id) => {
		let styles = {
			color: "white",
		};

		if (
			brand_id &&
			config.themes[brand_id] &&
			config.themes[brand_id].text
		) {
			styles.color = config.themes[brand_id].text.color;
		}

		return styles;
	};

	handleValue = (e) => {
		const { value } = e.target;
		const { getBarList } = this.props;
		this.setState({
			bar_name: value,
			showOptions: true,
		});
		if (value.length >= 3) {
			getBarList(value);
		}
	};

	handleSelect = (event) => {
		const { setBarChoosen } = this.props;

		this.setState({
			bar_name: event.label,
			showOptions: false,
		});
		setBarChoosen(event.data);
	};

	sortBars = (arr) => {
		let array = [];

		arr.forEach((item) => {
			array.push({
				data: item,
				value: item.name,
				label: item.name,
				subLabel: item.address,
			});
		});

		return array;
	};

	renderModal() {
		const { show_modal } = this.state;
		const { classes } = this.props;

		return (
			<Modal open={show_modal} className={classes.paper} disableAutoFocus>
				<div className={classes.modalContent}>
					<div>
						<Typography
							component="h3"
							variant="h3"
							classes={{ h3: classes.titleModal }}
						>
							Cancel action
						</Typography>
						<Typography
							component="p"
							variant="body2"
							classes={{ body2: classes.textModal }}
						>
							You definitely want to cancel data entry?
						</Typography>
					</div>
					<div>
						<CButton
							fullWidth
							onClick={() => {
								this.setState({ show_modal: false }, () => {
									this.logOut();
								});
							}}
						>
							Complete
						</CButton>
						<CButton
							style={{ marginTop: 20 }}
							onClick={() => this.setState({ show_modal: false })}
							fullWidth
							typeStyle={"white"}
						>
							Cancel
						</CButton>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		const { classes, bars, user_bar, event } = this.props;
		const {
			first_name,
			last_name,
			date_birthday,
			email,
			repeat_email,
			send_email_notifications,
			send_sms_notifications,
			age_verified,
			confirm_licensed,
			isValid_first_name,
			isValid_last_name,
			isValid_date_birthday,
			isValid_email,
			isValid_repeat_email,
			isValid_bar_name,
			isValid_age_verified,
			isValid_confirm_licenced,
			bar_name,
			showOptions,
			chosenEvent,
			opt_in_trade_insights_survey,
		} = this.state;

		const disabledButton =
			isValid_first_name === false ||
			isValid_last_name === false ||
			isValid_date_birthday === false ||
			isValid_email === false ||
			isValid_repeat_email === false ||
			isValid_bar_name === false ||
			isValid_age_verified === false ||
			isValid_confirm_licenced === false;

		let brandId =
			chosenEvent && chosenEvent.brand_id ? chosenEvent.brand_id : 0;

		return (
			<HomeContainer>
				<Typography
					component="p"
					variant="body2"
					classes={{ body2: classes.body2 }}
					style={this.getThemeText(brandId)}
				>
					Please, provide all users’ contacts as it appears on their
					photo ID. Photo ID is required on the door.
				</Typography>
				{this.renderModal()}
				<form noValidate onSubmit={this.handleSubmit} method="POST">
					<Grid container spacing={24} justify="center">
						<Grid item xs={12} sm={6}>
							<CInput
								required
								margin={"normal"}
								fullWidth
								name="first_name"
								type={"text"}
								autoFocus
								value={first_name}
								onChange={this.handleChange}
								isValid={isValid_first_name}
								placeholder={"First name"}
								label={"First Name"}
								themeId={brandId}
								validate={(event) =>
									this.validateForm(
										event.target.value,
										"text",
										"isValid_first_name"
									)
								}
							/>
							<CInput
								required
								margin={"normal"}
								fullWidth
								name="last_name"
								type={"text"}
								value={last_name}
								onChange={this.handleChange}
								isValid={isValid_last_name}
								placeholder={"Last Name"}
								label={"Last Name"}
								themeId={brandId}
								validate={(event) =>
									this.validateForm(
										event.target.value,
										"text",
										"isValid_last_name"
									)
								}
							/>
							<CDateInput
								required
								margin={"normal"}
								fullWidth
								name="date_birthday"
								value={date_birthday}
								isValid={isValid_date_birthday}
								onChangeDate={(event) =>
									this.validateForm(
										event,
										"date",
										"isValid_date_birthday"
									)
								}
								placeholder={"yyyy/mm/dd"}
								label={"Date of birth"}
								themeId={brandId}
								validate={(event) => {
									this.validateForm(
										event.target.value,
										"date",
										"isValid_date_birthday"
									);
								}}
							/>
							<CCheckbox
								checked={age_verified}
								onChange={() =>
									this.setState({
										age_verified: !age_verified,
										isValid_age_verified: !age_verified,
									})
								}
								error={
									isValid_age_verified === null
										? false
										: !isValid_age_verified
								}
								value={"age_verified"}
								name={"age_verified"}
								themeId={brandId}
								label="Age is verified"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CInput
								required
								margin={"normal"}
								fullWidth
								name="email"
								type={"email"}
								value={email}
								isValid={isValid_email}
								onChange={this.handleChange}
								// onPaste={(e) => { e.preventDefault(); }}
								// onCut={(e) => { e.preventDefault(); }}
								placeholder={"Email"}
								label={"Email"}
								themeId={brandId}
								validate={(event) =>
									this.validateForm(
										event.target.value,
										"email",
										"isValid_email"
									)
								}
							/>
							<CInput
								required
								margin={"normal"}
								fullWidth
								name="repeat_email"
								type={"email"}
								value={repeat_email}
								isValid={isValid_repeat_email}
								onChange={this.handleChange}
								// onPaste={(e) => { e.preventDefault(); }}
								// onCut={(e) => { e.preventDefault(); }}
								placeholder={"Email"}
								label={"Repeat email"}
								themeId={brandId}
								error_text={
									repeat_email && email
										? "You entered different emails"
										: ""
								}
								validate={(event) =>
									this.validateForm(
										event.target.value,
										"email",
										"isValid_repeat_email"
									)
								}
							/>
							<AuthCompleteWithLabel
								required
								value={bar_name}
								margin={"normal"}
								fullWidth
								themeId={brandId}
								isValid={isValid_bar_name}
								options={this.sortBars(bars)}
								menuIsOpen={bar_name.length >= 3 && showOptions}
								onChange={this.handleValue}
								onBlur={() => {
									this.setState({ showOptions: false });
								}}
								onFocus={() => {
									this.setState({ showOptions: true });
								}}
								onSelect={this.handleSelect}
								label={"Location name"}
								placeholderText="Start typing location name"
								onValidate={(e) => this.validateForm(e, true)}
							/>
							{user_bar && (
								<IconButton
									aria-label="Close"
									className={classes.closeIcon}
									onClick={this.cleaneField}
								>
									<Icon
										fontSize="large"
										style={{
											color: "#232B37",
											fontSize: 35,
										}}
									>
										cleare
									</Icon>
								</IconButton>
							)}
							<CCheckbox
								checked={confirm_licensed}
								onChange={() =>
									this.setState({
										confirm_licensed: !confirm_licensed,
										isValid_confirm_licenced:
											!confirm_licensed,
									})
								}
								error={
									isValid_confirm_licenced === null
										? false
										: !isValid_confirm_licenced
								}
								value={"confirm_licensed"}
								name={"confirm_licensed"}
								themeId={brandId}
								label="I confirm that I am (i) an owner or an employee of a licensed tobacco retailer and/or a vaping products retailer; and (ii) of legal age to purchase tobacco and vaping products in my province of residence."
							/>
							{/* <CCheckbox
								checked={opt_in_trade_insights_survey}
								onChange={() =>
									this.setState({
										opt_in_trade_insights_survey:
											!opt_in_trade_insights_survey,
									})
								}
								error={false}
								value={"opt_in_trade_insights_survey"}
								name={"opt_in_trade_insights_survey"}
								themeId={brandId}
								label="I want to participate in Trade Insights Survey program"
							/> */}
						</Grid>
						<div className={classes.emptyBlock} />
						<Grid
							item
							xs={12}
							sm={6}
							className={classes.smPaddingTopHide}
						>
							<CInput
								margin={"normal"}
								fullWidth
								name="bar_address"
								type={"text"}
								value={user_bar ? user_bar.address : ""}
								onChange={this.handleChange}
								label={"Location address"}
								themeId={brandId}
								readOnly
							/>
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							className={classes.smPaddingTopHide}
						>
							<CInput
								margin={"normal"}
								fullWidth
								name="bar_phone"
								type={"tel"}
								value={user_bar ? user_bar.phone : ""}
								onChange={this.handleChange}
								label={"Location phone"}
								themeId={brandId}
								readOnly
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div>
								<CSwitch
									checked={send_email_notifications}
									onChange={() =>
										this.setState({
											send_email_notifications:
												!send_email_notifications,
										})
									}
									value={"send_email_notifications"}
									name={"send_email_notifications"}
									label="Get more invites to events like this news, info"
									themeId={brandId}
								/>
							</div>
							<div>
								<CSwitch
									checked={send_sms_notifications}
									onChange={() =>
										this.setState({
											send_sms_notifications:
												!send_sms_notifications,
										})
									}
									value={"valsend_sms_notificationsue_2"}
									name={"valsend_sms_notificationsue_2"}
									label="Receive SMS notifications"
									themeId={brandId}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} className={classes.xsHide} />
						<Grid item xs={12} sm={6} className={classes.xsHide}>
							<CButton
								onClick={() =>
									this.setState({ show_modal: true })
								}
								fullWidth
								typeStyle={"white"}
								themeId={brandId}
							>
								Cancel
							</CButton>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CButton
								onClick={this.handleSubmit}
								fullWidth
								disabled={disabledButton}
								themeId={brandId}
							>
								Complete
							</CButton>
						</Grid>
						<Grid item xs={12} className={classes.xsShow}>
							<CButton
								onClick={() =>
									this.setState({ show_modal: true })
								}
								fullWidth
								typeStyle={"white"}
								themeId={brandId}
							>
								Cancel
							</CButton>
						</Grid>
					</Grid>
				</form>
			</HomeContainer>
		);
	}
}

Registration.propTypes = {
	classes: PropTypes.object.isRequired,
};

/* istanbul ignore next */
const mapStateToProps = (state) => ({
	event: state.staffReducer.event,
	staff: state.staffReducer.staff,
	bars: state.userReducer.bars,
	user_bar: state.userReducer.user_bar,
	user: state.userReducer.user,
	event_id: state.userReducer.event_id,
	events: state.staffReducer.events,
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			logOutStaff,
			setBarChoosen,
			getBarList,
			updateUser,
			cleareUser,
			updateTimeOpenPage,
			setErrorMessage,
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Registration));
