/**
 * Created by piter on 4/21/19.
 */
import {
  LARGE_TEXT_LG,
  LARGE_TEXT_MD,
  LARGE_TEXT_SM,
  MAIN_TEXT_LG,
} from '../../styles/constants';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit*6,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  textBlock: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  },
  h1: {
    color: 'white',
    fontFamily: "Montserrat-Bold",
    [theme.breakpoints.down('sm')]: {
      fontSize: `${LARGE_TEXT_SM}px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `${LARGE_TEXT_MD}px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${LARGE_TEXT_LG}px`,
    },
    marginBottom: 10
  },
  text: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: `18px`,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: `19px`,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: `${MAIN_TEXT_LG}px`,
    },
  },
  button: {
    paddingTop: '20px',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      display: 'flex',
      alignItems: 'flex-end',
    },
  }

});

export default styles;